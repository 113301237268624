import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { getUser } from "../../../utils";
import { getByTypeAndUserId } from "../../../shared/services/api/StaticContentApi";
import { BreadcrumbNavigation } from "../../../shared/components/breadcrumb-navigation/Breadcrumb-Navigation";
import { DropdownItem } from "../../../shared/components/dropdown-item/DropdownItem";
import { ContentDto } from "../../../shared/dtos/content.dto";
import { ProgressBar } from "../../../shared/components/progress-bar/ProgressBar";

export const SoftSkillsFundamentals = () => {
  const [staticContent, setStaticContent] = useState<ContentDto[]>([]);
  const [user, setUser] = useState({
    username: "",
    type: "",
    _id: "",
  });

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  useEffect(() => {
    setUser({
      ...user,
      username: currentUser?.username,
      type: currentUser?.type,
      _id: currentUser?._id,
    });
  }, []);

  let userId = currentUser?._id;
  let userType = currentUser?.type;

  useEffect(() => {
    getByTypeAndUserId("softskills", userId).then((staticcontent) => {
      console.log("Get StaticContent from API: " + staticcontent);
      setStaticContent(staticcontent);
    });
  }, [userId]);

  const getFinishedTasks = (tasks1: ContentDto[]): number => {
    let numberOfFinishedTasks = 0;
    tasks1.forEach((task) => {
      if (task.execution === "STARTED") {
        numberOfFinishedTasks += 1;
      }
    });

    return numberOfFinishedTasks;
  };

  return (
    <Wrapper>
      <Title>Soft Skills Fundamentals</Title>
      <BreadcrumbNavigation title="Soft Skills Fundamentals" />

      <AccordionWrapper>
        <StyledProgressBar>
          <ProgressBar
            title="Progress"
            percentage={Math.round(
              (getFinishedTasks(staticContent) * 100) / staticContent.length
            )}
            total={staticContent.length}
            submittedTasks={getFinishedTasks(staticContent)}
          ></ProgressBar>
        </StyledProgressBar>
        {Array.isArray(staticContent) &&
          staticContent.length > 0 &&
          staticContent.map((staticcontent: ContentDto, index) => (
            <DropdownItem
              userId={userId}
              userType={userType}
              staticcontent={staticcontent}
              key={index}
            />
          ))}
      </AccordionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;
  min-height: calc(100% - 15rem);
  height: auto;
  color: #000000;

  @media (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 970px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-top: 3rem;
`;

const AccordionWrapper = styled.div`
  height: 100%;
  background: white;

  @media (max-width: 970px) {
    padding: 1rem 0;
  }
  padding: 2rem;
  border: 1px solid #dee2e6;
`;

const StyledProgressBar = styled.div`
  margin-top: -2rem !important;
  margin-bottom: 2rem !important;
`;

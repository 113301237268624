import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface CategoryCardInterface {
  title: string;
  icon: any;
  text: string;
  count: number;
}

export const CategoryCard = (props: CategoryCardInterface) => {
  const { title, icon, text, count } = props;

  return (
    <Card className="card">
      <AnimationOnScroll animateOnce animateIn="animate__bounceInDown">
        <Title>{title}</Title>
      </AnimationOnScroll>
      <Content>
        <AnimationOnScroll animateOnce animateIn="animate__bounceInRight">
          <IconAndCount>
            <Icon>
              <img src={icon} alt="Category icon" />
            </Icon>
            <Count>{count}</Count>
          </IconAndCount>
        </AnimationOnScroll>

        <AnimationOnScroll
          animateOnce
          animateIn="animate__bounceInLeft"
          className="overflow-auto"
        >
          <Description>{text}</Description>
        </AnimationOnScroll>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  background: #ffffff;
  height: 38rem;
  width: 35rem;
  box-shadow: 0 9px 30px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  z-index: 1;
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 3rem;
  flex-direction: row;

  @media (max-width: 1023px) {
    width: 28rem;
    height: 33rem;
  }

  @media (min-width: 1023px) {
    :not(:nth-of-type(1)) {
      margin-left: calc(10rem * -1);
    }
  }

  @media (max-width: 1023px) {
    :nth-of-type(3) {
      margin-left: 0;
    }
  }

  :hover,
  :focus-within {
    transform: translatey(-1rem);
  }
  @media (min-width: 1023px) {
    :hover ~ .card,
    :focus-within ~ .card {
      transform: translateX(9rem);
    }
  }
`;

const Title = styled.h2`
  margin: 0;
  padding: 2rem 1rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: uppercase;
  border-right: 1px solid gray;
  font-weight: bold;
  height: 100%;
  // width: 85px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const IconAndCount = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Icon = styled.div`
  padding: 2rem;
  img {
    width: 7rem;
  }
`;

const Count = styled(Typography)`
  color: #747474;
  font-weight: bolder;
  font-size: xxx-large;
  position: absolute;
  line-height: 1;
  top: 0;
  right: 0;
`;

const Description = styled.h5`
  color: #747474;
  overflow: auto;
  line-height: 2rem;

  span {
    display: block;
  }
`;

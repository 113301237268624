import { Divider, Popover, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import personPlaceholder from "../../../../../assets/images/person-placeholder.svg";
import personFill from "../../../../../assets/images/user-menu/person-fill.svg";
import logout from "../../../../../assets/images/user-menu/log-out.svg";
import { googleLogout } from "@react-oauth/google";
import { useSelector } from "react-redux";
import { getUser, setNewUser } from "../../../../utils";
import { Mixpanel } from "../../../../../../Mixpanel";
import { LoginButton } from "../../buttons/LoginButton";
import { checkIfGuestExists } from "../../../../utils/guestCheck";

interface UserMenuProps {
  setIsShowLogin: (data: boolean) => void;
}

export const UserMenu = (props: UserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({
    name: "",
    username: "",
    userImage: "",
  });

  const { setIsShowLogin } = props;

  let userDetails: {
    name: string;
    userImage: string;
    imageUrl: string;
  };
  const currentUser = getUser();
  if (currentUser) {
    userDetails = JSON.parse(currentUser);
  }

  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);

  useEffect(() => {
    if (currentUser && currentUser !== "") {
      setUser({
        ...userDetails,
        username: userDetails?.name,
        userImage: userDetails?.imageUrl,
      });
    } else {
      setUser({
        name: "",
        userImage: "",
        username: "",
      });
    }
  }, [currentUser]);

  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopoverOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserState = () => {
    if (isLoggedIn) {
      setIsShowLogin(false);
      googleLogout();
      if (process.env.NODE_ENV === "development") {
        setNewUser({
          _id: "507f191e810c19729de860ea",
          name: "Guest",
          type: "FREE",
        });
      } else if (process.env.REACT_APP_ENV_ARG === "production") {
        setNewUser({
          _id: "65af7c46e70d9f0037825c74",
          name: "Guest",
          type: "FREE",
        });
      } else if (process.env.REACT_APP_ENV_ARG === "test") {
        setNewUser({
          _id: "65af7bb2995c11003d57f4b5",
          name: "Guest",
          type: "FREE",
        });
      }
      handleClose();
      // dispatch(setLogoutState());
    } else {
      setIsShowLogin(true);
    }
  };

  const signOut = () => {
    Mixpanel.track("User menu - signout.");
    handleUserState();
  };

  const showPopup = () => {
    setIsShowLogin(true);
  };

  const redirectionCheck = (event: any) => {
    isLoggedIn ? handlePopoverOpenMenu(event) : showPopup();
  };

  return (
    <>
      <MenuContainer>
        <Typography variant="caption">
          {isLoggedIn ? user?.name : "Sign in"}
        </Typography>
        <StyledImg
          className="profile-step"
          id="profile-step"
          src={user?.userImage ? user?.userImage : personPlaceholder}
          alt="User image"
          onClick={(event) => redirectionCheck(event)}
          aria-describedby={id}
          width="60"
          height="70"
        />
      </MenuContainer>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledPopoverContainer {...{ theme }} onMouseLeave={handleClose}>
          <StyledOverview>
            Hey {user?.name.split(" ")[0]}!
            <br />
            <br />
            {checkIfGuestExists() ? (
              <LoginButton shouldClosePopup={handleClose} />
            ) : (
              "You are logged in with your Google account."
            )}
          </StyledOverview>
          <Divider />
          <StyledLinks>
            <PopoverLink
              to="/roadmap-and-progress"
              {...{ theme }}
              onClick={() => Mixpanel.track("User menu - account details.")}
            >
              <MenuIcon src={personFill} alt="Personal details" />
              Account details
            </PopoverLink>
            {checkIfGuestExists() ? (
              ""
            ) : (
              <PopoverLink to="/" {...{ theme }} onClick={() => signOut()}>
                <MenuIcon src={logout} alt="Logout icon" />
                Sign out
              </PopoverLink>
            )}
          </StyledLinks>
        </StyledPopoverContainer>
      </StyledPopover>
    </>
  );
};

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  height: 60px;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
`;

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0 9px 30px gray;
  }
`;

const StyledPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  background: #ffffff;
  width: 25rem;
  justify-content: space-between;
  align-content: flex-start;

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const StyledOverview = styled.div`
  text-align: left;
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  width: fit-content;
  margin: auto;
  font-size: 18px;
  color: #000000;

  @media (max-width: 440px) {
    padding: 1rem;
  }
`;

const StyledLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  background: #f6f9fc;
  padding: 1rem;
  flex-direction: column;
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.2rem;

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
  }

  &:after {
    transition: all ease-in-out 0.2s;
    background: ${(props: any) => props.theme.palette.primary.main};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }

  &:hover:after {
    width: 93%;
  }
`;

const PopoverLink = styled(StyledNavLink)`
  margin-bottom: 1rem;
  font-size: 18px;
  padding-left: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const MenuIcon = styled.img`
  padding-right: 1rem;
`;

import { fetchData } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Basic dGVzdDp0ZXN0",
  };
};

const getSubmittedByUserId = (userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/submissions/user/` + userId,
    "GET",
    requestHeaders(),
    null
  );
};

const getStatisticsSubmittedByUserId = (userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/submissions/submitted/` + userId,
    "GET",
    requestHeaders(),
    null
  );
};

const getStatisticsStaticContentSubmittedByUserId = (userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() +
      `/submissions/submitted/staticcontent/` +
      userId,
    "GET",
    requestHeaders(),
    null
  );
};

const getByTaskSubmissionIdAndUserId = (taskId: string, userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() +
      `/submissions/findby/` +
      taskId +
      "/" +
      userId,
    "GET",
    requestHeaders(),
    null
  );
};

const add = (taskSubmission: any) => {
  console.log("Adding new task: " + JSON.stringify(taskSubmission));
  return fetchData(
    config.getBeServerEndpoint() + `/submissions`,
    "POST",
    requestHeaders(),
    JSON.stringify(taskSubmission)
  );
};

export {
  getByTaskSubmissionIdAndUserId,
  getSubmittedByUserId,
  add,
  getStatisticsSubmittedByUserId,
  getStatisticsStaticContentSubmittedByUserId,
};

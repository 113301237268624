import { Typography, useTheme } from "@mui/material";
import styled from "@emotion/styled";

interface TransparentCardWithIconModel {
  icon: any;
  title: string;
  description: string;
  type: string;
  iconSide?: string;
}

export const TransparentCardWithIcon = (
  props: TransparentCardWithIconModel
) => {
  const { icon, title, description, type, iconSide } = props;
  const theme = useTheme();

  return (
    <>
      {iconSide === "left" ? (
        <Container icon={icon}>
          {icon ? (
            <Icon>
              <img src={icon} alt="Testing icon" />
            </Icon>
          ) : (
            ""
          )}
          <Card>
            <h3>{title}</h3>
            <Description theme={theme}>{description}</Description>
          </Card>
        </Container>
      ) : (
        <RightContainer icon={icon}>
          <RightCard>
            <h3>{title}</h3>
            <Description theme={theme}>{description}</Description>
          </RightCard>
          <Icon>
            {icon ? (
              <img
                className={`${type} === 'restApi'  ? 'rest-margin-top' : '', ${type} === 'unit' ? 'unit-margin-top' : ''`}
                src={icon}
                alt="Testing icon"
              />
            ) : (
              ""
            )}
          </Icon>
        </RightContainer>
      )}
    </>
  );
};

const Container = styled.div<{ icon: string }>`
  display: flex;
  text-align: end;
  justify-content: space-between;
  margin-bottom: ${(props) => (!props.icon ? "3rem" : "0")};
  width: 100%;
`;

const RightContainer = styled.div<{ icon: string }>`
  display: flex;
  margin-bottom: ${(props) => (!props.icon ? "3rem" : "0")};
  width: 100%;
`;

const Card = styled.div`
  background: #ffffff;
  width: 100%;
  min-width: 300px !important;
  height: 350px;
  padding: 1.5rem;
  border-radius: 15px;
  background: linear-gradient(to left, white 70%, #f6f9fc 100%);
`;

const RightCard = styled.div`
  background: #ffffff;
  width: 100%;
  min-width: 300px !important;
  height: 350px;
  padding: 1.5rem;
  border-radius: 15px;
  background: linear-gradient(to right, white 70%, #f6f9fc 100%);
`;

const Description = styled.h5`
  color: #747474;
  overflow: auto;
  height: 250px;
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1.2rem;
  line-height: 2rem;
`;

const Icon = styled.div`
  & .unit-margin-top {
    margin-top: 3.5rem;
  }

  & .rest-margin-top {
    margin-top: 7rem;
  }
`;

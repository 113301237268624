import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import personPlaceholder from "../../../../assets/images/djembo.jpeg";

interface TestimonialModel {
  name: string;
  position: string;
  testimonial: string;
  companyLogo?: any;
  count?: number;
}

export const Testimonial = (props: TestimonialModel) => {
  const { name, position, testimonial, companyLogo, count } = props;
  const theme = useTheme();

  return (
    <Container theme={theme} count={count}>
      <PersonPlaceholderContainer>
        <img
          src={personPlaceholder}
          alt="Person placeholder"
          width="86"
          height="86"
        />
      </PersonPlaceholderContainer>
      <Name variant="subtitle1">{name}</Name>
      <Position variant="subtitle2">{position}</Position>
      <StyledHr />
      <DescriptionContainer>
        <Description variant="caption">{testimonial}</Description>
      </DescriptionContainer>
      {companyLogo && (
        <CompanyLogo>
          <img src={companyLogo} alt="Quantox-company-img" height="70" />
        </CompanyLogo>
      )}
    </Container>
  );
};

const Container = styled.div<{ count: number | undefined }>`
  width: 330px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  @media (max-width: 1000px) {
    margin: auto;
  }
  margin: ${(props) => (props.count === 1 ? "auto" : "")};
`;

const PersonPlaceholderContainer = styled.div`
  position: absolute;
  img {
    position: relative;
    top: -4.5rem;
    left: -4.5rem;
  }
`;

const Name = styled(Typography)`
  text-align: center;
`;

const Position = styled(Typography)`
  text-align: center;
`;

const StyledHr = styled.hr`
  border-top: 1px solid #00efd1 !important;
  margin: 1rem 2rem;
`;

const DescriptionContainer = styled.div`
  overflow-y: auto;
  height: 350px;
  padding-right: 1rem;
`;

const Description = styled(Typography)`
  color: #747474;
`;

const CompanyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
  img {
    height: 50%;
    width: 50%;
  }
`;

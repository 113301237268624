import { useState } from "react";
import styled from "@emotion/styled";

import { Button, Modal } from "react-bootstrap";
import { OutlinedButton } from "../shared/components/buttons/OutlinedButton";
import lock from "../../assets/images/padlock/lock.png";
import { Typography } from "@mui/material";
import { Image } from "react-bootstrap";

interface TriggerModel {
  showSimple?: boolean;
}

export const Trigger = (props: TriggerModel) => {
  const [show, setShow] = useState(false);
  const { showSimple } = props;

  const handleHide = () => {
    setShow(false);
  };

  return (
    <div className="modal-container">
      {showSimple ? (
        <StyledImage src={lock} alt="lock icon" onClick={() => setShow(true)} />
      ) : (
        <ButtonWrapper>
          <OutlinedButton
            text="Unlock question"
            disabled={false}
            icon={lock}
            variant="outlined"
            type="action"
            executionMethod={() => setShow(true)}
            color="#000000"
          />
        </ButtonWrapper>
      )}

      <Modal
        show={show}
        onHide={handleHide}
        container={this}
        aria-labelledby="contained-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">Task is locked</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="caption">
            Task is available only for paid users.
            <p>
              <a href="/products-price">Buy QASkills </a> for more questions.
            </p>
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleHide()}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
`;

const StyledImage = styled(Image)`
  height: 40px;
  margin-left: 1.4rem;
`;

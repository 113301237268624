import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { BreadcrumbNavigation } from "../../../shared/components/breadcrumb-navigation/Breadcrumb-Navigation";
import MatTable from "../../../shared/components/MatTable";
import { getByTypeAndUserId } from "../../../shared/services/api/LearningApi";
import { getUser } from "../../../utils";
import { Mixpanel } from "../../../../../Mixpanel";
import { TaskDto } from "../../../shared/dtos/task.dto";
import {
  HeadingTypeEnum,
  TitleAndTaskCounter,
} from "../../../shared/components/title-and-task-counter/TitleAndTaskCounter";

export const AutomationTasks = () => {
  const [user, setUser] = useState({
    username: "",
    type: "",
    _id: "",
  });
  const [mochaRows, setMochaRows] = useState<TaskDto[]>([]);
  const [pythonUnitTestRows, setPythonUnitTestRows] = useState<TaskDto[]>([]);
  const [mochaRestApiRows, setMochaRestApiRows] = useState<TaskDto[]>([]);
  const [unitTestRestApiRows, setUnitTestRestApiRows] = useState<TaskDto[]>([]);

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  useEffect(() => {
    setUser({
      ...user,
      username: currentUser?.username,
      type: currentUser?.type,
      _id: currentUser?._id,
    });
  }, []);

  let userId = currentUser?._id;

  useEffect(() => {
    getByTypeAndUserId("mocha", userId).then((mocha: TaskDto[]) => {
      console.log("Get from API: " + mocha);
      setMochaRows(mocha);
    });

    getByTypeAndUserId("pythonunittest", userId).then(
      (pythonunittest: TaskDto[]) => {
        console.log("Get from API: " + pythonunittest);
        setPythonUnitTestRows(pythonunittest);
      }
    );

    getByTypeAndUserId("mocharestapi", userId).then(
      (mocharestapi: TaskDto[]) => {
        console.log("Get from API: " + mocharestapi);
        setMochaRestApiRows(mocharestapi);
      }
    );

    getByTypeAndUserId("unittestrestapi", userId).then(
      (unittestrestapi: TaskDto[]) => {
        console.log("Get from API: " + unittestrestapi);
        setUnitTestRestApiRows(unittestrestapi);
      }
    );
  }, [userId]);

  return (
    <Container>
      <Title>Automation Tasks</Title>
      <BreadcrumbNavigation title="Automation Tasks" />

      <AccordionWrapper>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() => {
                Mixpanel.track("Unit Testing content opened");
              }}
            >
              <TitleAndTaskCounter
                tasks1={mochaRows}
                tasks2={pythonUnitTestRows}
                title="Unit Testing"
                headingType={HeadingTypeEnum.H2}
              ></TitleAndTaskCounter>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion>
                <Accordion.Item eventKey="0.1">
                  <Accordion.Header
                    onClick={() => {
                      Mixpanel.track("Mocha unittest js content opened");
                    }}
                  >
                    <TitleAndTaskCounter
                      tasks1={mochaRows}
                      title="Mocha"
                      headingType={HeadingTypeEnum.H3}
                    ></TitleAndTaskCounter>
                  </Accordion.Header>
                  <Accordion.Body>
                    {mochaRows && (
                      <MatTable
                        location={{
                          taskPage: "Automation Tasks",
                          taskUrl: "/automation-tasks",
                        }}
                        rows={mochaRows}
                        userType={user.type}
                      ></MatTable>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="0.2">
                  <Accordion.Header
                    onClick={() => {
                      Mixpanel.track("Unittest unittest python content opened");
                    }}
                  >
                    <TitleAndTaskCounter
                      tasks1={pythonUnitTestRows}
                      title="Unittest"
                      headingType={HeadingTypeEnum.H3}
                    ></TitleAndTaskCounter>
                  </Accordion.Header>
                  <Accordion.Body>
                    {pythonUnitTestRows && (
                      <MatTable
                        location={{
                          taskPage: "Automation Tasks",
                          taskUrl: "/automation-tasks",
                        }}
                        rows={pythonUnitTestRows}
                        userType={user.type}
                      ></MatTable>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              onClick={() => {
                Mixpanel.track("Rest API Testing content opened");
              }}
            >
              <TitleAndTaskCounter
                tasks1={mochaRestApiRows}
                tasks2={unitTestRestApiRows}
                title="Rest API Testing"
                headingType={HeadingTypeEnum.H2}
              ></TitleAndTaskCounter>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion>
                <Accordion.Item eventKey="1.1">
                  <Accordion.Header
                    onClick={() => {
                      Mixpanel.track("Rest API Testing Mocha content opened");
                    }}
                  >
                    <TitleAndTaskCounter
                      tasks1={mochaRestApiRows}
                      title="Mocha"
                      headingType={HeadingTypeEnum.H3}
                    ></TitleAndTaskCounter>
                  </Accordion.Header>
                  <Accordion.Body>
                    {mochaRestApiRows && (
                      <MatTable
                        rows={mochaRestApiRows}
                        userType={user.type}
                      ></MatTable>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="1.2">
                  <Accordion.Header
                    onClick={() => {
                      Mixpanel.track(
                        "Rest API Testing Unittest content opened"
                      );
                    }}
                  >
                    <TitleAndTaskCounter
                      tasks1={unitTestRestApiRows}
                      title="Unittest"
                      headingType={HeadingTypeEnum.H3}
                    ></TitleAndTaskCounter>
                  </Accordion.Header>
                  <Accordion.Body>
                    {unitTestRestApiRows && (
                      <MatTable
                        rows={unitTestRestApiRows}
                        userType={user.type}
                      ></MatTable>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <Accordion>
            <Accordion.Item eventKey="2">
              <Accordion.Header
                onClick={() => {
                  Mixpanel.track("End2End Testing content opened");
                }}
              >
                End2End Testing
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="2.1">
                    <Accordion.Header
                      onClick={() => {
                        Mixpanel.track("End2End Testing Protractor content opened");
                      }}
                    >
                      Protractor
                    </Accordion.Header>
                    <Accordion.Body>
                      {protractorRows && (
                        <MatTable
                          rows={protractorRows}
                          userType={user.type}
                        ></MatTable>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
      </AccordionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;
  min-height: calc(100% - 15rem);
  height: auto;
  color: #000000;

  @media (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 970px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-top: 3rem;
`;

const AccordionWrapper = styled.div`
  height: 100%;
`;

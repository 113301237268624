import { fetchData, fetchDataWithoutBasicAuth } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
    return {
        "Content-Type": "application/json"
    };
};

const getCommentsByTaskId = (taskId: string) => {
    return fetchDataWithoutBasicAuth(
        config.getPlatformEndpoint() + `/comment/` + taskId,
        "GET",
        requestHeaders(),
        null
    );
};

const add = (comment: any) => {
    console.log("Adding new comment: " + JSON.stringify(comment));
    return fetchDataWithoutBasicAuth(
        config.getPlatformEndpoint() + `/comment`,
        "POST",
        requestHeaders(),
        JSON.stringify(comment)
    );
};

export { getCommentsByTaskId, add };

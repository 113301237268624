import { Typography } from "@mui/material";
import styled from "@emotion/styled";

import calculatorBug from "../../../assets/images/automation-infra/calculator-bug.svg";
import travelBug from "../../../assets/images/automation-infra/travel-bug.svg";
import calculator from "../../../assets/images/automation-infra/calculator.svg";
import travel from "../../../assets/images/automation-infra/travel.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const AutomationInfraCards = () => {
  return (
    <Container>
      <CardWrapper>
        <Card>
          <Calculator src={calculator} alt="" />
          <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
            <CardContentLeft>
              <CardTitle>Test Calculator App</CardTitle>
              <StyledHeading4>
                Learn how to create automation infrastructure for a calculator
                application designed as online application that performs all of
                the essential functions of a calculator.
              </StyledHeading4>
            </CardContentLeft>
          </AnimationOnScroll>
        </Card>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <BugIcon src={calculatorBug} alt="" />
        </AnimationOnScroll>
      </CardWrapper>

      <CardWrapper>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
          <BugIcon src={travelBug} alt="" />
        </AnimationOnScroll>
        <Card>
          <Travel src={travel} alt="" />

          <CardContent>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
              <CardTitle>Test Travel App</CardTitle>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              className="overflow-auto"
            >
              <StyledHeading4>
                Learn how to create automation infrastructure for OnlineTravel
                application which has a single database, a single load balancer
                in front of it, and several modules like travel booking, hotel
                booking, a payment module, and a users module.
              </StyledHeading4>
            </AnimationOnScroll>
          </CardContent>
        </Card>
      </CardWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 4rem;
  margin-bottom: 5rem;
  gap: 3rem;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  font-weight: bold;
  padding-bottom: 2rem;
  width: 70%;
  margin: auto;
`;

const Card = styled.div`
  background: #ffffff;
  height: 450px;
  width: 750px;
  box-shadow: 0 0 20px -10px #303941;
  border-radius: 10px;
  position: relative;

  @media (max-width: 920px) {
    width: 75%;
  }

  @media (max-width: 700px) {
    width: 95%;
  }

  @media (max-width: 700px) {
    overflow: auto;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CardContentLeft = styled.div`
  width: 600px;
  padding: 3rem;
  float: right;
  @media (max-width: 700px) {
    width: auto;
    padding: 1.5rem;
  }

  & p {
    text-align: left;

    @media (max-width: 768px) {
      text-align: right;
      padding-right: -2rem;
      float: right;
    }
  }
`;

const CardContent = styled.div`
  width: 600px;
  margin: auto;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: auto;
    padding: 1.5rem;
  }

  & p {
    text-align: left;

    @media (max-width: 768px) {
      text-align: left;
      padding-right: -2rem;
      float: left;
    }
  }
`;

const BugIcon = styled.img`
  margin-top: 9rem;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Calculator = styled.img`
  position: absolute;
  margin-left: -90px;
  margin-top: 20px;

  @media (max-width: 990px) {
    width: 30%;
    margin-left: -50px;
  }

  @media (max-width: 920px) {
    margin-top: 70px;
    margin-left: -90px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    margin-right: -30px;
  }

  @media (max-width: 700px) {
    position: absolute;
    left: 50px;
    top: -40px;
    height: 90px;
  }

  @media (max-width: 600px) {
    left: 75px;
  }
`;

const Travel = styled.img`
  position: absolute;
  margin-top: -40px;
  margin-right: -90px;
  right: 0;

  @media (max-width: 990px) {
    width: 30%;
    margin-right: -60px;
    margin-top: -30px;
  }

  @media (max-width: 920px) {
    margin-top: 50px;
    margin-right: -80px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    margin-right: -70px;
  }

  @media (max-width: 700px) {
    position: absolute;
    right: 20px;
    top: -40px;
    height: 100px;
  }

  @media (max-width: 600px) {
    right: 80px;
  }
`;

const StyledHeading4 = styled.h4`
  color: #747474;
  width: 70%;
  margin: auto;
  text-align: left;
  line-height: 2rem;

  @media (max-width: 700px) {
    overflow: auto;
    width: 100%;
  }
`;

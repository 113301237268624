import doubleCheckIcon from "../../../../assets/images/editor-actions/double-check-green.svg";
import playVideoIcon from "../../../../assets/images/editor-actions/play-video-blue.svg";
import AlertDismissable from "../../../controls/AlertDismissable";
import CodeEditor from "../../../controls/CodeEditor";
import OutputBox from "../../../controls/OutputBox";
import { getUser } from "../../../utils";
import { run } from "../../services/api/CompilerApi";
import { addExecution } from "../../services/api/ExecutionApi";
import { getByTaskIdAndUserId } from "../../services/api/LearningApi";
import {
  add,
  getByTaskSubmissionIdAndUserId,
} from "../../services/api/TaskSubmissionApi";
import { DynamicTable } from "./DynamicTable";
import { FullscreenEditor } from "./FullscreenEditor";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { Form, FormGroup, ProgressBar, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Mixpanel } from "../../../../../Mixpanel";
import { checkIfGuestExists } from "../../../utils/guestCheck";
import { LoginPopup } from "../../../login/LoginPopup";

interface AceEditorWrapperModel {
  task: any;
  updateCode: (code: string) => void;
  updateCounter: (counter: number) => void;
  question: any;
}

export const AceEditorWrapper = (props: AceEditorWrapperModel) => {
  const { task, updateCode, updateCounter, question } = props;
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [executingRun, setExecutingRun] = useState(false);

  const [requestInProgress, setRequestInProgress] = useState("");
  const [requestStatus] = useState(70);
  const [response, setResponse] = useState({
    status: "0",
    message: "",
  });
  const [, setTaskSubmissionResponse] = useState("");
  const [submittedCode, setSubmittedCode] = useState({
    submittedCode: "",
  });
  let updateSubmittedCode = useRef(false);

  const [key, setKey] = useState(0);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);
  const [loginMessage, setLoginMessage] = useState<string>("");

  const { id } = useParams();
  const { type } = useParams();

  const RUN_BUTTON_LOGIN_MESSAGE = "Login to run code.";
  const SUBMIT_BUTTON_LOGIN_MESSAGE = "Login to submit code.";

  let taskId = id as string;
  let taskType = type as string;

  let userId = JSON.parse(getUser() as string)?._id;

  useEffect(() => {
    if (taskId && userId) {
      getByTaskSubmissionIdAndUserId(taskId, userId).then(
        (currentSubmittedCode: any) => {
          console.log(
            "TaskSubmissionApi.getByTaskIdAndUserId: " +
              JSON.stringify(currentSubmittedCode)
          );

          if (
            Array.isArray(currentSubmittedCode) &&
            currentSubmittedCode.length
          ) {
            // Set only latest submittedCode
            setSubmittedCode(
              currentSubmittedCode[currentSubmittedCode.length - 1]
                .submittedCode
            );
            updateSubmittedCode.current = true;
          }
        }
      );
    }
  }, [taskId]);

  const handleCodeChange = (code: any) => {
    if (code) {
      console.log(code);
      updateCode(code);
    }
  };

  const handleRun = (event: { preventDefault: () => void }) => {
    setRequestInProgress("Running");
    setExecutingRun(true);
    event.preventDefault();
    task.taskId = taskId;
    Mixpanel.track("Editor - Run question");
    console.log("Run task: " + JSON.stringify(task));
    Mixpanel.track("Editor - Run question:" + question.question);
    run(task)
      .then((response: any) => {
        if (response.status === "0") {
          setShowAlert(false);
          setResponse({ ...response, response });
        } else {
          setResponse({ ...response, message: "" });
          setShowAlert(true);
        }
        setRequestInProgress("");
        setExecutingRun(false);
      })
      .catch((error: any) => {
        console.log(error);
        setRequestInProgress("");
        setExecutingRun(false);
        toast.error(error);
      });
  };

  const handleSubmitSolution = (event: { preventDefault: () => void }) => {
    setRequestInProgress("Running");
    setExecutingRun(true);
    event.preventDefault();
    task.taskId = taskId;
    console.log(task);
    Mixpanel.track("Editor - Submit question");
    Mixpanel.track("Editor - Submit question:" + question.question);
    run(task)
      .then((response: any) => {
        setResponse({ ...response, response });
        setRequestInProgress("");
        console.log("Response status:" + response.status);

        const userId = JSON.parse(getUser() as string)._id;
        if (response.status === "0" && taskId && userId) {
          const taskSubmissionRequest = {
            taskId,
            userId,
            submittedCode: task.code,
            staticContent: false,
          };
          updateSubmittedCode.current = true;
          add(taskSubmissionRequest).then(
            (currentTaskSubmissionResponse: any) => {
              setTaskSubmissionResponse(currentTaskSubmissionResponse);
              setSubmittedCode(
                task.code as unknown as SetStateAction<{
                  submittedCode: string;
                }>
              );
              updateSubmittedCode.current = true;
            }
          );

          getByTaskIdAndUserId(taskId, userId).then(
            (currentSubmittedCode: any) => {
              setSubmittedCode(
                task.code as unknown as SetStateAction<{
                  submittedCode: string;
                }>
              );
            }
          );

          const addTask = { taskId };
          addExecution(addTask).then((currentCounter: number) => {
            updateCounter(currentCounter);
          });
          setShowAlert(false);
          toast.success("Your solution has been submitted successfully.");
        } else {
          setResponse({ ...response, message: "" });
          setShowAlert(true);
          toast.error("An error occured.");
        }
        setRequestInProgress("");
        setExecutingRun(false);
      })
      .catch((error: string) => {
        console.log("Response ERROR: " + error);
        setExecutingRun(false);
        toast.error(error);
      });
  };

  const checkUserId = () => {
    // local, test, prod userId
    return (
      !userId ||
      userId === "507f191e810c19729de860ea" ||
      userId === "65af7bb2995c11003d57f4b5" ||
      userId === "65af7c46e70d9f0037825c74"
    );
  };

  return (
    <>
      <Form>
        <StyledFormGroup controlId="code">
          <Col sm={12}>
            <Tabs className="unselectable" onSelect={(tab) => setKey(tab)}>
              <TabList>
                <Tab>
                  <span className="add-your-solution-tab-step">
                    Add Your Solution
                  </span>
                </Tab>
                <Tab>Submitted Solution</Tab>
              </TabList>

              <TabPanel>
                <CodeEditor
                  onChange={handleCodeChange}
                  code={task.code}
                  lang={taskType}
                  isReadonly={false}
                />
              </TabPanel>
              <TabPanel>
                <CodeEditor
                  code={updateSubmittedCode.current ? submittedCode : ""}
                  lang={taskType}
                  onChange={function (arg0: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  isReadonly={true}
                />
              </TabPanel>
            </Tabs>
          </Col>
        </StyledFormGroup>
        <FormGroup>
          <ButtonsWrapper>
            {key === 0 && (
              <>
                {checkIfGuestExists() ? (
                  <>
                    <RunButton
                      id="run-button-step"
                      onClick={() => {
                        setIsShowLogin(true);
                        setLoginMessage(RUN_BUTTON_LOGIN_MESSAGE);
                      }}
                    >
                      <img src={playVideoIcon} alt="run" /> &nbsp; Run
                    </RunButton>
                    <SubmitButton
                      id="submit-button-step"
                      onClick={() => {
                        setIsShowLogin(true);
                        setLoginMessage(SUBMIT_BUTTON_LOGIN_MESSAGE);
                      }}
                    >
                      <img src={doubleCheckIcon} alt="submit" /> &nbsp; Submit
                    </SubmitButton>
                  </>
                ) : (
                  <>
                    <RunButton
                      id="run-button-step"
                      disabled={executingRun || checkUserId()}
                      onClick={handleRun}
                    >
                      <img src={playVideoIcon} alt="run" /> &nbsp; Run
                    </RunButton>
                    <SubmitButton
                      id="submit-button-step"
                      disabled={executingRun || checkUserId()}
                      onClick={handleSubmitSolution}
                    >
                      <img src={doubleCheckIcon} alt="submit" /> &nbsp; Submit
                    </SubmitButton>
                  </>
                )}
              </>
            )}

            <FullscreenButton onClick={() => setShow(true)}>
              Full screen
            </FullscreenButton>
          </ButtonsWrapper>
          {requestInProgress !== "" && <ProgressBar now={requestStatus} />}
        </FormGroup>
        <FormGroup>
          <Col sm={12}>
            <AlertDismissable
              show={response.status !== "0"}
              message={response.message}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={12}>
            {taskType !== "mysql" ? (
              <OutputBox
                show={response.status === "0"}
                message={response.message}
              />
            ) : response.message !== "" &&
              taskType === "mysql" &&
              response.status === "0" ? (
              <DynamicTable message={response.message} />
            ) : (
              <EmptyTerminal>
                <p>Use button 'Run' for testing your solution.</p>
                <p>Use button 'Submit' for submiting final solution.</p>
                <p>
                  'Without data you're just another person with an opinion. - W.
                  Edwards Deming'
                </p>
              </EmptyTerminal>
            )}
          </Col>
        </FormGroup>
      </Form>
      <LoginPopup
        isShowLogin={isShowLogin}
        setIsShowLogin={setIsShowLogin}
        loginMessage={loginMessage}
      />
      <FullscreenEditor
        task={task}
        isShow={show}
        code={updateSubmittedCode.current ? submittedCode : ""}
        setShow={setShow}
        updateCodeFromModal={updateCode}
      />
    </>
  );
};

const RunButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #00acea !important;
  font-weight: bold;
  font-size: 18px;
  border: 2px solid #00acea;
  width: 140px;
  background: white;
  margin: 0.5rem 0;
  height: 40px;

  &:disabled {
    color: gray !important;
    border: 2px solid gray;
    cursor: not-allowed;
    img {
      filter: grayscale(100%);
    }
  }

  @media (max-width: 380px) {
    width: auto;
  }
`;

const SubmitButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #2ef1d9 !important;
  font-weight: bold;
  font-size: 18px;
  border: 2px solid #2ef1d9;
  width: 180px;
  background: white;
  margin: 0.5rem 0;
  height: 40px;

  &:disabled {
    color: gray !important;
    border: 2px solid gray;
    cursor: not-allowed;
    img {
      filter: grayscale(100%);
    }
  }

  @media (max-width: 380px) {
    width: auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 363px) {
    flex-wrap: wrap;
  }
`;

const FullscreenButton = styled(Button)`
  text-transform: none;
  font-size: 1.3rem;
  border: 2px solid #00acea;
  width: 180px;
  margin: 0.5rem 0;
  background: white;
  height: 40px;

  @media (max-width: 380px) {
    width: auto;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const EmptyTerminal = styled.div`
  color: #cdee69 !important;
  font: 1rem Inconsolata, monospace;
  background: #000000 !important;
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

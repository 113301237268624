import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Coupon } from "./coupon/Coupon";
import { PromoCode } from "./promo-code/PromoCode";
import styled from "@emotion/styled";
import { PackagesModel } from "./PurchasePage";

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const CustomTabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

interface DiscountTabs {
  isLoggedIn: boolean;
  isChecked: boolean;
  onDataFromPromoCode: (data: PackagesModel[]) => void;
  sendPromoCode: (code: string) => void;
  selectedPackage: string;
}

export const DiscountTabs = (props: DiscountTabs) => {
  const [value, setValue] = React.useState(0);
  const {
    isLoggedIn,
    isChecked,
    onDataFromPromoCode,
    selectedPackage,
    sendPromoCode,
  } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: "black" } }}
        sx={{
          ".Mui-selected": {
            color: `black !important`,
          },
        }}
        value={value}
        onChange={handleChange}
        centered
      >
        <StyledTab label="Voucher" />
        <StyledTab label="Promo Code" />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        {isLoggedIn ? <Coupon isDisabled={!isChecked} /> : null}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {isLoggedIn ? (
          <>
            <StyledText>
              Your promo code discount will be applied on all packages.
            </StyledText>
            <PromoCode
              onDataFromPromoCode={onDataFromPromoCode}
              selectedPackage={selectedPackage}
              sendPromoCode={sendPromoCode}
            />
          </>
        ) : null}
      </CustomTabPanel>
    </Box>
  );
};

const StyledText = styled.p`
  padding-top: 1rem;
  text-align: center;
  margin-bottom: 0;
  font-size: 1.25rem;
`;

const StyledTab = styled(Tab)`
  text-transform: none;
`;

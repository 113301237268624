import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface SimpleTransparentCardModel {
  icon: any;
  name: string;
  description: string;
}

export const SimpleTransparentCard = (props: SimpleTransparentCardModel) => {
  const { icon, name, description } = props;
  return (
    <Card>
      <Icon>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
          <img src={icon} alt="Frameworks and languages icon" />
        </AnimationOnScroll>
      </Icon>

      <Content>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <Title>{name}</Title>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateOnce
          animateIn="animate__fadeInRight"
          className="overflow-auto"
        >
          <Description>{description}</Description>
        </AnimationOnScroll>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  margin-bottom: 3rem;
  margin-right: 1rem;
  @media (max-width: 500px) {
    width: 95%;
  },
`;

const Icon = styled.div`
  margin-right: 2rem;

  img {
    width: 80px;
    margin-top: 1.5rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-weight: bold;
`;

const Description = styled.h5`
  color: #747474;
  overflow: auto;
  line-height: 2rem;
`;

import styled from "@emotion/styled";
import { TaskDto } from "../../dtos/task.dto";

export enum HeadingTypeEnum {
  H2 = "h2",
  H3 = "h3",
}

interface TitleAndTaskCounterModel {
  tasks1: TaskDto[];
  tasks2?: TaskDto[];
  title: string;
  headingType: string;
}

export const TitleAndTaskCounter = (props: TitleAndTaskCounterModel) => {
  const { tasks1, tasks2, title, headingType } = props;
  const getFinishedTasks = (tasks1: TaskDto[], tasks2?: TaskDto[]): number => {
    let numberOfFinishedTasks = 0;
    tasks1.forEach((task) => {
      if (task.execution === "STARTED") {
        numberOfFinishedTasks += 1;
      }
    });
    if (tasks2) {
      tasks2.forEach((task) => {
        if (task.execution === "STARTED") {
          numberOfFinishedTasks += 1;
        }
      });
    }

    return numberOfFinishedTasks;
  };

  return (
    <Container>
      {headingType === HeadingTypeEnum.H2 ? (
        <StyledHeading2>{title}</StyledHeading2>
      ) : (
        <StyledHeading3>{title}</StyledHeading3>
      )}
      <TaskCounterHeader>
        {tasks2?.length
          ? getFinishedTasks(tasks1, tasks2) +
            " / " +
            (tasks1.length + tasks2.length)
          : getFinishedTasks(tasks1) + " / " + tasks1.length}
      </TaskCounterHeader>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TaskCounterHeader = styled.span`
  color: black;
  margin-right: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
`;

const TaskCounter = styled.span`
  color: black;
  margin-right: 1rem;
  font-size: 1.1rem;
`;

const StyledHeading2 = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 0;
`;

const StyledHeading3 = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 0;
`;

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getThemeByName } from "./theme";

export const ThemeContext = React.createContext(() => {});

const CustomThemeProvider = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const currentTheme = useSelector((state: any) => state.currentTheme);
  // Retrieve the theme object by theme name
  const theme = getThemeByName(currentTheme);

  return (
    <ThemeContext.Provider value={currentTheme}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;

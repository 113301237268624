import { FormControl } from "react-bootstrap";
import styled from "@emotion/styled";

interface OutputBoxProps {
  show: boolean;
  message: string | number | string[] | undefined;
}

function OutputBox(props: OutputBoxProps) {
  const { show, message } = props;
  if (show && message !== "") {
    return (
      <StyledFormControl
        as="textarea"
        name="code"
        rows={10}
        readOnly
        value={message}
      />
    );
  } else {
    return (
      <EmptyTerminal>
        <p>Use button 'Run' for testing your solution.</p>
        <p>Use button 'Submit' for submiting final solution.</p>
        <p>'Talk is cheap. Show me the code. - Linus Torvalds'</p>
      </EmptyTerminal>
    );
  }
}

export default OutputBox;

const StyledFormControl = styled(FormControl)`
  color: #cdee69 !important;
  font: 1rem Inconsolata, monospace;
  background: #000000 !important;
  margin-bottom: 0.5rem;

  // in order to get results as expected results prevent word breaking
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;

  .form-control {
    background: #000000 !important;
  }
`;

const EmptyTerminal = styled.div`
  color: #cdee69 !important;
  font: 1rem Inconsolata, monospace;
  background: #000000 !important;
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

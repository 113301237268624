import styled from "@emotion/styled";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { ContactUsForm } from "./ContactUsForm";

export const ContactUs = () => {
  return (
    <Container>
      <PageIntro
        description="Interested in learning more about QASkills? Feel free to fill out the form below to get in direct contact with one of our founders. "
        subtitle="Contact Us"
        title=""
      />
      <VerticalLine />
      <ContactUsForm buttonText="Send Message" url="/contactus" />
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (max-width: 1368px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Mixpanel } from "../../../../../Mixpanel";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useSelector } from "react-redux";
import { getUser } from "../../../utils";
import { PackageType } from "../../../roadmap-and-progress/RoadmapAndProgress";
import { LoginPopup } from "../../../login/LoginPopup";
import { useState } from "react";
import { checkIfGuestExists } from "../../../utils/guestCheck";

interface ContainedButtonModel {
  text: string;
  variant: "text" | "outlined" | "contained" | undefined;
  disabled: boolean;
  icon: any;
  customColor?: string | undefined;
  fromPage: string;
  triggerAnimation?: boolean;
}

export const ContainedButton = (props: ContainedButtonModel) => {
  let {
    text,
    variant,
    disabled,
    icon,
    customColor,
    fromPage,
    triggerAnimation,
  } = props;

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  let navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);
  const isGuestUser = checkIfGuestExists();

  if ((!isLoggedIn || isGuestUser) && text === "Buy QASkills") {
    text = "Tell me more";
  }

  const redirectToPayment = () => {
    Mixpanel.track(
      `The user navigated to the payment page from the ` + fromPage
    );
    if (isLoggedIn) {
      if (isGuestUser) {
        navigate("/roadmap-and-progress");
      } else {
        navigate("/payment");
      }
    } else {
      return (
        <LoginPopup isShowLogin={isShowLogin} setIsShowLogin={setIsShowLogin} />
      );
    }
  };

  const redirections = () => {
    if (!isLoggedIn) {
      setIsShowLogin(true);
    }
    currentUser?.activePackage === PackageType.yearlyPackageAndFinal
      ? navigate("/roadmap-and-progress")
      : redirectToPayment();
  };

  const checkTextOutput = (text: string) => {
    if (text === "Buy QASkills") {
      if (
        currentUser?.activePackage === PackageType.monthlyPackage ||
        currentUser?.activePackage === PackageType.yearlyPackage
      ) {
        return "Upgrade your plan";
      } else if (
        currentUser?.activePackage === PackageType.yearlyPackageAndFinal
      ) {
        return "Go to the Roadmap";
      }
    }
    return text;
  };

  return (
    <div>
      <LoginPopup isShowLogin={isShowLogin} setIsShowLogin={setIsShowLogin} />
      {triggerAnimation ? (
        <AnimationOnScroll animateOnce animateIn="animate__flipInX" offset={10}>
          <Container>
            <StyledButton
              customcolor={customColor}
              onClick={() => redirections()}
              variant={variant}
              disabled={disabled}
            >
              {checkTextOutput(text)}
            </StyledButton>
            <ButtonIcon>
              <Circle>
                <img src={icon} alt="Button icon" />
              </Circle>
            </ButtonIcon>
          </Container>
        </AnimationOnScroll>
      ) : (
        <Container>
          <StyledButton
            customcolor={customColor}
            onClick={() => redirections()}
            variant={variant}
            disabled={disabled}
          >
            {checkTextOutput(text)}
          </StyledButton>
          <ButtonIcon>
            <Circle>
              <img src={icon} alt="Button icon" />
            </Circle>
          </ButtonIcon>
        </Container>
      )}
    </div>
  );
};

const Container = styled.div`
  position: relative;
  width: 340px;
  height: 70px;

  @media (max-width: 420px) {
    width: auto;
  }
`;

const StyledButton = styled(Button) <{ customcolor: string | undefined }>`
  width: 100%;
  height: 70px;
  padding-left: 5rem;
  border-radius: 20px;
  line-height: 1.5rem;
  text-transform: none;
  box-shadow: none;
  background-image: linear-gradient(
    to right,
    ${(props: any) => props.customcolor ?? props.theme.palette.primary.main},
    ${(props: any) => props.customcolor ?? props.theme.palette.secondary.main}
  );
`;

const ButtonIcon = styled.div`
  position: relative;
  top: -107%;
  left: 5%;
  cursor: pointer;
  pointer-events: none;
`;

const Circle = styled.div`
  width: 70px;
  height: 75px;
  border-radius: 0 68px 0 20px;
  border-right: 5px solid #f6f9fc;
  border-top: 5px solid #f6f9fc;

  img {
    position: absolute;
    top: 38%;
    left: 4%;
  }
`;

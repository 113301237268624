import styled from "@emotion/styled";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { FeedbackForm } from "./FeedbackForm";

export const Feedback = () => {
  return (
    <Container>
      <PageIntro
        description="Have something to say about QASkills? Great! We love to hear feedback from people. Feel free to share feedback about your experience with us below."
        subtitle="Feedback"
        title="Feedback"
      />
      <VerticalLine />
      <FeedbackForm buttonText="Send feedback" url="/feedback" />
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

import styled from "@emotion/styled";

interface DynamicTableModel {
  message: any;
}

export const DynamicTable = (props: DynamicTableModel) => {
  const { message } = props;

  const generateTable = (arrayOfArrays: string) => {
    console.log("Response db task: " + arrayOfArrays);
    let removedFirstTwoChars = arrayOfArrays.substring(2);
    let removedLastTwoChars = removedFirstTwoChars.slice(0, -2);
    let splittedArray = removedLastTwoChars.split("],[");

    let table = "";
    if (splittedArray.length > 0) {
      table.concat("<ListGroup>");
      // let table = "";
      let tableHeader = "";
      let tableBody = "";
      let i;
      table = table.concat(
        '<div><table style="width:100%;" border="1"><tbody>'
      );
      for (i = 0; i < splittedArray.length; i++) {
        if (i === 0) {
          tableHeader = tableHeader.concat("<tr>");
          let splittedArrayHeader = splittedArray[i].split(",");

          let headerIndex;
          for (
            headerIndex = 0;
            headerIndex < splittedArrayHeader.length;
            headerIndex++
          ) {
            tableHeader = tableHeader.concat(
              "<th>" + splittedArrayHeader[headerIndex] + "</th>"
            );
          }
          tableHeader = tableHeader.concat("</tr>");
        }

        if (i > 0) {
          tableBody = tableBody.concat("<tr>");
          let splittedLineInBody = splittedArray[i].split(",");

          let bodyLineIndex;
          for (
            bodyLineIndex = 0;
            bodyLineIndex < splittedLineInBody.length;
            bodyLineIndex++
          ) {
            tableBody = tableBody.concat(
              "<td>" + splittedLineInBody[bodyLineIndex] + "</td>"
            );
          }
          tableBody = tableBody.concat("</tr>");
        }
      }
      table = table.concat(tableHeader);
      table = table.concat(tableBody);
      table = table.concat("</tbody></table></div>");

      table = table.toString().replace(/"/g, "");
    }

    return <TableTerminal dangerouslySetInnerHTML={{ __html: table }} />;
  };

  return generateTable(message);
};

const TableTerminal = styled.div`
  color: #cdee69 !important;
  font: 1rem Inconsolata, monospace;
  background: #000000 !important;
  padding: 1rem 0.5rem;
  overflow: auto;
  margin-bottom: 0.5rem;
  table {
    th,
    td {
      padding: 5px;
    }
  }
`;

import React, { useEffect, useRef } from "react";

// Extend the Window interface to include the Trustpilot property
interface ExtendedWindow extends Window {
  Trustpilot?: any; // Adjust the type accordingly based on the Trustpilot API
}

const TrustpilotWidget = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Use the ExtendedWindow interface to access the Trustpilot property on the window object
    const windowWithTrustpilot = window as ExtendedWindow;

    // Check if windowWithTrustpilot.Trustpilot is available
    if (windowWithTrustpilot?.Trustpilot) {
      windowWithTrustpilot.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="65a25141fe59a0421a298c42"
      data-style-height="52px"
      data-style-width="100%"
    >
      <a
        href="https://www.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export default TrustpilotWidget;

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import documentImg from "../../../assets/images/document.svg";
import { SmallProgressBar } from "../../shared/components/progress-bar/SmallProgressBar";
import { AnimationOnScroll } from "react-animation-on-scroll";

export interface RoadmapCardModel {
  title: string;
  description: string;
  lesson: string;
  totalTasks: number;
  completedTasks: number;
}

export const RoadmapCard = (props: RoadmapCardModel) => {
  const { title, description, lesson, totalTasks, completedTasks } = props;

  return (
    <Container completedTasks={completedTasks} totalTasks={totalTasks}>
      <AnimationOnScroll animateOnce animateIn="animate__backInUp" offset={100}>
        {/*Calculate and show percentage of done tasks if they are not all completed*/}
        {/* {completedTasks !== totalTasks ? (
        <SmallProgressBar percentage={(100 * completedTasks) / totalTasks} />
      ) : (
        ""
      )} */}
        <h3>
          <b>{title}</b>
        </h3>
        <Description>
          <h5>{description}</h5>
        </Description>

        {/* <TasksStatus>
        {totalTasks === completedTasks ? (
          <CompletedText variant="caption">COMPLETED</CompletedText>
        ) : (
          <Lesson>
            <img src={documentImg} alt="Document" />
            <CurrentLesson variant="caption">{lesson}</CurrentLesson>
          </Lesson>
        )}
        <TasksCount>
          <Typography variant="body1">{completedTasks}</Typography>
          <TotalTaskCount variant="caption">/{totalTasks}</TotalTaskCount>
        </TasksCount>
      </TasksStatus> */}
      </AnimationOnScroll>
    </Container>
  );
};

const Container = styled.div<{ completedTasks: number; totalTasks: number }>`
  background: ${(props) =>
    props.completedTasks === props.totalTasks ? "#d8fcf7" : "#ffffff"};
  border-radius: 10px;
  padding: 1.5rem 2rem;
  pointer-events: ${(props: any) => (props.completedTasks === 0 ? "none" : "")};
  height: 300px;
  box-shadow: 0 0 20px #dcdcdc;
  @media (max-width: 1432px) {
    height: 300px;
  }
  @media (max-width: 966px) {
    height: 350px;
  }
  position: relative;
`;

const Description = styled.div`
  width: 90%;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #747474;

  h5 {
    line-height: 2rem;
  }

  > span {
    line-height: normal;
  }

  @media (max-width: 966px) {
    width: 100%;
    height: 240px;
  }
`;

const CompletedText = styled(Typography)`
  color: #00efd1;
  font-weight: bolder;
`;

const TasksCount = styled.div`
  display: flex;
`;

const TasksStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const Lesson = styled.div`
  width: calc(100% - 60px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CurrentLesson = styled(Typography)`
  padding-left: 5px;
`;

const TotalTaskCount = styled(Typography)`
  display: flex;
  align-items: end;
  color: #9c9c9c;
`;

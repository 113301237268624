import mixpanel, { Dict } from "mixpanel-browser";
import { getUser } from "./core/components/utils";
mixpanel.init("3a4126047bda4c6e2cd67655ba1d8221");

let env_check = process.env.NODE_ENV === "production" || "development";

let actions = {
  identify: (id: string | undefined) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props?: Dict | undefined) => {
    if (env_check) {
      let currentUser = JSON.parse(getUser() as string);
      let propUser = {}
      if (currentUser !== null) {
        // console.log("Mixpanel user: " + currentUser.username);
        propUser = {
          $distinct_id: currentUser.username,
          $username: currentUser.username,
        }
      } else {
        // console.log("Mixpanel user: guest");
        propUser = {
          $distinct_id: 'guestId',
          $username: 'guest',
        }
      }
      mixpanel.track(name, propUser);
    }
  },
  people: {
    set: (props: Dict) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;

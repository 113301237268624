import messageIcon from "../../../../assets/images/message_icon.svg";
import { getPlatformEndpoint } from "../../../../config";
import LoadingPage from "../../../shared/components/LoadingPage";
import { OutlinedButton } from "../../../shared/components/buttons/OutlinedButton";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getUser } from "../../../utils";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getCommentsByTaskId } from "../../services/api/CommentsApi";

interface AddCommentFormModel {
  buttonText: string;
  url: string;
}

interface CommentsModel {
  description: string;
  name: string;
  taskId: string;
  title: string;
  userId: string;
}

type FormValues = {
  name: string;
  title: string;
  description: string;
  taskId: string;
  userId: string;
};

export const AddCommentForm = (props: AddCommentFormModel) => {
  const [isLoading, setIsLoading] = useState(false);
  const userId = JSON.parse(getUser() as string)._id;
  const userName = JSON.parse(getUser() as string).name;
  const { id } = useParams();
  let taskId = id as string;

  const { buttonText, url } = props;

  const [comments, setComments] = useState<CommentsModel[]>([]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      title: "",
      description: "",
      taskId: "",
      userId: "",
    },
  });

  useEffect(() => {
    getCommentsByTaskId(taskId).then((comments: CommentsModel[]) => {
      setComments(comments);
    });
  }, []);

  const handleFormSubmit = (form: FormValues) => {
    setIsLoading(true);
    const promise = axios
      .post(
        getPlatformEndpoint() + url,
        {
          name: userName,
          title: form.title,
          description: form.description,
          taskId: taskId,
          userId: userId,
        },
        {
          headers: {
            // Authorization: "Basic dGVzdDp0ZXN0",
            "content-type": "application/json",
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        console.log(response.data);

        getCommentsByTaskId(taskId).then((comments: CommentsModel[]) => {
          setComments(comments);
        });
        reset();
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message =
      "Comment has been added. Thank you for your assistance in making our platform better. BR, QASkills Team";

    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {Array.isArray(comments) && comments.length === 0 ? (
        <div>No one has commented yet.</div>
      ) : (
        comments.map((comments: CommentsModel, index) => (
          <div key={index}>
            <Name variant="caption">{comments.name}</Name>
            <MessageContainer>
              <Title variant="caption" align="center">
                {comments.title}
              </Title>
              <Typography variant="caption">{comments.description}</Typography>
            </MessageContainer>
          </div>
        ))
      )}
      <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
        <FieldTitle variant="caption">Title</FieldTitle>
        <StyledField
          {...register("title", {
            required: true,
          })}
        />
        {errors.title && !errors.title?.message && (
          <ErrorMessage>Title is required.</ErrorMessage>
        )}
        {errors.title?.message && (
          <ErrorMessage>{errors.title?.message}</ErrorMessage>
        )}

        <FieldTitle variant="caption">Description</FieldTitle>
        <StyledField
          {...register("description", { required: true })}
          multiline
          rows={3}
        />
        {errors.description && (
          <ErrorMessage>Description is required.</ErrorMessage>
        )}

        <ButtonWrapper>
          <OutlinedButton
            text={buttonText}
            variant="outlined"
            disabled={false}
            icon={messageIcon}
            type="submit"
            redirectLink={undefined}
            color="#000000"
            triggerAnimation={false}
          />
        </ButtonWrapper>
      </StyledForm>
    </>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const FieldTitle = styled(Typography)`
  width: 70%;
  padding-top: 1rem;
  @media (max-width: 820px) {
    width: 85%;
  }
`;

const StyledField = styled(OutlinedInput)`
  width: 70%;
  color: gray;
  background: #ffffff;
  line-height: 1rem;

  @media (max-width: 820px) {
    width: 85%;
  }
  & .MuiOutlinedInput-root {
    background: #ffffff;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 5px #dcdcdc;
  }

  & .MuiOutlinedInput-input {
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  opacity: 1;
  zoom: 0.8;
`;

const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  @media (max-width: 820px) {
    width: 85%;
  }
  color: ${(props: any) => props.theme.palette.error.main};
  font-size: 16px;
`;

const MessageContainer = styled.div`
    position: relative;
    margin: 0.5rem;

    span {
      font-size: 18px;
      line-height: 1.5rem;
      overflow-wrap: break-word;
    }
    
    background-color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1.25em;
    border-radius: 1rem;
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
  
  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em; // offset should move with padding of parent
    border: .75rem solid transparent;
    border-top: none;
  
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
  }
  }
`;

const CommentNumber = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 10px;
  background: #fff;
  border: 2px solid #000;
  color: #000;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Typography)`
  font-weight: bolder;
  padding-bottom: 0.5rem;
`;

const Name = styled(Typography)`
  font-size: 16px;
`;

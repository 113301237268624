import { Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { SimpleTransparentCard } from "./SimpleTransparentCard";
import mocha from "../../../../assets/images/frameworks-and-languages/mocha.svg";
import protractor from "../../../../assets/images/frameworks-and-languages/protractor.svg";
import unittest from "../../../../assets/images/frameworks-and-languages/potion.svg";
import javaScript from "../../../../assets/images/frameworks-and-languages/javascript.svg";
import python from "../../../../assets/images/frameworks-and-languages/python.svg";
import redBug from "../../../../assets/images/bug-icons/transparent-bug-red-2.svg";
import greenBug from "../../../../assets/images/bug-icons/transparent-bug-green.svg";
import blueBug from "../../../../assets/images/bug-icons/transparent-bug-blue.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const FrameworksAndLanguages = () => {
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery("(max-width:819px)");

  return (
    <>
      <TitleContainer>
        <AnimationOnScroll animateOnce animateIn="animate__tada">
          <img src={greenBug} alt="Green bug icon" />
        </AnimationOnScroll>
        <Title theme={theme}>
          3 different Frameworks in <br />2 different Languages
        </Title>
        {!isSmallerDevice ? (
          <AnimationOnScroll animateOnce animateIn="animate__tada">
            <img src={blueBug} alt="Blue bug icon" />
          </AnimationOnScroll>
        ) : (
          ""
        )}
      </TitleContainer>

      <Content isSmallerDevice={isSmallerDevice}>
        <Items>
          <SimpleTransparentCard
            description="Mocha is a solid open-source testing framework used by JavaScript developers for unit testing. We like frameworks that are easy to use, flexible and have a fantastic support community."
            icon={mocha}
            name="Mocha"
          />
          <SimpleTransparentCard
            description="Unittest is the first Python based automated unit test framework designed to work with the Python standard library. Knowing such a framework can benefit a QA engineer as Python is widely used."
            icon={unittest}
            name="Unittest"
          />
          <SimpleTransparentCard
            description="Protractor is an End To End test framework for Angular and AngularJS applications. Automating testing frameworks helps you improve your testing process quality, speed, and accuracy. If your dream company is using such technologies, we have practical examples for you at QASkills.io"
            icon={protractor}
            name="Protractor"
          />
        </Items>
        <Items>
          <SimpleTransparentCard
            description="JavaScript is a scripting language, and it’s a relatively easy programming language to learn as a beginner. It’s great to know it among your first languages because you can achieve a lot with it quickly. You can try a lot of basic examples here on QASkills.io."
            icon={javaScript}
            name="JavaScript"
          />
          <SimpleTransparentCard
            description="Python is an excellent option for QA engineers as an easy-to-learn scripting language. Learning to code in Python can be fun if you pick up an interesting idea that can translate into a valuable piece of code, and there are many practical examples on QASkills.io."
            icon={python}
            name="Python"
          />
        </Items>
        <Icon isSmallerDevice={isSmallerDevice}>
          <AnimationOnScroll animateOnce animateIn="animate__tada">
            <img src={redBug} alt="Transparent red bug" />
          </AnimationOnScroll>
        </Icon>
      </Content>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  text-align: center;
  padding: 2rem 1rem 6rem 1rem;
  font-weight: bold;
`;

const Content = styled.div<{ isSmallerDevice: boolean }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: ${(props: any) => (props.isSmallerDevice ? "column" : "row")};
  padding-top: 1rem;

  > div:nth-of-type(1) {
    border-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='4' stroke-dasharray='20%2c 30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")
      15 fill repeat;
    border-style: dashed;
    border-width: ${(props: any) =>
      !props.isSmallerDevice ? "0 10px 0 0" : "0"};
    margin-right: ${(props: any) => (!props.isSmallerDevice ? "4.5%" : "")};
    margin-bottom: 3rem;
    justify-content: ${(props: any) =>
      props.isSmallerDevice ? "center" : "left"};
    align-items: ${(props: any) =>
      props.isSmallerDevice ? "center" : "start"};
  }

  > div:nth-of-type(2) {
    display: flex;
    justify-content: ${(props: any) =>
      props.isSmallerDevice ? "center" : "right"};
    align-items: ${(props: any) => (props.isSmallerDevice ? "center" : "end")};
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  // @media (min-width: 1023px) {
  //   padding-left: 2rem;
  // }
`;

const Icon = styled.div<{ isSmallerDevice: boolean }>`
  position: absolute;
  bottom: ${(props: any) => (props.isSmallerDevice ? "-70px" : "0")};
  left: 55%;
`;

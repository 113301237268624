import styled from "@emotion/styled";

import { LinearProgress, Typography, useTheme } from "@mui/material";

import { LogoIcon } from "../images/Logo";

export default function LoadingPage() {
  const theme = useTheme();

  return (
    <PageContainer theme={theme}>
      <LogoIconContainer theme={theme}>
        <LogoIcon />
      </LogoIconContainer>
      <StyledTypography variant="h6" theme={theme}>
        Loading...
      </StyledTypography>
      <StyledLinearProgress />
    </PageContainer>
  );
}

const LogoIconContainer = styled.div`
  & > svg {
    height: 550px;
    width: 350px;
    margin: auto;
    display: block;

    @media (max-width: 770px) {
      zoom: 0.5;
    }
  }
`;

const StyledTypography = styled(Typography)`
  background-image: linear-gradient(
    to top right,
    ${(props: any) => props.theme.palette.primary.main},
    ${(props: any) => props.theme.palette.secondary.main}
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin-bottom: 0.5rem !important;
`;

const PageContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: #f6f9fc;
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 40%;
  margin: auto;
`;

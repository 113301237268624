import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Quiz from "react-quiz-component";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { LogoIcon } from "../images/Logo";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface QuizComponentProps {
  isOpen: boolean;
  setOpenPopup: (data: boolean) => void;
  quizData: any;
}

export default function QuizComponent(props: QuizComponentProps) {
  const { isOpen, setOpenPopup, quizData } = props;

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <StyledDialog
      fullWidth={true}
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <LogoAndQuizContainer>
        <Container>
          <Wrapper>
            <StyledIconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </StyledIconButton>
            <LogoIcon />
          </Wrapper>

          <Quiz quiz={quizData} showInstantFeedback={true} />
        </Container>
      </LogoAndQuizContainer>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    background-image: linear-gradient(to top right, #00acea, #00efd1);
  }
`;

const Wrapper = styled.div`
  position: relative;
  & svg {
    display: block;
    margin: auto;
    zoom: 1.5;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 1px;
  top: -40px;
  zoom: 0.8;
`;

const Container = styled.div`
  margin: auto;
  background: white;
  justify-content: center;
  display: flex;
  padding-top: 2rem;
  flex-direction: column;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

  @media (max-width: 620px) {
    width: 375px;
  }
`;

const LogoAndQuizContainer = styled.div`
  margin-top: 1rem;
  background; white !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

import { Modal, FormGroup, Col, Button } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CodeEditor from "../../../controls/CodeEditor";
import "react-tabs/style/react-tabs.css";
import { useParams } from "react-router-dom";

interface FullscreenEditorModel {
  task: any;
  isShow: boolean;
  code: any;
  setShow: (data: boolean) => void;
  updateCodeFromModal: (code: string) => void;
}

export const FullscreenEditor = (props: FullscreenEditorModel) => {
  const { task, code, isShow, setShow, updateCodeFromModal } = props;

  const { type } = useParams();
  let taskType = type as string;

  const handleHide = () => {
    setShow?.(false);
  };

  const handleCodeChange = (code: any) => {
    if (code) {
      console.log(code);
      updateCodeFromModal(code);
    }
  };

  return isShow ? (
    <Modal
      show={isShow}
      onHide={handleHide}
      dialogClassName="custom-modal"
      className="custom-modal"
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup controlId="code">
          <Col sm={12}>
            <Tabs className="unselectable">
              <TabList>
                <Tab>Add Your Solution</Tab>
                <Tab>Submitted Solution</Tab>
              </TabList>

              <TabPanel>
                <CodeEditor
                  onChange={handleCodeChange}
                  code={task.code}
                  lang={taskType}
                  isReadonly={false}
                />
              </TabPanel>
              <TabPanel>
                <CodeEditor
                  code={code}
                  lang={taskType}
                  isReadonly={false}
                  onChange={function (arg0: any): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </TabPanel>
            </Tabs>
          </Col>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <div></div>
  );
};

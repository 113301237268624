import { useLocation, useSearchParams } from "react-router-dom";

import { CustomErrorPage } from "../custom-error-page/CustomErrorPage";
import automationInfra from "../../../../assets/images/automation-infra/automation-infra.svg";

interface TrnStatusModel {
  shoppingCartId: string;
  wsPayOrderId: string;
  signature: string;
  success: string;
  approvalCode: string;
  errorCode: string;
}

export const PaymentPageBasedOnTransaction = () => {
  const location = useLocation();
  const activeUrl = location?.pathname;

  const [searchParams, setSearchParams] = useSearchParams();
  const shoppingCartId = searchParams.get("ShoppingCartID");
  const wsPayOrderId = searchParams.get("WsPayOrderId");
  const signature = searchParams.get("Signature");
  const success = searchParams.get("Success");
  const approvalCode = searchParams.get("ApprovalCode");
  const errorCode = searchParams.get("ErrorCodes");

  const trnStatus: TrnStatusModel = {
    shoppingCartId: shoppingCartId !== null ? shoppingCartId : "",
    wsPayOrderId: wsPayOrderId !== null ? wsPayOrderId : "",
    signature: signature !== null ? signature : "",
    success: success !== null ? success : "",
    approvalCode: approvalCode !== null ? approvalCode : "",
    errorCode: errorCode !== null ? errorCode : "",
  };

  console.log("Transaction Status: " + JSON.stringify(trnStatus));

  const getDescription = (): string => {
    let message = "";
    switch (activeUrl) {
      case "/success":
        message =
          "Your transaction was successful. Your payment card account has been debited, please wait for confirmation.";
        return message;
      case "/error":
        // TODO: Retrieve error code from response
        message = "Your payment card account is not debited.";
        return message;
      case "/cancel":
        message = "Your transaction has been canceled.";
        return message;
      default:
        message = "";
        return message;
    }
  };

  return (
    <CustomErrorPage
      title="Transaction details"
      description={getDescription()}
      image={automationInfra}
      buttonText="Back home"
      redirectLink="/roadmap-and-progress"
      trnStatus={trnStatus}
    />
  );
};

import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";
import { Mixpanel } from "../../../../Mixpanel";

import dinaCard from "../../../assets/images/payment/dinacard50.png";
import masterCard from "../../../assets/images/payment/MasterCard50.gif";
import visaCard from "../../../assets/images/payment/Visa50.gif";
import maestroCard from "../../../assets/images/payment/maestro50.gif";
import masterIdCheckIcon from "../../../assets/images/payment/mastercard-identity-check.png";
import visaSecureIcon from "../../../assets/images/payment/visa-secure.png";
import otpBankIcon from "../../../assets/images/payment/otp.png";
import linkedinIcon from "../../../assets/images/social-media/linkedin.svg";
import instagramIcon from "../../../assets/images/social-media/instagram.svg";
import facebookIcon from "../../../assets/images/social-media/facebook.svg";

const Footer = () => {
  const theme = useTheme();
  return (
    <StyledBackground {...{ theme }}>
      <HorizontalLine />

      <FooterWrapper>
        <CompanyInfo>
          <Typography>QASkills labs d.o.o. Kragujevac</Typography>
          <Typography>Address: Kragujevac, Vršačka 3</Typography>
          <Typography>Programming (6201)</Typography>
          <Typography>VAT: 21872164</Typography>
          <Typography>Tax number: 113466433</Typography>
          <Typography>Website: https://qaskills.io</Typography>
          {/* <Typography>Mob:</Typography> */}
          <Typography>E-mail: office@qaskills.io</Typography>
        </CompanyInfo>

        <SocialNetworks>
          <b>Follow us</b>
          <SocialIcons>
            <a
              href="https://www.linkedin.com/company/qaskills/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinIcon}
                alt="QASkills linkedin page"
                width="41"
                height="41"
              />
            </a>

            <a
              href="https://www.instagram.com/qaskills.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramIcon}
                alt="QASkills instagram page"
                width="56"
                height="56"
              />
            </a>

            <a
              href="https://www.facebook.com/profile.php?id=61555643242920"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookIcon}
                alt="QASkills facebook page"
                width="41"
                height="41"
              />
            </a>
          </SocialIcons>
        </SocialNetworks>

        <Payment>
          <div>
            <a
              href="http://www.wspay.rs"
              title="WSpay - Web Secure Payment Gateway"
              target="_blank"
            >
              <img
                alt="WSpay - Web Secure Payment Gateway"
                src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png"
                width="118"
                height="50"
              />
            </a>
          </div>
          <CreditCards>
            <img src={dinaCard} alt="Dina card" width="93" height="50" />
            <img src={masterCard} alt="Master card" width="70" height="50" />
            <img src={maestroCard} alt="Maestro card" width="60" height="50" />
            <img src={visaCard} alt="Visa card" width="80" height="50" />
          </CreditCards>
          <CreditCards>
            <a
              href="https://www.otpbanka.rs"
              target="_blank"
              title="OTP Banka"
              rel="noopener noreferrer"
            >
              <img width="170" height="50" src={otpBankIcon} alt="OTP Banka" />
            </a>
            <a
              href="https://www.mastercard.rs/sr-rs/consumers/find-card-products/credit-cards.html"
              target="_blank"
              title="Master ID Check"
              rel="noopener noreferrer"
            >
              <img
                src={masterIdCheckIcon}
                alt="Master ID Check"
                width="67"
                height="50"
              />
            </a>
            <a
              href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
              target="_blank"
              title="Visa Secure"
              rel="noopener noreferrer"
            >
              <img
                src={visaSecureIcon}
                alt="Visa Secure"
                width="50"
                height="50"
              />
            </a>
          </CreditCards>
        </Payment>
      </FooterWrapper>

      <footer className=" text-center">
        <LinksWrapper>
          <StyledNavLink
            to="/contactus"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation contactus.")}
          >
            Contact Us
          </StyledNavLink>
          <StyledNavLink
            to="/faq"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation faq.")}
          >
            FAQ
          </StyledNavLink>
          {/*<StyledNavLink to="/career" {...{ theme }}>*/}
          {/*  Career*/}
          {/*</StyledNavLink>*/}
          <StyledNavLink
            to="/reviews"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation reviews.")}
          >
            Reviews
          </StyledNavLink>
          <StyledNavLink
            to="/feedback"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation feedback.")}
          >
            Submit a Feedback
          </StyledNavLink>
          <StyledNavLink
            to="/terms-of-use"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation terms-of-use.")}
          >
            Terms Of Use
          </StyledNavLink>
          <StyledNavLink
            to="/privacy-policy"
            {...{ theme }}
            onClick={() => Mixpanel.track("Footer navigation privacy-policy.")}
          >
            Privacy Policy
          </StyledNavLink>
        </LinksWrapper>
        <StyledP {...{ theme }}>
          &copy; {new Date().getFullYear()} QASkills.io
        </StyledP>
      </footer>
    </StyledBackground>
  );
};

export default Footer;

const StyledBackground = styled.div`
  background: #f6f9fc;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const HorizontalLine = styled.div`
  border-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2393999FFF' stroke-width='4' stroke-dasharray='20%2c 30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")
    15 fill repeat;
  border-style: dashed;
  border-width: 10px 0 0 0;
  padding-bottom: 2rem;
  width: 40%;
`;

const LinksWrapper = styled.div`
  text-align: center;
  line-height: 2rem;
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-right: 1px solid black;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  :last-child {
    border-right: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid ${(props: any) => props.theme.palette.primary.main};
  }
`;

const StyledP = styled.p`
  color: #747474;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;

  p {
    font-size: 16px !important;
  }
`;

const Payment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  gap: 1rem;
  align-self: center;

  div {
    margin: auto;
  }
`;

const CreditCards = styled.div`
  display: flex;
  margin: 0.5rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
`;

const SocialNetworks = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

import styled from "@emotion/styled";
import ciCd from "../../../assets/images/automation-infra/ci-cd.svg";
import automation from "../../../assets/images/automation-infra/automation.svg";
import codeAnalysis from "../../../assets/images/automation-infra/code-analysis.svg";
import logging from "../../../assets/images/automation-infra/logging.svg";
import monitoring from "../../../assets/images/automation-infra/monitoring.svg";
import { Typography } from "@mui/material";
import Slider from "react-slick";
import { useState } from "react";

export const AutomationInfraSlider = () => {
  const [index, setIndex] = useState(0);

  var settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    focusOnSelect: false,
    centerMode: true,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setIndex(newIndex);
    },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <SliderWrapper>
      <Slider {...settings}>
        <div>
          <SliderPart>
            <StyledImg src={automation} alt="automation" height="70" />
            {index === 0 ? (
              <StyledTypography>AUTOMATION</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={codeAnalysis} alt="code analysis" height="70" />
            {index === 1 ? (
              <StyledTypography>CODE ANALYSIS</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={ciCd} alt="ci cd" height="70" />
            {index === 2 ? <StyledTypography>CI/CD</StyledTypography> : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={logging} alt="logging" height="70" />
            {index === 3 ? <StyledTypography>LOGGING</StyledTypography> : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={monitoring} alt="monitoring" height="70" />
            {index === 4 ? (
              <StyledTypography>MONITORING</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={automation} alt="automation" height="70" />
            {index === 5 ? (
              <StyledTypography>AUTOMATION</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={codeAnalysis} alt="code analysis" height="70" />
            {index === 6 ? (
              <StyledTypography>CODE ANALYSIS</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={ciCd} alt="ci cd" height="70" />
            {index === 7 ? <StyledTypography>CI/CD</StyledTypography> : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={logging} alt="logging" height="70" />
            {index === 8 ? <StyledTypography>LOGGING</StyledTypography> : null}
          </SliderPart>
        </div>
        <div>
          <SliderPart>
            <StyledImg src={monitoring} alt="monitoring" height="70" />
            {index === 9 ? (
              <StyledTypography>MONITORING</StyledTypography>
            ) : null}
          </SliderPart>
        </div>
      </Slider>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  height: 10rem;
  background: #ffffff;
  box-shadow: inset 100px 0px 100px -50px #f6f9fc,
    inset -100px 0px 100px -50px #f6f9fc, 0px 8px 16px -16px #dcdcdc,
    0px -8px 16px -16px #dcdcdc;
  padding-bottom: 1rem;
`;

const StyledImg = styled.img`
  margin-top: 2rem;
  width: 90%;
`;

const SliderPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > p {
    font-weight: bold;
  }
`;

const StyledTypography = styled.h3`
  text-align: center;
  padding-top: 0.5rem;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
`;

import loggedReducer from "./isLoggedIn";
import themeReducer from "./currentTheme";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  isLoggedIn: loggedReducer,
  currentTheme: themeReducer,
});

export default allReducers;

import { ShepherdOptionsWithType } from "react-shepherd";
import { offset } from "@floating-ui/core";

export const generateCommonStepOptions = (
  id: string | undefined,
  position: any,
  userName?: string
): ShepherdOptionsWithType => {
  position = "top";
  return {
    id,
    scrollTo: {
      behavior: "smooth",
      block: "center",
    },
    attachTo: {
      element: id ? id : "<div></div>",
      on: position,
    },
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
    },
    showOn: () => {
      return !!id;
    },
    beforeShowPromise: () => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    cancelIcon: {
      enabled: true,
    },
  };
};

export const tourOptions = {
  defaultStepOptions: {
    exitOnESC: true,
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

import { fetchData, fetchDataWithoutBasicAuth } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Basic dGVzdDp0ZXN0",
  };
};

const requestHeadersWithoutAuth = () => {
  return {
    "Content-Type": "application/json",
  };
};

const get = () => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks`,
    "GET",
    requestHeaders(),
    null
  );
};

const getById = (taskId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks/` + taskId,
    "GET",
    requestHeaders(),
    null
  );
};

const getByTaskIdAndUserId = (taskId: string, userId: string) => {
  return fetchDataWithoutBasicAuth(
    config.getPlatformEndpoint() + `/editor/` + taskId + `/user/` + userId,
    "GET",
    requestHeadersWithoutAuth(),
    null
  );
};

const getByGroup = (group: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks/group/` + group,
    "GET",
    requestHeaders(),
    null
  );
};

const getByType = (type: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks/type/` + type,
    "GET",
    requestHeaders(),
    null
  );
};

const getByTypeAndUserId = (type: string, userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks/type/` + type + `/user/` + userId,
    "GET",
    requestHeaders(),
    null
  );
};

const getByGroupAndUserId = (group: string, userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/tasks/group/` + group + "/" + userId,
    "GET",
    requestHeaders(),
    null
  );
};

const add = (task: any) => {
  console.log("Adding new task: " + JSON.stringify(task));
  return fetchData(
    config.getBeServerEndpoint() + `/tasks`,
    "POST",
    requestHeaders(),
    JSON.stringify(task)
  );
};

export {
  getByGroupAndUserId,
  add,
  getByGroup,
  getById,
  get,
  getByType,
  getByTypeAndUserId,
  getByTaskIdAndUserId,
};

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface BlackWhiteCardModel {
  icon: any;
  title: string;
  description: string;
}

export const BlackWhiteCard = (props: BlackWhiteCardModel) => {
  const { icon, title, description } = props;
  return (
    <Card>
      <AnimationOnScroll animateOnce animateIn="animate__zoomInDown">
        <Icon>
          <img src={icon} alt="Environment icon" />
        </Icon>
      </AnimationOnScroll>
      <AnimationOnScroll animateOnce animateIn="animate__zoomInUp">
        <Title>{title}</Title>
        <Title>Execution Environment</Title>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__zoomInUp"
        className="overflow-auto"
      >
        <Description>{description}</Description>
      </AnimationOnScroll>
    </Card>
  );
};

const Card = styled.div`
  width: 25rem;
  background: #ffffff;
  margin: 2rem;
  @media (max-width: 500px) {
    width: 16rem;
  }
  padding: 2rem;
  box-shadow: 0 9px 30px rgb(0 0 0 / 0.2);
  cursor: pointer;

  :nth-of-type(1) {
    transform: perspective(540px) rotateY(25deg);
    transition: 0.4s;

    :hover {
      transform: none;
      transition: 0.4s;
    }
  }

  :nth-of-type(2) {
    transform: scale(0.85);
    transition: 0.4s;

    :hover {
      transform: scale(1);
      transition: 0.4s;
    }
  }

  :nth-of-type(3) {
    transform: perspective(540px) rotateY(-25deg);
    transition: 0.4s;

    :hover {
      transform: none;
      transition: 0.4s;
    }
  }
`;

const Icon = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  text-align: center;
  font-weight: bold;

  :nth-of-type(2) {
    margin-bottom: 2rem;
  }
`;

const Description = styled.h5`
  overflow: auto;
  height: 15rem;
  color: #747474;
  padding-bottom: 2rem;
  display: block;
  line-height: 2rem;
`;

import { getUser } from ".";

export const checkIfGuestExists = () => {
  let user = JSON.parse(getUser() as string);
  const isGuestUser =
    user?.name === "Guest" &&
    [
      "507f191e810c19729de860ea",
      "65af7c46e70d9f0037825c74",
      "65af7bb2995c11003d57f4b5",
    ].includes(user?._id);
  return isGuestUser;
};

import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface TableCellModel {
  icon: any;
  title: string;
  subtitle: string;
  learnMore?: boolean;
  redirectLink?: string;
}

export const TableCellItem = (props: TableCellModel) => {
  const { icon, title, subtitle, learnMore, redirectLink } = props;
  const theme = useTheme();
  let navigate = useNavigate();

  const redirection = () => {
    if (redirectLink) {
      navigate(redirectLink);
    }
  };

  return (
    <TableSection>
      <AnimationOnScroll animateOnce animateIn="animate__flipInX">
        <IconContainer>
          <img src={icon} alt="Platform process icon" />
        </IconContainer>
      </AnimationOnScroll>
      <AnimationOnScroll animateOnce animateIn="animate__flipInX">
        <StyledTitle>{title}</StyledTitle>
      </AnimationOnScroll>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
      {learnMore ? (
        <LearnMore variant="caption" theme={theme} onClick={redirection}>
          Learn more
        </LearnMore>
      ) : (
        ""
      )}
    </TableSection>
  );
};

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f6f9fc;
`;

const IconContainer = styled.div`
  height: 100px;
  margin-top: 2rem;

  img {
    height: 100px;
  }
`;

const StyledTitle = styled.h3`
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const StyledSubtitle = styled.h5`
  margin-left: 4rem;
  margin-right: 4rem;
  text-align: center;
  color: #747474;
  line-height: 2rem;
`;

const LearnMore = styled(Typography)`
  font-weight: bold;
  color: ${(props: any) => props.theme.palette.primary.main};
  cursor: pointer;
  margin-bottom: 2rem;
`;

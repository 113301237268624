import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import differentIssues from "../../../../assets/images/platform-process/different-issues.svg";
import testInFrameworks from "../../../../assets/images/platform-process/test-in-frameworks.svg";
import solutions from "../../../../assets/images/platform-process/solutions.svg";
import environments from "../../../../assets/images/platform-process/environments.svg";
import finalTest from "../../../../assets/images/platform-process/final-test.svg";
import certificate from "../../../../assets/images/platform-process/certificate.svg";
import { TableCellItem } from "./TableCellItem";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const PlatformProcessTable = () => {
  const theme = useTheme();

  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__flipInX">
        <Title>What can we offer you?</Title>
      </AnimationOnScroll>

      <StyledTable>
        <TableCellItem
          icon={differentIssues}
          subtitle="You will learn fundamentals and major terminology, making you familiar with Software testing."
          title="Step 1"
          learnMore={true}
          redirectLink="/qaskills-fundamentals"
        />
        <TableCellItem
          icon={testInFrameworks}
          subtitle="You will start writing some code, so everyone knows you have some experience writing code in JavaScript and Python."
          title="Step 2"
          learnMore={true}
          redirectLink="/coding-skills"
        />
        <TableCellItem
          icon={solutions}
          subtitle="You will write some Unit and the Rest API tests, so you can have a better understanding of automation testing."
          title="Step 3"
          learnMore={true}
          redirectLink="/automation-tasks"
        />
        <TableCellItem
          icon={environments}
          subtitle="You will learn SQL so that you can check database content without the support of a developer."
          title="Step 4"
          learnMore={true}
          redirectLink="/database-tasks"
        />
        <TableCellItem
          icon={finalTest}
          subtitle="You will use terminal commands, so everyone is pleasantly surprised with your Linux interaction."
          learnMore={true}
          redirectLink="/linux-interaction-tasks"
          title="Step 5"
        />
        <TableCellItem
          icon={certificate}
          subtitle="You will learn about agile methodologies, so you can easily fit in future teams."
          learnMore={true}
          redirectLink="/development-methodologies"
          title="Step 6"
        />
        <TableCellItem
          icon={differentIssues}
          subtitle="You will introduce the most important tools and concepts for automation testing infrastructure, making you more familiar with real projects on your dream job."
          title="Step 7"
          learnMore={true}
          redirectLink="/automation-infra-fundamentals"
        />
        <TableCellItem
          icon={testInFrameworks}
          subtitle="You will write multiple test cases and bugs, so everyone knows you have already created some of them."
          title="Step 8"
          learnMore={true}
          redirectLink="/test-case-tasks"
        />
        <TableCellItem
          icon={solutions}
          subtitle="You will learn best practices for communication in the team and the process of CV creation."
          title="Step 9"
          learnMore={true}
          redirectLink="/soft-skills-fundamentals"
        />
        <TableCellItem
          icon={environments}
          subtitle="You will attend a real video interview, making you familiar with the interview process and checking whether are you ready for the real interview."
          title="Step 10"
          learnMore={true}
          redirectLink="/roadmap-and-progress"
        />
        <TableCellItem
          icon={finalTest}
          subtitle="You will have more confidence to apply to as many as possible junior positions in Software Testing."
          learnMore={true}
          redirectLink="/roadmap-and-progress"
          title="Step 11"
        />
        <TableCellItem
          icon={certificate}
          subtitle="You will be prepared for the D Day, so explain to them what you have been doing in previous steps."
          learnMore={true}
          redirectLink="/roadmap-and-progress"
          title="Step 12"
        />
      </StyledTable>
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  padding: 4rem 1rem 6rem 1rem;
  font-weight: bold;
`;

const StyledTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #00acea;
  grid-gap: 1px;
  margin-bottom: 5rem;
  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { SaleCompleted } from "./SaleCompleted";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getPlatformEndpoint } from "../../../../config";
import axios from "axios";

interface FlashSaleDto {
  id: string;
  showFlashSale: boolean;
  expireDate: string;
  startSaleText: string;
  endSaleText: string;
}

export const FlashSale = () => {
  const [showFlashSale, setShowFlashSale] = useState<boolean>(false);
  const [expireDate, setExpireDate] = useState<number>(0);
  const [startSaleText, setStartSaleText] = useState<string>("");
  const [endSaleText, setEndSaleText] = useState<string>("");

  const navigate = useNavigate();

  // const callToActionText =
  //   "Don't miss out on our amazing flash sale, happening now for a limited time only!";

  const flashSaleUrl = "/flash-sale";

  useEffect(() => {
    getFlashSale();
  }, []);

  const createTimeDate = (rawDate: string): number => {
    return new Date(rawDate).getTime();
  };

  const getFlashSale = () => {
    axios
      .get<FlashSaleDto>(getPlatformEndpoint() + flashSaleUrl, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response: any) => {
        if (response.data.length) {
          const saleDataObj = response.data[0];
          setShowFlashSale(saleDataObj.showFlashSale);
          const timeDate = createTimeDate(saleDataObj.expireDate);
          setExpireDate(timeDate);
          setStartSaleText(saleDataObj.startSaleText);
          setEndSaleText(saleDataObj.endSaleText);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <>
      {showFlashSale ? (
        expireDate > new Date().getTime() ? (
          <Container>
            <CountdownItems>
              <StyledTypography variant="caption" align="center">
                {startSaleText}
              </StyledTypography>

              <CountdownWrapper>
                <FlipClockCountdown
                  to={expireDate}
                  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                  labelStyle={{
                    fontSize: 10,
                    fontWeight: 500,
                    textTransform: "uppercase",
                    color: "#000000",
                  }}
                  digitBlockStyle={{ width: 30, height: 30, fontSize: 20 }}
                ></FlipClockCountdown>
              </CountdownWrapper>

              <StyledButton
                className="button-glow"
                onClick={() =>
                  navigate("/products-price", { state: { path: "packages" } })
                }
              >
                Buy now
              </StyledButton>
            </CountdownItems>

            <CloseIconWrapper onClick={() => setShowFlashSale(false)}>
              <CloseIcon />
            </CloseIconWrapper>
          </Container>
        ) : (
          <SaleCompleted
            endSaleText={endSaleText}
            setShowFlashSale={setShowFlashSale}
          />
        )
      ) : null}
    </>
  );
};

const Container = styled.div`
  background-image: linear-gradient(to top right, yellow, red);
  padding: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const CountdownItems = styled.div`
  flex: 95%;
  display: flex;
  gap: 0.5rem;
  margin: 1rem;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const CountdownWrapper = styled.span`
  margin-top: 0.5rem;
`;

const StyledTypography = styled(Typography)`
  line-height: 1.2rem;
`;

const StyledButton = styled(Button)`
  color: white;
  border: 2px solid white;
  border-radius: 30px;

  &.MuiButton-root {
    padding: 0.5rem;
    font-size: 18px;
    font-weight: bold;
  }
  margin: auto;
  cursor: pointer;

  @keyframes glowing {
    0% {
      box-shadow: 0 0 -15x white;
    }
    40% {
      box-shadow: 0 0 25px white;
    }
    60% {
      box-shadow: 0 0 25px white;
    }
    100% {
      box-shadow: 0 0 -15px white;
    }
  }

  &.button-glow {
    animation: glowing 3000ms infinite;
  }
`;

const CloseIconWrapper = styled.div`
  max-width: 40px;
  position: relative;
  top: 10px;
  right: 10px;
  display: block;
  margin-bottom: 2rem;
  clear: both;
  cursor: pointer;

  & svg {
    font-size: 25px;
  }
`;

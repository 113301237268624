import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref } from "react";
import { TransactionsDto } from "./RoadmapAndProgress";
import cards from "../../assets/images/credit-cards-black.svg";
import { PackageType } from "./RoadmapAndProgress";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TransactionsDialogProps {
  isOpen: boolean;
  setOpenPopup: (data: boolean) => void;
  title: string;
  subtitle?: string;
  transactions?: TransactionsDto[];
}

export const formatPackageType = (type: string) => {
  switch (type) {
    case PackageType.monthlyPackage:
      return "Monthly package";
    case PackageType.yearlyPackage:
      return "Yearly package";
    case PackageType.yearlyPackageAndFinal:
      return "Yearly package and Final test";
  }
};

export const TransactionsDialog = (props: TransactionsDialogProps) => {
  const { isOpen, setOpenPopup, title, subtitle, transactions } = props;

  const handleClose = () => {
    setOpenPopup(false);
  };

  const isSmallerDevice = useMediaQuery("(max-width:1200px)");

  return (
    <StyledDialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContentText id="alert-dialog-slide-description">
        <Subtitle variant="caption">{subtitle}</Subtitle>
      </StyledDialogContentText>
      <DialogContent>
        {!transactions?.length ? (
          <TransactionsContainter>
            There are no transactions so far.
          </TransactionsContainter>
        ) : (
          <TransactionsContainter>
            {transactions?.map((transaction) => (
              <TransactionList key={transaction?.transactionId}>
                <Container>
                  <div className="d-flex">
                    <img src={cards} alt="Credit card icon" />
                    <Typography>QASkills </Typography>
                  </div>
                  <Typography>
                    type: {formatPackageType(transaction?.packageType)}
                  </Typography>
                  <Typography>price: {transaction?.amount}</Typography>
                  <Typography>date: {transaction?.transactionDate}</Typography>
                  <Typography>status: {transaction?.status}</Typography>
                </Container>
              </TransactionList>
            ))}
          </TransactionsContainter>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 10px;
    width: 70%;
    padding: 20px;
    background: #ffffff;
    padding: 0 !important;
    min-width: 70% !important;
  }

  & .MuiDialogContent-root {
    padding: 0 !important;
  }

  @media (max-width: 767px) {
    & .MuiPaper-root {
      width: 100%;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-weight: bold;
  text-align: center;
  color: #000000;
`;

const StyledDialogContentText = styled(DialogContentText)`
  color: #000000;
`;

const TransactionsContainter = styled.div`
  background: lightgray;
  padding: 2rem;
  overflow: auto;
`;

const Subtitle = styled(Typography)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 0 1rem;
`;

const TransactionList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  img {
    margin-right: 0.5rem;
    height: 32px;
    width: 38px;
  }
  :not(:last-child) {
    border-bottom: 1px solid;
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  span {
    white-space: nowrap;
  }
  flex-wrap: wrap;
`;

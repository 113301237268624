import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

// import Snowfall from "react-snowfall";
import { QaSkillsSection } from "./QaSkillsSection";
import { WhatIsQASkills } from "./what-is-qaskills/WhatIsQASkills";
import { PlatformProcessTable } from "./platform-process-table/PlatformProcessTable";
import { TestimonialsCarousel } from "./testimonials/TestimonialsCarousel";
import { CompanyCarousel } from "./testimonials/CompanyCarousel";
import { TestingLevels } from "./testing-levels/TestingLevels";
import { Categories } from "./categories/Categories";
import { FrameworksAndLanguages } from "./frameworks-and-languages/FrameworksAndLanguages";
import { Environments } from "./environments/Environments";
import { VerticalLine } from "../../shared/components/VerticalLine";
import { EndPageNavigation } from "../../shared/components/end-page-navigation/EndPageNavigation";

export const LandingPage = () => {
  const theme = useTheme();

  return (
    <LandingContainer theme={theme} id="top">
      {/* <Snowfall /> */}
      <QaSkillsSection />
      <VerticalLine />
      <WhatIsQASkills />
      <PlatformProcessTable />
      <VerticalLine />
      <TestimonialsCarousel />
      <CompanyCarousel />
      <VerticalLine />
      <TestingLevels />
      <VerticalLine />
      <Categories />
      <VerticalLine />
      <FrameworksAndLanguages />
      <VerticalLine />
      <Environments />
      <EndPageNavigation btnText="Buy QASkills" />
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  background: #f6f9fc;
  position: relative;
  padding-left: 10rem;
  padding-right: 10rem;
  @media (max-width: 1200px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (max-width: 1024px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

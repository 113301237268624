import { Popover, Typography, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "../../../../utils";
import { Mixpanel } from "../../../../../../Mixpanel";

interface ContentMenuModel {
  anchorElProp: any;
  isOpen: boolean;
  handleClose: any;
}

export const ContentMenu = (props: ContentMenuModel) => {
  const { anchorElProp, isOpen, handleClose } = props;
  const [user, setUser] = useState({
    name: "",
  });
  const currentUser = getUser();

  useEffect(() => {
    if (currentUser) {
      let userDetails = JSON.parse(currentUser);
      setUser({
        ...userDetails,
        username: userDetails.name,
      });
    }
  }, [currentUser]);

  const theme = useTheme();

  const id = isOpen ? "simple-popover" : undefined;

  return (
    <StyledPopover
      id={id}
      open={isOpen}
      anchorEl={anchorElProp}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <StyledPopoverContainer {...{ theme }} onMouseLeave={handleClose}>
        <StyledOverview>
          Hey {user?.name.split(" ")[0]}, look at your{" "}
          <StandardLink
            to="/roadmap-and-progress"
            {...{ theme }}
            onClick={() => Mixpanel.track("Header navigation roadmap.")}
          >
            roadmap and progress
          </StandardLink>
        </StyledOverview>
        <StyledLinks>
          <StyledContentWrapperColumn>
            <MenuTitle variant="body1">QASkills</MenuTitle>
            <PopoverLink
              to="/qaskills-fundamentals"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track(
                  "Header navigation qaskills-fundamentals and theory."
                )
              }
            >
              <StyledHeading2>Fundamentals and theory</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/test-case-tasks"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation test-case-tasks.")
              }
            >
              <StyledHeading2>Test Case and Bug Tasks</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/development-methodologies"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation development-methodologies.")
              }
            >
              <StyledHeading2>Development methodologies</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/coding-skills"
              {...{ theme }}
              onClick={() => Mixpanel.track("Header navigation coding-skills.")}
            >
              <StyledHeading2>Coding Skills</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/automation-tasks"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation automation-tasks.")
              }
            >
              <StyledHeading2>Automation Tasks</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/database-tasks"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation database-tasks.")
              }
            >
              <StyledHeading2>Database Tasks</StyledHeading2>
            </PopoverLink>

            <PopoverLink
              to="/linux-interaction-tasks"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation linux-interaction-tasks.")
              }
            >
              <StyledHeading2>Linux Interactions Task</StyledHeading2>
            </PopoverLink>
          </StyledContentWrapperColumn>

          <StyledContentWrapperColumn>
            <MenuTitle variant="body1">AutomationInfra</MenuTitle>
            <PopoverLink
              to="/automation-infra-fundamentals"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track(
                  "Header navigation automationInfra-fundamentals-and-theory."
                )
              }
            >
              <StyledHeading2>Fundamentals and theory</StyledHeading2>
            </PopoverLink>
            <PopoverLink
              to="/automation-infra-tasks"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation automationInfra-tasks.")
              }
            >
              <StyledHeading2>Tasks</StyledHeading2>
            </PopoverLink>
            <MenuTitle variant="body1">Other</MenuTitle>
            <PopoverLink
              to="/soft-skills-fundamentals"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation soft-skills-fundamentals.")
              }
            >
              <StyledHeading2>Soft Skills Fundamentals</StyledHeading2>
            </PopoverLink>
            <PopoverLink
              to="/how-to-write-cv"
              {...{ theme }}
              onClick={() =>
                Mixpanel.track("Header navigation how-to-write-cv.")
              }
            >
              <StyledHeading2>How to write a CV</StyledHeading2>
            </PopoverLink>
            {/* <PopoverLink to="/qaskills-quiz" {...{ theme }}>
                QASkills Quiz
              </PopoverLink> */}
          </StyledContentWrapperColumn>
        </StyledLinks>
      </StyledPopoverContainer>
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0 9px 30px gray;
  }
`;

const StyledPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  background: #ffffff;
  width: 55rem;
  justify-content: space-between;
  align-content: flex-start;
`;

const StyledLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  background: #f6f9fc;
  padding: 2rem;
`;

const StyledOverview = styled.div`
  text-align: center;
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  width: fit-content;
  margin: auto;
  font-size: 18px;
  color: #000000;
`;

const StandardLink = styled(NavLink)`
  text-decoration: underline;
  color: blue;
`;

const StyledContentWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  :nth-of-type(1) {
    width: 50%;
    padding-right: 3rem;
    border-right: 1px solid #747474;
  }

  :nth-of-type(2) {
    padding-right: 1rem;
    width: 45%;
  }
`;

const MenuTitle = styled(Typography)`
  font-weight: bold;
  padding-bottom: 1rem;
  color: #000000;
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.2rem;

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid ${(props: any) => props.theme.palette.primary.main};
  }

  &:after {
    transition: all ease-in-out 0.2s;
    background: ${(props: any) => props.theme.palette.primary.main};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const PopoverLink = styled(StyledNavLink)`
  margin-bottom: 1rem;
  font-size: 18px;
  margin-left: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledHeading2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0;
`;

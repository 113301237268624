import { fetchData } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Basic dGVzdDp0ZXN0",
  };
};

const getTask = (lang: any) => {
  return fetchData(
    config.getAppClientBeEndpoint() + `/api/file/${lang}`,
    "GET",
    requestHeaders(),
    null
  );
};

const run = (answer: any) => {
  return fetchData(
    config.getAppClientBeEndpoint() + `/api/run/`,
    "POST",
    requestHeaders(),
    JSON.stringify(answer)
  );
};

export { getTask, run };

import styled from "@emotion/styled";

export const VerticalLine = () => {
  return <VerticalLineStyle />;
};

const VerticalLineStyle = styled.div`
  border-right: 1.5px solid #747474;
  height: 150px;
  position: relative;
  margin: 1rem auto !important;
  @media (max-width: 820px) {
    margin: 3rem auto !important;
  }
  width: 1.5px;
`;

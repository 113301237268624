import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import axios from "axios";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Button } from "react-bootstrap";
import { getPlatformEndpoint } from "../../config";
import toast from "react-hot-toast";

interface FinalTestAndInterviewCardModel {
  disabled: boolean;
  userId: string;
  setIsLoading: (isLoading: boolean) => void;
}

export const FinalTestAndInterviewCard = (
  props: FinalTestAndInterviewCardModel
) => {
  const { disabled, userId, setIsLoading } = props;

  const applyForTheFinalTest = () => {
    setIsLoading(true);
    const route = getPlatformEndpoint() + `/final-test-apply`;
    const finalTestPromise = axios
      .post(
        route,
        {
          userId,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.response?.data?.message) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error);
        }
      });

    const message =
      "Thank you for applying for Final Test and video Interview call. Congratulations for completing all tasks.";
    toast.promise(finalTestPromise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  return (
    <Container disabled={disabled}>
      <CardDetails>
        <AnimationOnScroll
          animateOnce
          animateIn="animate__backInUp"
          offset={100}
        >
          <h3>
            <b>Final Test and Interview Video Call</b>
          </h3>
          <Description>
            <h5>
              Apply for Final Test and real video call interview to verify you
              knowledge and have better understanding of whole interview
              process.
            </h5>
          </Description>
          <FinalTestButton onClick={applyForTheFinalTest}>
            Apply for Final Test and Interview
          </FinalTestButton>
        </AnimationOnScroll>
      </CardDetails>
    </Container>
  );
};

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  pointer-events: ${(props: any) => (props.disabled ? "none" : "")};
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  height: auto;
  position: relative;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  height: auto;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #9c9c9c;
  > span {
    line-height: normal;
  }

  h5 {
    line-height: 2rem;
  }
`;

const FinalTestButton = styled(Button)`
  border: 4px solid #6c2dc7;
  background: #6c2dc7;
  margin: auto;
  display: block;
  padding: 1rem;
  font-size: 1.3rem;
  :hover {
    box-shadow: 0 0 25px #ababab;
    background: #6c2dc7;
    border: 4px solid #6c2dc7;
    transform: scale(1.02);
  }
`;

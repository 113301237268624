import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoginButton } from "../shared/components/buttons/LoginButton";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { LogoIcon } from "../shared/images/Logo";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface LoginPopupModel {
  isShowLogin: boolean;
  setIsShowLogin?: (data: boolean) => void;
  loginMessage?: string;
}

export const LoginPopup = (props: LoginPopupModel) => {
  let { isShowLogin, setIsShowLogin, loginMessage } = props;

  const handleClose = () => {
    setIsShowLogin?.(false);
  };

  return isShowLogin ? (
    <StyledDialog
      open={isShowLogin}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle variant="h6">Sign in to</DialogTitle>
      <DialogContent>
        <StyledDialogContentText
          className="text-center"
          id="alert-dialog-slide-description"
        >
          <span className="d-flex justify-content-center">
            <LogoIcon />
          </span>
          <br />
          <b>{loginMessage && <LoginMessage>{loginMessage}</LoginMessage>}</b>
          Sign in with your Google Account.
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledCustomButton>
          <LoginButton shouldClosePopup={handleClose} />
        </StyledCustomButton>
      </DialogActions>
    </StyledDialog>
  ) : (
    <div></div>
  );
};

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 2rem;
    border-radius: 10px;
  }

  & .MuiDialogContent-root {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const DialogTitle = styled(Typography)`
  text-align: center;
  font-weight: bold;
`;

const StyledCustomButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledDialogContentText = styled(DialogContentText)`
  font-size: 1.3rem;
`;

const LoginMessage = styled.div`
  padding-bottom: 1rem;
`;

import { getThemeByName } from "../theme/theme";

const themeReducer = (state = "lightTheme", action: { type: string }) => {
  switch (action.type) {
    case "LIGHT_THEME":
      getThemeByName("lightTheme");
      return "lightTheme";
    case "DARK_THEME":
      getThemeByName("darkTheme");
      return "darkTheme";
    default:
      getThemeByName(state);
      return state;
  }
};

export default themeReducer;

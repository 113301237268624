import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/cjs/styles/prism";

export default function MarkdownWithHighlight({ text }) {
  return (
    <StyledReactMarkdown
      children={text}
      components={{
        code({ node, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, "")}
              language={match[1]}
              style={materialDark}
              {...props}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
}

const StyledReactMarkdown = styled(ReactMarkdown)`
  font-size: 17px;

  code {
    font-weight: bold;
  }
`;

import { Typography, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import { CategoryCard } from "./CategoryCard";
import automation from "../../../../assets/images/categories/automation.svg";
import coding from "../../../../assets/images/categories/coding.svg";
import database from "../../../../assets/images/categories/database.svg";
import linux from "../../../../assets/images/categories/linux.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

export const Categories = () => {
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery("(max-width:1023px)");

  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__flipInX">
        <Title>
          100+ <br />
          Issues spanning 4 categories
        </Title>
      </AnimationOnScroll>

      {isSmallerDevice ? (
        <GridCards>
          <CategoryCard
            title="automation"
            text="Many types of tests exist to help an organization assess and improve the quality of its product. By automating your testing process, your organization will save time and money and deliver higher quality products. Testers are responsible for writing automation tests, and maybe your dream job is waiting for you."
            icon={automation}
            count={20}
          />
          <CategoryCard
            title="coding"
            text="Coding is arguably one of the essential skills for current and future generations to learn. For young learners, programming helps to gain skills to solve a problem logically and creatively. Most importantly, quality assurance coding is the future and mandatory for writing automation tests. Therefore, a beneficial skill in a tester career to possess. "
            icon={coding}
            count={20}
          />
          <CategoryCard
            title="database"
            text="Having SQL experience is an essential skill for verifying the test data and values in the database; therefore, a QA engineer should have basic SQL knowledge and understand the relationship between database tables."
            icon={database}
            count={20}
          />
          <CategoryCard
            title="linux"
            text="A vital skill in the tester profile is the knowledge of Linux commands. It is essential since, being an open-source OS, Linux offers more possibilities for both testers and developers to immerse deep into the development environment, execute scripts, and read application logs. A prevailing number of applications are deployed on Linux. Therefore, to demonstrate an efficient testing task execution, you should know basic Linux commands and operations."
            icon={linux}
            count={20}
          />
        </GridCards>
      ) : (
        <Cards>
          <CategoryCard
            title="automation"
            text="Many types of tests exist to help an organization assess and improve the quality of its product. By automating your testing process, your organization will save time and money and deliver higher quality products. Testers are responsible for writing automation tests, and maybe your dream job is waiting for you."
            icon={automation}
            count={20}
          />
          <CategoryCard
            title="coding"
            text="Coding is arguably one of the essential skills for current and future generations to learn. For young learners, programming helps to gain skills to solve a problem logically and creatively. Most importantly, quality assurance coding is the future and mandatory for writing automation tests. Therefore, a beneficial skill in a tester career to possess. "
            icon={coding}
            count={20}
          />
          <CategoryCard
            title="database"
            text="Having SQL experience is an essential skill for verifying the test data and values in the database; therefore, a QA engineer should have basic SQL knowledge and understand the relationship between database tables."
            icon={database}
            count={20}
          />
          <CategoryCard
            title="linux"
            text="A vital skill in the tester profile is the knowledge of Linux commands. It is essential since, being an open-source OS, Linux offers more possibilities for both testers and developers to immerse deep into the development environment, execute scripts, and read application logs. A prevailing number of applications are deployed on Linux. Therefore, to demonstrate an efficient testing task execution, you should know basic Linux commands and operations."
            icon={linux}
            count={20}
          />
        </Cards>
      )}
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  padding: 2rem 1rem 6rem 1rem;
  font-weight: bold;
`;

const Cards = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
`;

const GridCards = styled.div`
  @media (max-width: 1023px) {
    justify-content: center;
    align-items: center;
    display: flex !important;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5rem;
    flex-flow: row wrap;
  }
`;

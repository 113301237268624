import messageIcon from "../../../assets/images/message_icon.svg";
import { getBeServerEndpoint } from "../../../config";
import LoadingPage from "../../shared/components/LoadingPage";
import { OutlinedButton } from "../../shared/components/buttons/OutlinedButton";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { getUser } from "../../utils";

const options = [
  {
    value: "Login issues",
    label: "Login issues",
  },
  {
    value: "Workspace issue",
    label: "Workspace issue",
  },
  {
    value: "Content related issue",
    label: "Content related issue",
  },
  {
    value: "Purchasing issue",
    label: "Purchasing issue",
  },
  {
    value: "Feature request",
    label: "Feature request",
  },
  {
    value: "Report a bug",
    label: "Report a bug",
  },
  {
    value: "Business Inquiries Topics",
    label: "Business Inquiries Topics",
  },
  {
    value: "Other",
    label: "Other",
  },
];

interface ContactUsFormModel {
  buttonText: string;
  url: string;
}

type FormValues = {
  subject: [];
  message: string;
};

export const ContactUsForm = (props: ContactUsFormModel) => {
  const [isLoading, setIsLoading] = useState(false);

  const userEmail = JSON.parse(getUser() as string).googleId;

  const { buttonText, url } = props;

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      subject: [],
      message: "",
    },
  });

  const handleFormSubmit = (form: FormValues) => {
    setIsLoading(true);
    const promise = axios
      .post(
        getBeServerEndpoint() + url,
        {
          email: userEmail,
          subject: form.subject,
          message: form.message,
        },
        {
          headers: {
            Authorization: "Basic dGVzdDp0ZXN0",
            "content-type": "application/json",
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        reset();
        console.log(response.data);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message =
      "Message has been sent. Thank you for your assistance in making our platform better. BR, QASkills Team";
    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
        <FieldTitle variant="caption">Subject *</FieldTitle>
        <Controller
          name="subject"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              options={options}
              name="subject"
              placeholder="Select one or more subjects"
              isMulti
            />
          )}
        />
        {errors.subject && <ErrorMessage>Subject is required.</ErrorMessage>}

        <FieldTitle variant="caption">Message *</FieldTitle>
        <StyledField
          {...register("message", { required: true })}
          multiline
          rows={3}
        />
        {errors.message && <ErrorMessage>Message is required.</ErrorMessage>}

        <ButtonWrapper>
          <OutlinedButton
            text={buttonText}
            variant="outlined"
            disabled={false}
            icon={messageIcon}
            type="submit"
            redirectLink={undefined}
            color="#000000"
          />
        </ButtonWrapper>
      </StyledForm>
    </>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const FieldTitle = styled(Typography)`
  width: 70%;
  padding-top: 1rem;
  @media (max-width: 820px) {
    width: 85%;
  }
`;

const StyledField = styled(OutlinedInput)`
  width: 70%;
  color: gray;
  @media (max-width: 820px) {
    width: 85%;
  }
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-root {
    background: #ffffff;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 25px #dcdcdc;
  }

  & .MuiOutlinedInput-input {
    font-size: 1rem;
  }
`;

const StyledSelect = styled(Select)`
  width: 70%;
  @media (max-width: 820px) {
    width: 85%;
  }
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  @media (max-width: 820px) {
    width: 85%;
  }
  color: ${(props: any) => props.theme.palette.error.main};
  font-size: 16px;
  margin-bottom: 0;
`;

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import linkedinIcon from "../../../assets/images/social-media/linkedin.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface TeamCardModel {
  icon: any;
  name: string;
  description: string;
  cardPosition: string;
  position: string;
  linkedinLink: string;
}

export const TeamCard = (props: TeamCardModel) => {
  const { icon, name, description, position, cardPosition, linkedinLink } =
    props;

  return (
    <>
      {cardPosition === "left" ? (
        <AnimationOnScroll animateOnce animateIn="animate__backInLeft">
          <Container>
            <Icon>
              <img src={icon} alt="Testing icon" />
            </Icon>
            <Card>
              <LeftWrapper>
                <Title>{name}</Title>
                <Position>{position}</Position>
                <Description>{description}</Description>
              </LeftWrapper>

              <SocialNetworkIcons>
                <img
                  src={linkedinIcon}
                  alt="Linkedin icon"
                  onClick={() => window.open(linkedinLink, "_blank")}
                />
              </SocialNetworkIcons>
            </Card>
          </Container>
        </AnimationOnScroll>
      ) : (
        <AnimationOnScroll animateOnce animateIn="animate__backInRight">
          <RightContainer>
            <RightCard>
              <SocialNetworkIconsRight>
                <img
                  src={linkedinIcon}
                  alt="Linkedin icon"
                  onClick={() => window.open(linkedinLink, "_blank")}
                />
              </SocialNetworkIconsRight>

              <RightWrapper>
                <Title>{name}</Title>
                <Position>{position}</Position>
                <Description>{description}</Description>
              </RightWrapper>
            </RightCard>
            <IconRight>
              <img src={icon} alt="Person" />
            </IconRight>
          </RightContainer>
        </AnimationOnScroll>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 6rem;
  padding-bottom: 6rem;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 770px) {
    flex-flow: column;
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 770px) {
    flex-flow: column-reverse;
  }
`;

const Card = styled.div`
  background: #ffffff;
  width: 600px;
  height: 300px;
  padding: 2rem;
  border-radius: 15px;
  background: linear-gradient(to left, white 70%, #f6f9fc 100%);
  display: flex;

  @media (max-width: 770px) {
    width: auto;
    padding: 1rem 0.5rem;
  }
`;

const RightCard = styled.div`
  background: #ffffff;
  width: 600px;
  height: 300px;
  padding: 2rem;
  border-radius: 15px;
  background: linear-gradient(to right, white 70%, #f6f9fc 100%);
  display: flex;

  @media (max-width: 770px) {
    width: auto;
    padding: 1rem 0.5rem;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  line-height: 1.2;
`;

const Position = styled.h4`
  color: #747474;
  font-weight: normal !important;
  line-height: 1.2;

  @media (max-width: 770px) {
    font-size: 1.4rem;
  }
`;

const Description = styled.h5`
  color: #747474;
  line-height: 1.3;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 170px;
  overflow: auto;
`;

const Icon = styled.div`
  img {
    height: auto;
    width: 100%;
  }
`;

const IconRight = styled.div`
  img {
    height: auto;
    width: 100%;
  }
`;

const SocialNetworkIcons = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #747474;
  cursor: pointer;

  img {
    margin-left: 1rem;
  }
`;

const SocialNetworkIconsRight = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #747474;
  cursor: pointer;

  img {
    margin-right: 1rem;
  }
`;

const RightWrapper = styled.div`
  margin-left: 1rem;
`;

const LeftWrapper = styled.div`
  margin-right: 1rem;
`;

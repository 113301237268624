import styled from "@emotion/styled";

interface LevelDifficultyModel {
  level: string;
}

export const LevelDifficulty = (props: LevelDifficultyModel) => {
  const { level } = props;
  return <Pill level={level}>{level}</Pill>;
};

const Pill = styled.div<{ level: string }>`
  background: ${(props: any) =>
    props.level === "Easy"
      ? props.theme.palette.secondary.main
      : props.level === "Medium"
      ? props.theme.palette.primary.main
      : props.theme.palette.error.main};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  width: 90px;
  height: 30px;
  padding: 0.5rem;
`;

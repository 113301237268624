import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useEffect, useState } from "react";
import rightArrowIcon from "../../../../../assets/images/right-arrow.svg";

export const ContentMenuMobile = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  let navigate = useNavigate();
  const location = useLocation();
  const routes = [
    "/development-methodologies",
    "/qaskills-fundamentals",
    "/automation-tasks",
    "/coding-skills",
    "/database-tasks",
    "/linux-interaction-tasks",
    "/test-case-tasks",
    "/automation-infra-fundamentals",
    "/automation-infra-tasks",
    "/soft-skills-fundamentals",
    "/how-to-write-cv",
  ];

  useEffect(() => {
    if (routes.includes(location.pathname)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location]);

  return (
    <div>
      <Menu
        menuButton={
          <StyledMenuButton className={isActive ? "active" : ""}>
            <span className="d-flex justify-content-between align-items-center">
              Content <img src={rightArrowIcon} alt="menu arrow" height="17" />
            </span>
          </StyledMenuButton>
        }
      >
        <SubMenu label="QASkills">
          <MenuItem onClick={() => navigate("development-methodologies")}>
            <StyledHeading2>Development Methodologies</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("qaskills-fundamentals")}>
            <StyledHeading2>QASkills Fundamentals</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("automation-tasks")}>
            <StyledHeading2>Automation Tasks</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("coding-skills")}>
            <StyledHeading2>Coding Skills</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("database-tasks")}>
            <StyledHeading2>Database Tasks</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("linux-interaction-tasks")}>
            <StyledHeading2>Linux Interaction Tasks</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("test-case-tasks")}>
            <StyledHeading2>Test Case and Bug Tasks</StyledHeading2>
          </MenuItem>
        </SubMenu>
        <SubMenu label="AutomationInfra">
          <MenuItem onClick={() => navigate("automation-infra-fundamentals")}>
            <StyledHeading2>Automation Infra Fundamentals</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("automation-infra-tasks")}>
            <StyledHeading2>Automation Infra Tasks</StyledHeading2>
          </MenuItem>
        </SubMenu>
        <SubMenu label="Other">
          <MenuItem onClick={() => navigate("soft-skills-fundamentals")}>
            <StyledHeading2>Soft Skills Fundamentals</StyledHeading2>
          </MenuItem>
          <MenuItem onClick={() => navigate("how-to-write-cv")}>
            <StyledHeading2>How to write a CV</StyledHeading2>
          </MenuItem>
        </SubMenu>
      </Menu>
    </div>
  );
};

const StyledMenuButton = styled(MenuButton)`
  margin-bottom: 1rem;
  border: none;
  background: #ffffff;
  padding: 0;
  font-size: 1.2rem !important;
  width: 100%;
  text-align: left;
  color: #747474;

  &.active {
    color: #000000 !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
    border: none;
    color: #000000;
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid ${(props: any) => props.theme.palette.primary.main};
  }

  &:after {
    transition: all ease-in-out 0.2s;
    background: ${(props: any) => props.theme.palette.primary.main};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const StyledHeading2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 0;
`;

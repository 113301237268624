import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import LoadingPage from "../LoadingPage";

interface ProgressBarModel {
  title: string;
  percentage: number;
  total: number;
  submittedTasks: number;
}

export const ProgressBar = (props: ProgressBarModel) => {
  const { title, percentage, total, submittedTasks } = props;
  const theme = useTheme();

  if (!submittedTasks && !total && !percentage) {
    return <LoadingPage />;
  }

  return (
    <Container id="progress-step">
      <DescriptionContainer>
        <Title variant="body2">{title}:</Title>
        <ProgressPercentage variant="body2">
          {submittedTasks}/{total}
        </ProgressPercentage>
      </DescriptionContainer>
      <Progress>
        <FillInColor theme={theme} percentage={percentage} title={title}>
          <PercentageDone variant="body2">{percentage}%</PercentageDone>
        </FillInColor>
      </Progress>
    </Container>
  );
};

const Container = styled.div`
  width: 60%;
  margin: auto;

  @media (max-width: 1900px) {
    width: 85%;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 2rem;
`;
const Title = styled(Typography)``;
const ProgressPercentage = styled(Typography)``;
const Progress = styled.div`
  height: 25px;
  background: #ffffff;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 25px #dcdcdc;
`;

const PercentageDone = styled(Typography)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  line-height: normal;
`;

const FillInColor = styled.div<{ percentage: number; title: string }>`
  border-radius: inherit;
  background: ${(props: any) =>
    props.title === "Theoretical tasks"
      ? props.theme.palette.primary.main
      : props.theme.palette.secondary.main};
  width: ${(props) => props.percentage}%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

import { useEffect, useState } from "react";
import { PackagesModel } from "./PurchasePage";
import styled from "@emotion/styled";
import { PackageType } from "../../roadmap-and-progress/RoadmapAndProgress";
import pigBankImage from "../../../assets/images/purchase/pig-bank.svg";
import { formatPackageType } from "../../roadmap-and-progress/TransactionsDialog";

interface CalculatedSavingsModel {
  packages: PackagesModel[];
  selectedPackage: string;
  promoCode: string;
}

export const CalculatedSavings = (props: CalculatedSavingsModel) => {
  const [currentPackage, setCurrentPackage] = useState<
    PackagesModel | undefined
  >();
  const { packages, selectedPackage, promoCode } = props;

  useEffect(() => {
    setCurrentPackage(getSelectedPackageInfo());
  }, [selectedPackage, packages]);

  const getSelectedPackageInfo = (): PackagesModel | undefined => {
    return packages.find(
      (currentPackage) => currentPackage.type === selectedPackage
    );
  };

  return (
    <Container>
      <StyledSummary className="text-center" customcolor={selectedPackage}>
        Summary
      </StyledSummary>

      <div className="d-flex justify-content-between">
        <h6>Package name:</h6>
        <p>{formatPackageType(selectedPackage)}</p>
      </div>
      <div className="d-flex justify-content-between">
        <h6>Total package costs:</h6>
        <p>{currentPackage?.amount}&euro;</p>
      </div>
      <div className="d-flex justify-content-between">
        <p>
          <b>Saved:</b>
        </p>
        <h5>
          <b>-{currentPackage?.saving}&euro;</b>
        </h5>
      </div>
      {promoCode === "" ? (
        <div className="d-flex align-items-center justify-content-between">
          <span>(Global discount) &nbsp;</span>{" "}
          <img src={pigBankImage} alt="Window icon" width="27" />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-between">
          <span>(Global and promo code discount) &nbsp;</span>{" "}
          <div>
            <img src={pigBankImage} alt="Window icon" width="27" />
            <img src={pigBankImage} alt="Window icon" width="27" />
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: block;
  margin-top: 3rem;
  width: 50%;
  margin: 2.5rem auto 0 auto;
  @media (max-width: 800px) {
    width: 80%;
  }
`;

const StyledSummary = styled.h5<{ customcolor: string }>`
  font-weight: bold;
  color: ${(props: any) =>
    props.customcolor === PackageType.yearlyPackageAndFinal
      ? "#6C2DC7"
      : props?.customcolor === PackageType.monthlyPackage
      ? props.theme.palette.secondary.main
      : props?.customcolor === PackageType.yearlyPackage
      ? props.theme.palette.primary.main
      : "transparent"};
`;

import underConstruction from "../../../../assets/images/custom-error-page/under-construction.svg";
import { CustomErrorPage } from "../custom-error-page/CustomErrorPage";

export const Maintenance = () => {
  return (
    <CustomErrorPage
      title="Under Construction..."
      description="Hello there! This website is under construction."
      image={underConstruction}
      buttonText="Back home"
      redirectLink="/qaskills"
      trnStatus={""}
    />
  );
};

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoginState, setLogoutState } from "../../../../actions";
import { getBeServerEndpoint, getPlatformEndpoint } from "../../../../config";
import { setNewUser } from "../../../utils";
import { useState } from "react";
import { Mixpanel } from "../../../../../Mixpanel";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

interface LoginButtonModel {
  shouldClosePopup: () => void;
}

export const LoginButton = (props: LoginButtonModel) => {
  const [googleLogin, setGoogleLogin] = useState({
    username: "",
    name: "",
    googleId: "",
    error: "",
  });

  const { shouldClosePopup } = props;
  let navigate = useNavigate();

  const onFailure = () => {
    dispatch(setLogoutState());
    setGoogleLogin({ ...googleLogin, error: "Login error." });
  };

  const onSuccess = (response: CredentialResponse) => {
    const jwtToken = response?.credential;
    if (jwtToken) {
      let decodedToken = jwt_decode<any>(jwtToken);

      const googleImageUrlLogin = decodedToken.picture;

      axios
        .post(
          getBeServerEndpoint() + "/users/auth/login",
          {
            username: decodedToken?.email,
            name: decodedToken?.name,
            googleId: decodedToken?.email,
          },
          {
            headers: {
              Authorization: "Basic dGVzdDp0ZXN0",
              "content-type": "application/json",
            },
          }
        )
        .then((response: { data: any }) => {
          if (response && response.data) {
            dispatch(setLoginState());
            let userLoginRes = response.data;
            userLoginRes.imageUrl = googleImageUrlLogin;
            console.log("Login response: " + userLoginRes);
            setNewUser(userLoginRes);
            shouldClosePopup();
            getUserData(userLoginRes);
            Mixpanel.alias(userLoginRes.username);
            Mixpanel.people.set({
              $username: userLoginRes.username,
            });
            Mixpanel.track("Successful login.");
            navigate("/roadmap-and-progress");
          }
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(setLogoutState());
          setGoogleLogin({ ...googleLogin, error });
          Mixpanel.track("Unsuccessful login.");
        });
    }
  };

  const getUserData = (userObj: any) => {
    axios
      .get(getPlatformEndpoint() + "/user-by-email/" + userObj.username, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response: { data: any }) => {
        if (response && response.data) {
          dispatch(setLoginState());
          let userLoginRes = response.data;
          userLoginRes.imageUrl = userObj.imageUrl;
          userLoginRes._id = userLoginRes.id;
          console.log("getUserData response: " + userLoginRes);
          setNewUser(userLoginRes);
          shouldClosePopup();
          Mixpanel.alias(userLoginRes.username);
          Mixpanel.people.set({
            $username: userLoginRes.username,
          });
          Mixpanel.track("Successful login.");
          navigate("/roadmap-and-progress");
        }
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(setLogoutState());
        setGoogleLogin({ ...googleLogin, error });
        Mixpanel.track("Unsuccessful login.");
      });
  };

  const dispatch = useDispatch();

  return (
    <>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={() => {
          onFailure();
        }}
      />
    </>
  );
};

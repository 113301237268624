import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Testimonial } from "./Testimonial";
import { useTheme } from "@mui/material";
import styled from "@emotion/styled";
import quantox from "../../../../assets/images/companies/Quantox-Technology-logo.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

const responsive = {
  480: { items: 1 },
  1000: { items: 2 },
  1500: { items: 3 },
};

const items = [
  <Testimonial
    name="Stefan"
    testimonial="QASkills appeared at a time when I was a bit tired of reading and watching different QA-related courses, which all looked similar, and none of them gave me a true vision of what it takes to learn, so I can dare to apply for the real QA position. I needed a different approach, and the QASkills concept seemed like the perfect match for me as I was searching for a single place where I could learn and practically check and track what I've learned right away. Going through the flow of QASkills lessons and tasks, I was getting the knowledge much easier, and I got from zero to ready at no time. I passed the interview and started working as a junior QA. I was surprised at how essential the lessons from the QASkills were for actual tasks at work. I'm writing this to say thank you, guys, and of course, I would highly recommend QASkills to all the future and current QA's."
    companyLogo={quantox}
    position="Junior QA Engineer"
    count={1}
  />,
];

export const TestimonialsCarousel = () => {
  const theme = useTheme();
  return (
    <>
      <AnimationOnScroll animateOnce animateIn="animate__zoomInDown">
        <Title>We already helped them...</Title>
      </AnimationOnScroll>

      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="responsive"
        disableDotsControls
        autoPlay={items.length > 3}
        autoPlayInterval={5000}
        infinite={true}
      />
    </>
  );
};

const Title = styled.h2`
  text-align: center;
  padding: 2rem 1rem 6rem 1rem;
  font-weight: bold;
`;

import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { LoginPopup } from "../../login/LoginPopup";

interface FreeAccessCardModel {
  accessPeriod: string;
  amount: number | string;
  amountRsd: number | string;
  currency: string;
  items: string[];
  color?: string;
  icon: any;
}

export const FreeAccessCard = (props: FreeAccessCardModel) => {
  const { accessPeriod, items, amount, amountRsd, currency, icon, color } =
    props;

  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);

  return (
    <Container>
      <LoginPopup isShowLogin={isShowLogin} setIsShowLogin={setIsShowLogin} />
      <AccessPeriod color={color}>
        <Typography variant="body2">{accessPeriod}</Typography>
      </AccessPeriod>
      <AnimationOnScroll animateOnce animateIn="animate__heartBeat">
        <Price>
          <Typography variant="h4" className="d-flex">
            {amount}
            <Currency variant="h4">{currency}</Currency>
          </Typography>
        </Price>
        {amountRsd || amountRsd === 0 ? (
          <Typography align="center" variant="body2">
            ({amountRsd} RSD)
          </Typography>
        ) : (
          ""
        )}
      </AnimationOnScroll>
      <Line />
      <AnimationOnScroll animateOnce animateIn="animate__slideInUp">
        <Items>
          {items.map((item: string, index: number) => (
            <div key={index}>
              <img src={icon} alt="Plus icon" />
              <StyledItems variant="caption">{item}</StyledItems>
            </div>
          ))}
        </Items>
      </AnimationOnScroll>
    </Container>
  );
};

export const Container = styled.div`
  background: #ffffff;
  width: 420px;

  @media (max-width: 1600px) {
    width: 450px;
  }

  position: relative;
  border-radius: 10px;
`;

export const AccessPeriod = styled.div`
  background: ${(props: any) => props.color};
  color: #ffffff;
  width: 85%;
  height: 70px;
  text-transform: uppercase;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-weight: bold;
  margin-top: 1rem;

  > h4,
  p {
    font-weight: bold;
  }
`;

const Line = styled.hr`
  color: #747474;
  margin: 1.5rem 4rem 0;
`;

const Currency = styled(Typography)``;

const Items = styled.div`
  color: #747474;
  padding: 2rem 3rem 0 3rem;
  line-height: 1.3rem;
  margin-bottom: 5rem;
  justify-content: center;
  display: grid;

  > div {
    display: flex;

    :not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  @media (max-width: 767px) {
    padding: 2rem 1rem 0 1rem;
  }
`;

const StyledItems = styled(Typography)`
  color: #747474;
  padding-left: 10px;
`;

import { fetchData } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Basic dGVzdDp0ZXN0",
  };
};

const getExecutionsCountByTaskId = (taskId: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/executions/` + taskId,
    "GET",
    requestHeaders(),
    null
  );
};

const addExecution = (task: any) => {
  console.log("Adding new task to executions: " + JSON.stringify(task));
  return fetchData(
    config.getBeServerEndpoint() + `/executions`,
    "POST",
    requestHeaders(),
    JSON.stringify(task)
  );
};

export { getExecutionsCountByTaskId, addExecution };

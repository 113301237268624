import messageIcon from "../../../assets/images/message_icon.svg";
import { getBeServerEndpoint } from "../../../config";
import LoadingPage from "../../shared/components/LoadingPage";
import { OutlinedButton } from "../../shared/components/buttons/OutlinedButton";
import styled from "@emotion/styled";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

interface FeedbackFormModel {
  buttonText: string;
  url: string;
}

type FormValues = {
  name: string;
  company: string;
  position: string;
  linkedinProfileUrl: string;
  review: string;
};

export const FeedbackForm = (props: FeedbackFormModel) => {
  const [isLoading, setIsLoading] = useState(false);

  const { buttonText, url } = props;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      company: "",
      position: "",
      linkedinProfileUrl: "",
      review: "",
    },
  });

  const handleFormSubmit = (form: FormValues) => {
    setIsLoading(true);
    const promise = axios
      .post(
        getBeServerEndpoint() + url,
        {
          name: form.name,
          company: form.company,
          position: form.position,
          linkedinProfileUrl: form.linkedinProfileUrl,
          review: form.review,
        },
        {
          headers: {
            Authorization: "Basic dGVzdDp0ZXN0",
            "content-type": "application/json",
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        reset();
        console.log(response.data);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message =
      "Feedback has been sent. Thank you for your assistance in making our platform better. BR, QASkills Team";

    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
        <FieldTitle variant="caption">Name *</FieldTitle>
        <StyledField
          {...register("name", {
            required: true,
          })}
        />
        {errors.name && !errors.name?.message && (
          <ErrorMessage>Name is required.</ErrorMessage>
        )}
        {errors.name?.message && (
          <ErrorMessage>{errors.name?.message}</ErrorMessage>
        )}

        <FieldTitle variant="caption">Company *</FieldTitle>
        <StyledField {...register("company", { required: true })} />
        {errors.company && <ErrorMessage>Company is required.</ErrorMessage>}

        <FieldTitle variant="caption">Position *</FieldTitle>
        <StyledField {...register("position", { required: true })} />
        {errors.position && <ErrorMessage>Position is required.</ErrorMessage>}

        <FieldTitle variant="caption">Linkedin profile URL *</FieldTitle>
        <StyledField {...register("linkedinProfileUrl", { required: true })} />
        {errors.linkedinProfileUrl && (
          <ErrorMessage>Social profile is required.</ErrorMessage>
        )}

        <FieldTitle variant="caption">Review *</FieldTitle>
        <StyledField
          {...register("review", { required: true })}
          multiline
          rows={3}
        />
        {errors.review && <ErrorMessage>Review is required.</ErrorMessage>}

        <StyledFormControlLabel
          control={<Checkbox checked={true} disabled />}
          label={
            <Typography variant="caption">
              I grant{" "}
              <a href="https://qaskills.io/" target="_blank">
                QASkills.io
              </a>{" "}
              to use my name, position, company and social profile image on
              social media and there official website for promotion.
            </Typography>
          }
        />

        <ButtonWrapper>
          <OutlinedButton
            text={buttonText}
            variant="outlined"
            disabled={false}
            icon={messageIcon}
            type="submit"
            redirectLink={undefined}
            color="#000000"
          />
        </ButtonWrapper>
      </StyledForm>
    </>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const FieldTitle = styled(Typography)`
  width: 70%;
  padding-top: 1rem;
  @media (max-width: 820px) {
    width: 85%;
  }
`;

const StyledField = styled(OutlinedInput)`
  width: 70%;
  color: gray;
  @media (max-width: 820px) {
    width: 85%;
  }
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-root {
    background: #ffffff;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 25px #dcdcdc;
  }

  & .MuiOutlinedInput-input {
    font-size: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  @media (max-width: 820px) {
    width: 85%;
  }
  color: ${(props: any) => props.theme.palette.error.main};
  font-size: 16px;
  margin-bottom: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 70%;
  padding-top: 2rem;
  @media (max-width: 820px) {
    width: 85%;
  }
`;

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { BreadcrumbNavigation } from "../../../shared/components/breadcrumb-navigation/Breadcrumb-Navigation";
import MatTable from "../../../shared/components/MatTable";
import { getByGroupAndUserId } from "../../../shared/services/api/LearningApi";
import { getUser } from "../../../utils";
import { Mixpanel } from "../../../../../Mixpanel";
import {
  TitleAndTaskCounter,
  HeadingTypeEnum,
} from "../../../shared/components/title-and-task-counter/TitleAndTaskCounter";
import { TaskDto } from "../../../shared/dtos/task.dto";

export const LinuxInteractionTasks = () => {
  const [user, setUser] = useState({
    username: "",
    type: "",
    _id: "",
  });
  const [linuxTasksRows, setLinuxTasksRows] = useState<TaskDto[]>([]);

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  useEffect(() => {
    setUser({
      ...user,
      username: currentUser?.username,
      type: currentUser?.type,
      _id: currentUser?._id,
    });
  }, []);

  useEffect(() => {
    getByGroupAndUserId("linuxtasks", user?._id).then(
      (linuxtasks: TaskDto[]) => {
        console.log("Get from API: " + linuxtasks);
        setLinuxTasksRows(linuxtasks);
      }
    );
  }, [user?._id]);

  return (
    <Container>
      <Title>Linux Interaction Tasks</Title>
      <BreadcrumbNavigation title="Linux Interaction Tasks" />

      <AccordionWrapper>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() => {
                Mixpanel.track("Linux content opened");
              }}
            >
              <TitleAndTaskCounter
                tasks1={linuxTasksRows}
                title="Linux"
                headingType={HeadingTypeEnum.H2}
              ></TitleAndTaskCounter>
            </Accordion.Header>
            <Accordion.Body>
              {linuxTasksRows && (
                <MatTable rows={linuxTasksRows} userType={user.type}></MatTable>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AccordionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;
  min-height: calc(100% - 15rem);
  height: auto;
  color: #000000;

  @media (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 970px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-top: 3rem;
`;

const AccordionWrapper = styled.div`
  height: 100%;
`;

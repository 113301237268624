import { LinkedInCertification } from "linkedin-certification";

import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import whiteGearImg from "../../../assets/images/white-gear.svg";
import certificateImg from "../../../assets/images/platform-process/certificate.svg";
import { OutlinedButton } from "../../shared/components/buttons/OutlinedButton";
import jsPDF from "jspdf";
import { getUser } from "../../utils";
import { fontBoldItalic } from "../../../assets/fonts/Lato-BoldItalic-normal";
import { font } from "../../../assets/fonts/Lato-Regular-normal";
import { AnimationOnScroll } from "react-animation-on-scroll";
import linkedinIcon from "../../../assets/images/social-media/linkedin.svg";

export interface CertificateCardModel {
  disabled: boolean;
}

export const CertificateCard = (props: CertificateCardModel) => {
  const { disabled } = props;
  const isSmallerDevice = useMediaQuery("(max-width:550px)");
  let objectDate = new Date();

  const getUserInfo = () => {
    let user = getUser();
    return JSON.parse(user as string);
  };

  const generatePDF = () => {
    let doc = new jsPDF({
      orientation: "landscape",
    });

    let image = new Image();
    image.src = require("../../../assets/images/certificate/certificate-prepared.png");

    let imageSig1 = new Image();
    imageSig1.src = require("../../../assets/images/certificate/signatures/nt.png");
    let imageSig2 = new Image();
    imageSig2.src = require("../../../assets/images/certificate/signatures/ss.png");
    let imageSig3 = new Image();
    imageSig3.src = require("../../../assets/images/certificate/signatures/nm.png");

    doc.addFileToVFS("Lato-BoldItalic.ttf", fontBoldItalic);
    doc.addFont("Lato-BoldItalic.ttf", "Lato-BoldItalic", "normal");
    doc.setFont("Lato-BoldItalic");

    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    doc.addImage(image, "png", 0, 0, width, height);

    doc.addImage(imageSig1, "png", 55, 164, 45, 15);
    doc.addImage(imageSig2, "png", 130, 162, 45, 15);
    doc.addImage(imageSig3, "png", 200, 165, 45, 15);

    doc.setFontSize(40);
    doc.setTextColor(0, 0, 0);
    doc.text(getUserInfo().name, height / 2 - 10, 90);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text("& CEO", 142, 198);

    doc.setFontSize(13);
    doc.addFileToVFS("Lato-Regular.ttf", font);
    doc.addFont("Lato-Regular.ttf", "Lato", "normal");
    doc.setFont("Lato");

    let objectDate = new Date();
    let day = objectDate.getDate();
    let month = objectDate.toLocaleString("default", { month: "long" });
    let year = objectDate.getFullYear();
    let dateFormat = month + " " + day + ", " + year;
    doc.text(dateFormat, height / 2 - 12, 158);

    doc.save("Certificate.pdf");
  };

  return (
    <Container disabled={disabled}>
      {!isSmallerDevice ? (
        <CertificateImg src={certificateImg} alt="Certificate image" />
      ) : (
        ""
      )}

      <CardDetails>
        <AnimationOnScroll
          animateOnce
          animateIn="animate__backInUp"
          offset={100}
        >
          <h3>
            <b>Certificate of Completion</b>
          </h3>
          <Description>
            <h5>
              {"Congratulations! You managed to get through all the tasks we have on QASkills.io. By going through all these tasks, you have mastered specific skills that you will be able to apply and use in one of the following job interviews. Good luck with your next conversation, and looking for your dream job.\n" +
                "Sincerely, QASkills.io Team ❤️"}
            </h5>
          </Description>
          {isSmallerDevice ? (
            <StyledCertificateImg
              src={certificateImg}
              alt="Certificate image"
              width="113"
              height="113"
            />
          ) : (
            ""
          )}
          <ButtonsWrapper>
            <OutlinedButton
              text="Download Certificate"
              disabled={disabled}
              icon={whiteGearImg}
              variant="outlined"
              type="action"
              executionMethod={generatePDF}
              color="#000000"
            />
            <LinkedInCertification
              certificationName="Certificate for completing the QASkills.io course"
              organizationName="QASkills.io"
              issuedMonth={objectDate.getMonth() + 1}
              issuedYear={objectDate.getFullYear()}
              expirationMonth={12}
              expirationYear={2033}
              newTab={true}
              customButton={
                <OutlinedButton
                  text="Add to LinkedIn"
                  disabled={disabled}
                  icon={linkedinIcon}
                  variant="outlined"
                  type="action"
                  color="#000000"
                />
              }
            />
          </ButtonsWrapper>
        </AnimationOnScroll>
      </CardDetails>
    </Container>
  );
};

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  background: ${(props) => (!props.disabled ? "#d8fcf7" : "#ffffff")};
  border-radius: 10px;
  padding: 1.5rem 2rem;
  pointer-events: ${(props: any) => (props.disabled ? "none" : "")};
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  height: auto;
  position: relative;
`;

const CertificateImg = styled.img`
  width: 200px;
  margin-right: 2rem;

  @media (max-width: 550px) {
    margin: auto;
    display: block;
    width: 150px;
  }
`;

const StyledCertificateImg = styled(CertificateImg)`
  margin: auto;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  height: auto;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #9c9c9c;
  > span {
    line-height: normal;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  zoom: 0.85;
`;

import styled from "@emotion/styled";
import levelUp from "../../../../assets/images/custom-error-page/level-up.svg";
import bugGroup from "../../../../assets/images/custom-error-page/404-bug-group.svg";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { OutlinedButton } from "../buttons/OutlinedButton";
import axios from "axios";
import { getPlatformEndpoint } from "../../../../config";
import { getUser } from "../../../utils";
import { useSelector } from "react-redux";

interface CustomErrorPageModel {
  title: string;
  description: string;
  image: any;
  buttonText: string;
  redirectLink: string;
  trnStatus: any;
}

export const CustomErrorPage = (props: CustomErrorPageModel) => {
  const { title, description, image, buttonText, redirectLink, trnStatus } =
    props;
  const theme = useTheme();
  const isSmallerDevice = useMediaQuery("(max-width:700px)");
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);

  if (isLoggedIn) {
    const userId = JSON.parse(getUser() as string)._id;

    const handleConfirmTrnStatus = () => {
      const promise = axios
        .post(
          getPlatformEndpoint() + "/transactions/confirm",
          {
            shoppingCartId: trnStatus.shoppingCartId,
            wsPayOrderId: trnStatus.wsPayOrderId,
            signature: trnStatus.signature,
            success: trnStatus.success,
            approvalCode: trnStatus.approvalCode,
            userId: userId,
            errorCode: trnStatus.errorCode,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then((response: any) => {
          console.log(response.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    };

    handleConfirmTrnStatus();
  }

  return (
    <Container>
      <BugsGroup src={bugGroup} alt="Not found page bugs icon" />
      <ContentContainer>
        <Title variant="h4" align="center">
          {title}
        </Title>
        <Description theme={theme} variant="subtitle1" align="center">
          <b>{description}</b>
        </Description>
        {isSmallerDevice ? (
          <TitleWithIcon>
            <Icon src={image} alt="Not found icon" />
          </TitleWithIcon>
        ) : null}
        <Description theme={theme} variant="subtitle2" align="center">
          <br />
          To return to the homepage, click the button below.
        </Description>
        <ButtonWrapper>
          <OutlinedButton
            text={buttonText}
            variant="contained"
            disabled={false}
            icon={levelUp}
            redirectLink={redirectLink}
            color="#000000"
          />
        </ButtonWrapper>
      </ContentContainer>
      {!isSmallerDevice ? <Icon src={image} alt="Not found icon" /> : ""}
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;
  display: flex;
  height: auto;

  @media (max-width: 1200px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const BugsGroup = styled.img`
  position: absolute;
  left: 6rem;
  height: 77%;
  margin: 0.5rem;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  vertical-align: center;
  flex-direction: column;
  margin: auto;
`;

const Description = styled(Typography)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.8rem;

  @media (max-width: 700px) {
    padding-top: 1rem;
    &.MuiTypography-subtitle1 {
      font-size: 1.5rem !important;
      font-weight: 400;
    }
    &.MuiTypography-subtitle2 {
      font-size: 1.3rem !important;
    }
    font-size: 1.4rem !important;
  }
  color: #747474;
`;

const Title = styled(Typography)`
  font-weight: bold;
  @media (max-width: 819px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const TitleWithIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  display: flex;
  margin: auto;
  @media (max-width: 1200px) {
    width: 50%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  light: {
    primary: {
      hex: "#00ACEA",
      rgb: "0, 172, 234",
      hs: "196, 100%",
      luminosity: 46,
      contrast: "#ffffff",
    },
    secondary: {
      hex: "#00EFD1",
      rgb: "0, 239, 209",
      hs: "172, 100%",
      luminosity: 47,
      contrast: "#ffffff",
    },
    error: {
      hex: "#EB1F5D",
      rgb: "235, 31, 93",
      hs: "342, 84%",
      luminosity: 52,
      contrast: "#ffffff",
    },
    colors: {
      background: "#F6F9FC",
      surface: "#ffffff",
      text: "#535353",
      shadow: "rgba(0, 86, 117, 0.15)",
    },
  },
  dark: {
    primary: {
      hex: "#00ACEA",
      rgb: "0, 172, 234",
      hs: "196, 100%",
      luminosity: 46,
      contrast: "#ffffff",
    },
    secondary: {
      hex: "#00EFD1",
      rgb: "0, 239, 209",
      hs: "172, 100%",
      luminosity: 47,
      contrast: "#ffffff",
    },
    error: {
      hex: "#EB1F5D",
      rgb: "235, 31, 93",
      hs: "342, 84%",
      luminosity: 52,
      contrast: "#ffffff",
    },
    colors: {
      background: "#303941",
      surface: "#3F4A54",
      text: "#ffffff",
      shadow: "rgba(0, 86, 117, 0.15)",
    },
  },
  spacing: {
    mainSpacing: "3rem",
  },
};

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import variables from "./variables";

const { light, dark, spacing } = variables;

export const calcLuminosity = (
  luminosity: number,
  multiplier: number,
  increase: any
) => {
  // Default increase in color luminosity is 10.
  // There are 2 of darker and 2 of lighter colors -> 2.
  // If '100 - luminosity' or 'luminosity' are less than 2*10 -> 20,
  // Than we take their value divided by 2 and rounded on smaller number.
  const stepLighter =
    100 - luminosity >= 20 ? 10 : Math.floor((100 - luminosity) / 2);
  const stepDarker = luminosity >= 20 ? 10 : Math.floor(luminosity / 2);

  if (increase) {
    return luminosity + stepLighter * multiplier;
  }
  return luminosity - stepDarker * multiplier;
};

let themeMap: any = {
  lightTheme: createTheme({
    palette: {
      mode: "light",
      primary: {
        main: `hsl(${light.primary.hs}, ${light.primary.luminosity}%)`,
        contrastText: light.primary.contrast,
      },

      secondary: {
        main: `hsl(${light.secondary.hs}, ${light.secondary.luminosity}%)`,
        contrastText: light.secondary.contrast,
      },

      error: {
        main: `hsl(${light.error.hs}, ${light.error.luminosity}%)`,
        contrastText: light.error.contrast,
      },
    },
    spacing: [0, 4, 8, 16, 32, 64],
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      htmlFontSize: 10,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            border: "none",
            boxShadow: "0 0 25px #dcdcdc",

            // MuiAccordionSummary: {
            //   root: {
            //     "&. Mui-expanded": {
            //       margin: "auto",
            //     },
            //   },
            // },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {},
      },
    },
  }),
  darkTheme: createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: `hsl(${dark.primary.hs}, ${dark.primary.luminosity}%)`,
        contrastText: dark.primary.contrast,
      },

      secondary: {
        main: `hsl(${dark.secondary.hs}, ${dark.secondary.luminosity}%)`,
        contrastText: dark.secondary.contrast,
      },

      error: {
        main: `hsl(${dark.error.hs}, ${dark.error.luminosity}%)`,
        contrastText: dark.error.contrast,
      },
      // colors: dark.colors,
    },
    spacing: [0, 4, 8, 16, 32, 64],
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      htmlFontSize: 10,
    },
  }),
};

themeMap.lightTheme.props = {
  MuiButton: {
    size: "small",
  },
  MuiSvgIcon: {
    size: "large",
  },
  MuiFilledInput: {
    margin: "dense",
  },
  MuiFormControl: {
    margin: "dense",
  },
  MuiFormHelperText: {
    margin: "dense",
  },
  MuiIconButton: {
    size: "small",
  },
  MuiInputBase: {
    margin: "dense",
  },
  MuiInputLabel: {
    margin: "dense",
  },
  MuiListItem: {
    dense: true,
  },
  MuiOutlinedInput: {
    margin: "dense",
  },
  MuiFab: {
    size: "small",
  },
  MuiTable: {
    size: "small",
  },
  MuiTextField: {
    margin: "dense",
  },
  MuiToolbar: {
    variant: "dense",
  },
  MuiTypography: {
    variant: "body1",
  },
  MuiSelect: {
    variant: "standard",
  },
  MuiMenuItem: {
    dense: true,
  },
  MuiGrid: {
    variant: "dense",
  },
  MuiBackdrop: {
    variant: "dense",
  },
  MuiDialog: {
    variant: "dense",
  },
  MuiDialogContent: {
    variant: "dense",
  },
  MuiMenu: {
    variant: "menu",
  },
};

themeMap.lightTheme.props.overrides = {
  MuiButton: {
    containedSizeLarge: {
      color: light.primary.contrast,
      backgroundImage: `linear-gradient(to top right, ${light.primary.hex}, ${light.secondary.hex})`,
      fontSize: "2.3rem",
      padding: "1rem 4rem",
      boxShadow: `0px 3px 1px -2px ${light.colors.shadow} 0px 2px 2px 0px ${light.colors.shadow}, 0px 1px 5px 0px ${light.colors.shadow}`,
      borderRadius: "3px",

      "&:hover, &:focus, &:active": {
        boxShadow: `0px 5px 5px -3px ${light.colors.shadow}, 0px 8px 10px 1px ${light.colors.shadow}, 0px 3px 14px 2px ${light.colors.shadow}`,
      },

      "& > span": {
        textTransform: "none",
        display: "flex",
        alignContent: "center",
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "1.5rem",
      color: light.colors.text,
      backgroundColor: light.colors.background,
    },
  },
  MuiTablePagination: {
    root: {
      display: "flex",
      justifyContent: "center",
    },
  },
  MuiCollapse: {
    entered: {
      padding: "2rem",
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: "inherit",
    },
    barColorPrimary: {
      backgroundColor: "hsl(184, 100%, 46%)",
    },
  },
  MuiInputBase: {
    root: {
      fontSize: "2rem",
    },
    input: {
      backgroundColor: light.colors.background,
      zIndex: 1,
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      color: light.primary.contrast,
      zIndex: 2,
    },
  },
  MuiInputLabel: {
    outlined: {
      zIndex: 2,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: "1.8rem",
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: "1.4rem",
    },
  },
};

themeMap.darkTheme.props = {
  MuiButton: {
    size: "small",
  },
  MuiFilledInput: {
    margin: "dense",
  },
  MuiFormControl: {
    margin: "dense",
  },
  MuiFormHelperText: {
    margin: "dense",
  },
  MuiIconButton: {
    size: "small",
  },
  MuiInputBase: {
    margin: "dense",
  },
  MuiInputLabel: {
    margin: "dense",
  },
  MuiListItem: {
    dense: true,
  },
  MuiOutlinedInput: {
    margin: "dense",
  },
  MuiFab: {
    size: "small",
  },
  MuiTable: {
    size: "small",
  },
  MuiTextField: {
    margin: "dense",
  },
  MuiToolbar: {
    variant: "dense",
  },
  MuiTypography: {
    variant: "body1",
  },
};

themeMap.darkTheme.props.overrides = {
  MuiButton: {
    containedSizeLarge: {
      color: dark.primary.contrast,
      backgroundImage: `linear-gradient(to top right, ${dark.primary.hex}, ${dark.secondary.hex})`,
      fontSize: "2.3rem",
      padding: "1rem 4rem",
      boxShadow: `0px 3px 1px -2px ${dark.colors.shadow} 0px 2px 2px 0px ${dark.colors.shadow}, 0px 1px 5px 0px ${dark.colors.shadow}`,
      borderRadius: "3px",

      "&:hover, &:focus, &:active": {
        boxShadow: `0px 5px 5px -3px ${dark.colors.shadow}, 0px 8px 10px 1px ${dark.colors.shadow}, 0px 3px 14px 2px ${dark.colors.shadow}`,
      },

      "& > span": {
        textTransform: "none",
        display: "flex",
        alignContent: "center",
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "1.5rem",
      color: dark.primary.contrast,
      backgroundColor: dark.colors.background,
    },
  },
  MuiTablePagination: {
    display: "flex",
    justifyContent: "center",
  },
  MuiCollapse: {
    entered: {
      padding: "2rem",
      background: "gray",
    },
  },
  MuiLinearProgress: {
    colorPrimary: {
      backgroundColor: "inherit",
    },
    barColorPrimary: {
      backgroundColor: "hsl(184, 100%, 46%)",
    },
  },
  MuiInputBase: {
    root: {
      fontSize: "2rem",
    },
    input: {
      backgroundColor: dark.colors.background,
      zIndex: 1,
    },
  },
  MuiOutlinedInput: {
    notchedOutline: {
      color: dark.primary.contrast,
      zIndex: 2,
    },
  },
  MuiInputLabel: {
    outlined: {
      zIndex: 2,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: "1.8rem",
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: "1.4rem",
    },
  },
};

export function getThemeByName(theme: string) {
  return themeMap[theme];
}

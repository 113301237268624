import { RoadmapCard } from "../roadmap-card/RoadmapCard";
import { CertificateCard } from "../certificate-card/CertificateCard";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { FinalTestAndInterviewCard } from "../FinalTestAndInterviewCard";

interface SimpleRoadmapLayoutModel {
  disabled: boolean;
  userId: string;
  setIsLoading: (isLoading: boolean) => void;
}

export const SimpleRoadmapLayout = (props: SimpleRoadmapLayoutModel) => {
  const { disabled, userId, setIsLoading } = props;
  let navigate = useNavigate();

  return (
    <Column>
      <Card
        id="fundamentals-and-theory-step"
        onClick={() => navigate("/qaskills-fundamentals")}
      >
        <RoadmapCard
          title="QASkills: Fundamentals and theory"
          completedTasks={24}
          totalTasks={24}
          description={
            "Regardless of the seniority, everyone needs to know the fundamental processes and rules when testing software. It is always best to start learning the basic concepts in testing."
          }
          lesson=""
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="test-case-and-bug-step"
        onClick={() => navigate("/test-case-tasks")}
      >
        <RoadmapCard
          title="QASkills: Test Case and Bug Tasks"
          completedTasks={0}
          totalTasks={24}
          description={
            "Writing test cases is normally the responsibility of someone on the software development team, testing team, or quality assurance team. It’s typically preferred that a professional who was not involved with writing the code should write the test cases since they have a fresh perspective."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="development-methodologies-step"
        onClick={() => navigate("/development-methodologies")}
      >
        <RoadmapCard
          title="QASkills: Development methodologies"
          completedTasks={24}
          totalTasks={24}
          description={
            "All teams work on the principle of some agile methodology, and one of the most widespread is called Scrum. Therefore, a QA engineer must know the fundamental processes of the mentioned method."
          }
          lesson=""
        />
      </Card>
      <StyledVerticalLine />
      <Card id="coding-skills-step" onClick={() => navigate("/coding-skills")}>
        <RoadmapCard
          title="QASkills: Coding Skills"
          completedTasks={10}
          totalTasks={24}
          description={
            "Before you start writing automatic tests, it is necessary to learn the basics of programming. It is best to start with simple examples, such as the examples in this section."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="automation-tasks-step"
        onClick={() => navigate("/automation-tasks")}
      >
        <RoadmapCard
          title="QASkills: Automation tasks"
          completedTasks={2}
          totalTasks={24}
          description={
            "After processing the QASkills Fundamentals and Coding Skills sections, we are finally ready to start writing automated tests. This section will cover the writing of automatic Unit tests, Rest API tests, and EndToEnd tests."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="database-tasks-step"
        onClick={() => navigate("/database-tasks")}
      >
        <RoadmapCard
          title="QASkills: Database tasks"
          completedTasks={0}
          totalTasks={24}
          description={
            "Many applications store their data in an SQL database. Writing SQL sentences is an essential skill for a QA engineer, and some of the basic examples are covered in this section."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="linux-interaction-step"
        onClick={() => navigate("/linux-interaction-tasks")}
      >
        <RoadmapCard
          title="QASkills: Linux Interactions Task"
          completedTasks={0}
          totalTasks={24}
          description={
            "Most servers are using Linux systems and for every QA Engineer is super important to know most used linux commands."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="automationinfra-fundamentals-step"
        onClick={() => navigate("/automation-infra-fundamentals")}
      >
        <RoadmapCard
          title="AutomationInfra: Fundamentals and theory"
          completedTasks={10}
          totalTasks={24}
          description={
            "Knowing the basic principles, applications, or processes with excellent and proper infrastructure for automatically testing software is necessary. Some of the most commonly used methods or applications are mentioned in the first section."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="automationinfra-tasks-step"
        onClick={() => navigate("/automation-infra-tasks")}
      >
        <RoadmapCard
          title="AutomationInfra: Tasks"
          completedTasks={2}
          totalTasks={24}
          description={
            "In practical examples of a conversation within a team, we can see the key and most common questions that a QA engineer needs to ask. You can check our samples to get a good look at the whole application, and we suggest setting up an automated testing infrastructure for these applications."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card
        id="soft-skills-step"
        onClick={() => navigate("/soft-skills-fundamentals")}
      >
        <RoadmapCard
          title="Other: Soft Skills Fundamentals"
          completedTasks={0}
          totalTasks={24}
          description={
            "Working in any team requires specific skills which are not technical. It is no different for any team working on software products, and all communication skills are necessary for one QA engineer to work with all team members or users of the application itself."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />
      <Card id="cv-step" onClick={() => navigate("/how-to-write-cv")}>
        <RoadmapCard
          title="Other: How to write a CV"
          completedTasks={0}
          totalTasks={24}
          description={
            "For the company to know what skills we have, it is necessary to send a CV. Writing a CV itself is a skill that can be learned or improved, and some of the basic rules are covered in this section."
          }
          lesson="Name of the lesson user is currently working on is some practical tasks"
        />
      </Card>
      <StyledVerticalLine />

      <StyledVerticalLine />
      <Card id="certificate-step">
        <CertificateCard disabled={disabled} />
      </Card>
      <FinalCard id="final-test-step">
        <FinalTestAndInterviewCard
          disabled={disabled}
          userId={userId}
          setIsLoading={setIsLoading}
        />
      </FinalCard>
      <StyledVerticalLine />
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 90%;
  margin: auto;
  cursor: pointer;
`;

const StyledVerticalLine = styled.div`
  border-right: 2px solid #747474;
  height: 150px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
`;

const FinalCard = styled.div`
  width: 90%;
  cursor: pointer;
  z-index: 2;
  margin: auto;
  margin-top: 5rem;

  &:hover {
    box-shadow: 0 0 20px #6c2dc7;
    transform: scale(1.02);
    overflow: auto;
  }
`;

import { useState, useEffect } from "react";

import { get } from "./shared/services/api/LearningApi";

import { Breadcrumb } from "react-bootstrap";

function DemoPresentationToClient() {
  const [, setLearning] = useState([]);

  useEffect(() => {
    get()
      // .then(res => res.json())
      .then((learning) => {
        console.log("Get from API: " + learning);
        setLearning(learning);
      });
  }, []);

  return (
    <div className="">
      <hr />
      <h1>Demo Presentations to client</h1>

      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Demo Presentations to client</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default DemoPresentationToClient;

const APP_URI = process.env.REACT_APP_URI || "http://localhost";

export const getAppClientBeEndpoint = () => {
  return APP_URI + "/appbe";
};

export const getBeServerEndpoint = () => {
  return APP_URI + "/beserver";
};

export const getPlatformEndpoint = () => {
  return APP_URI + "/platform/v1";
};

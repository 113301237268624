import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { ProgressBar } from "../shared/components/progress-bar/ProgressBar";
import { taskSubmmisionStatisticStatic } from "../shared/dtos/taskSubmissionStatistic.dto";
import {
  getStatisticsStaticContentSubmittedByUserId,
  getStatisticsSubmittedByUserId,
} from "../shared/services/api/TaskSubmissionApi";
import { getUser } from "../utils";

export const Progress = () => {
  const [user, setUser] = useState({ id: 0 });

  const [taskSubmmisionStatistic, setTaskSubmmisionStatistic] =
    useState<taskSubmmisionStatisticStatic>({
      tasksNumber: 0,
      submittedTasks: 0,
      percentageDone: 0,
    });

  const [
    taskSubmmisionStatisticStaticContent,
    setTaskSubmmisionStatisticStaticContent,
  ] = useState<taskSubmmisionStatisticStatic>({
    tasksNumber: 0,
    submittedTasks: 0,
    percentageDone: 0,
  });

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  useEffect(() => {
    setUser({
      ...user,
      id: currentUser?._id,
    });

    getStatisticsSubmittedByUserId(currentUser?._id).then(
      (taskSubmmisionStatistic: any) => {
        console.log("taskSubmmisionStatistic", taskSubmmisionStatistic);
        setTaskSubmmisionStatistic({
          ...taskSubmmisionStatistic,
          taskSubmmisionStatistic,
        });
      }
    );

    getStatisticsStaticContentSubmittedByUserId(currentUser?._id).then(
      (taskSubmmisionStatisticStaticContent: any) => {
        console.log(
          "taskSubmmisionStatisticStaticContent",
          taskSubmmisionStatisticStaticContent
        );
        setTaskSubmmisionStatisticStaticContent({
          ...taskSubmmisionStatisticStaticContent,
          taskSubmmisionStatisticStaticContent,
        });
      }
    );
  }, []);

  return (
    <span id="progress-step">
      <StyledProgressBar
        title="Theoretical tasks"
        percentage={taskSubmmisionStatisticStaticContent.percentageDone}
        total={taskSubmmisionStatisticStaticContent.tasksNumber}
        submittedTasks={taskSubmmisionStatisticStaticContent.submittedTasks}
      />
      <StyledProgressBar
        title="Practical tasks"
        percentage={taskSubmmisionStatistic.percentageDone}
        total={taskSubmmisionStatistic.tasksNumber}
        submittedTasks={taskSubmmisionStatistic.submittedTasks}
      />
    </span>
  );
};

const StyledProgressBar = styled(ProgressBar)`
  @media (max-width: 600px) {
    background: red !important;
    width: 100% !important;
  }
`;

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SaleCompletedModel {
  endSaleText: string;
  setShowFlashSale: (showFlashSale: boolean) => void;
}

export const SaleCompleted = (props: SaleCompletedModel) => {
  const { endSaleText, setShowFlashSale } = props;

  // const endSaleText =
  //   "Thank you to all of our customers who participated in our countdown sale! We appreciate your support and hope you enjoy your purchases. Stay tuned for future deals and promotions, your QASkills team!";

  return (
    <>
      <StyledTypography variant="caption">
        {endSaleText}{" "}
        <CloseIconWrapper onClick={() => setShowFlashSale(false)}>
          <CloseIcon />
        </CloseIconWrapper>
      </StyledTypography>
    </>
  );
};

const StyledTypography = styled(Typography)`
  padding: 1rem 0.5rem 1rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to top right, yellow, red);
`;

const CloseIconWrapper = styled.div`
  width: 30px;
  position: relative;
  top: 50%;
  transform: translateX(-50%);
  margin-left: 1rem;
  padding-left: 1rem;
  display: block;
  margin-bottom: 2rem;
  margin: auto;
  clear: both;
  cursor: pointer;

  & svg {
    font-size: 25px;
  }
`;

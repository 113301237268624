import React, { useState, useEffect } from "react";

// Import AceEditor Component
import AceEditor from "react-ace";
// lazy loaded ace editor themes with react-ace-builds
// example themes monokai, github, tomorrow, kuroir, twilight, xcode, textmate, solarized dark, solarized light, terminal
import "react-ace-builds/webpack-resolver-min";

const editorStyle = {
  border: "1px solid lightgray",
  lineHeight: "1.8rem",
};

function CodeEditor(props: {
  lang: any;
  onChange: (arg0: any) => void;
  code: any;
  isReadonly: boolean;
}) {
  const [lang, setLang] = useState("");
  useEffect(() => {
    const currentLang = props.lang;

    // console.log('CodeEditor lang: ' + currentLang)
    switch (currentLang) {
      case "mocha":
        setLang("javascript");
        break;
      case "protractor":
        setLang("javascript");
        break;
      case "mocharestapi":
        setLang("javascript");
        break;
      case "junit":
        setLang("java");
        break;
      case "junitrestapi":
        setLang("java");
        break;
      case "pythonunittest":
        setLang("python");
        break;
      case "unittestrestapi":
        setLang("python");
        break;
      case "pythonend2end":
        setLang("python");
        break;
      default:
        // console.log("Not found mode for code editor: " + currentLang);
        setLang(currentLang);
        break;
    }
  }, []);

  const onChange = (newValue: any) => {
    props.onChange(newValue);
  };

  return (
    <AceEditor
      style={editorStyle}
      readOnly={props.isReadonly}
      onChange={onChange}
      width="100%"
      height="370px"
      mode={lang}
      theme="monokai"
      name="aceCodeEditor"
      // onLoad={this.onLoad}
      fontSize={16}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={props.code}
      editorProps={{
        $blockScrolling: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
      }}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}

export default CodeEditor;

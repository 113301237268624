// var base64 = require('base-64');
// var utf8 = require('utf8');

const TOKEN_KEY = "jwt";

// update user details
export const setNewUser = (user: any) => {
  console.log("Save token: " + JSON.stringify(user));
  localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
};

export const logoutUser = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  return !!localStorage.getItem(TOKEN_KEY);
};

export const getUser = () => {
  const user = localStorage.getItem(TOKEN_KEY);
  // console.log("Get token: " + JSON.stringify(user));
  return user;
};

import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import DemoPresentationToClient from "./core/components/DemoPresentationToClient";
import { ContactUs } from "./core/components/footer-pages/contact-us/ContactUs";
import { Faq } from "./core/components/footer-pages/faq/Faq";
import { Feedback } from "./core/components/footer-pages/feedback/Feedback";
import { PrivacyPolicy } from "./core/components/footer-pages/privacypolicy/Privacypolicy";
import { Reviews } from "./core/components/footer-pages/reviews/Reviews";
import { TermsOfUse } from "./core/components/footer-pages/terms-of-use/TermsOfUse";
import { AutomationInfra } from "./core/components/header-pages/automation-infra/AutomationInfra";
import { LandingPage } from "./core/components/header-pages/landing-page/LandingPage";
import { PurchasePage } from "./core/components/header-pages/purchase/PurchasePage";
import { TeamPage } from "./core/components/header-pages/team/TeamPage";
import RequireAuth from "./core/components/login/PrivateRoute";
import { PaymentForm } from "./core/components/payment-form/PaymentForm";
import { AutomationInfraFundamentals } from "./core/components/platform-content/automation-infra/automation-infra-fundamentals/AutomationInfraFundamentals";
import { AutomationInfraTasks } from "./core/components/platform-content/automation-infra/automation-infra-tasks/AutomationInfraTasks";
import { HowToWriteCvFundamentals } from "./core/components/platform-content/other/how-to-write-cv-fundamentals/HowToWriteCvFundamentals";
import { SoftSkillsFundamentals } from "./core/components/platform-content/other/soft-skills-fundamentals/SoftSkillsFundamentals";
import { AutomationTasks } from "./core/components/platform-content/qa-skills/automation-tasks/AutomationTasks";
import { CodingSkills } from "./core/components/platform-content/qa-skills/coding-skills/CodingSkills";
import { DatabaseTasks } from "./core/components/platform-content/qa-skills/database-tasks/Database-tasks";
import { DevelpomentMethodologies } from "./core/components/platform-content/qa-skills/development-methodologies/DevelopmentMethodologies";
import { LinuxInteractionTasks } from "./core/components/platform-content/qa-skills/linux-interaction-tasks/LinuxInteractionTasks";
import { TestCaseTasks } from "./core/components/platform-content/qa-skills/testcase-tasks/TestCaseTasks";
import { QaSkillsFundamentals } from "./core/components/platform-content/qa-skills/qa-skills-fundamentals/QaSkillsFundamentals";
import { RoadmapAndProgress } from "./core/components/roadmap-and-progress/RoadmapAndProgress";
import { NotFoundPage } from "./core/components/shared/components/404-page/NotFoundPage";
import Editor from "./core/components/shared/components/editor/Editor";
import { Maintenance } from "./core/components/shared/components/maintenance-page/Maintenance";
import { PaymentPageBasedOnTransaction } from "./core/components/shared/components/payment/PaymentPageBasedOnTransaction";
import { clientId } from "./core/components/utils/clientId";
import { PageLayout } from "./core/components/page-layout/PageLayout";
import "animate.css";
import ScrollToTopOnReload from "./ScrollToTop";
import { useEffect } from "react";
import { setNewUser } from "./core/components/utils";
import { useSelector } from "react-redux";

// import Career from "./core/components/footer-pages/Career";

function App() {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      // Check the environment
      if (process.env.NODE_ENV === "development") {
        setNewUser({
          _id: "507f191e810c19729de860ea",
          name: "Guest",
          type: "FREE",
        });
      } else if (process.env.REACT_APP_ENV_ARG === "production") {
        setNewUser({
          _id: "65af7c46e70d9f0037825c74",
          name: "Guest",
          type: "FREE",
        });
      } else if (process.env.REACT_APP_ENV_ARG === "test") {
        setNewUser({
          _id: "65af7bb2995c11003d57f4b5",
          name: "Guest",
          type: "FREE",
        });
      }
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <ScrollToTopOnReload />
        <Routes>
          <Route element={<PageLayout />}>
            {/* Public routes */}
            <Route path="/" element={<Navigate to="/qaskills" />} />
            <Route path="/qaskills" element={<LandingPage />} />
            <Route path="/automation-infra" element={<AutomationInfra />} />
            <Route
              path="/products-price"
              element={<PurchasePage history={[]} />}
            />
            {/*<Route path="/career" element={<Career />} />*/}
            <Route path="/team" element={<TeamPage />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />

            {/* Private routes */}
            <Route
              path="/contactus"
              element={
                <RequireAuth redirectTo="/login">
                  <ContactUs />
                </RequireAuth>
              }
            />
            <Route
              path="/payment"
              element={
                <RequireAuth redirectTo="/login">
                  <PurchasePage history={[]} />
                </RequireAuth>
              }
            />
            <Route
              path="/feedback"
              element={
                <RequireAuth redirectTo="/login">
                  <Feedback />
                </RequireAuth>
              }
            />
            <Route path="/automation-tasks" element={<AutomationTasks />} />
            <Route path="/coding-skills" element={<CodingSkills />} />
            <Route
              path="/qaskills-fundamentals"
              element={<QaSkillsFundamentals />}
            />
            <Route
              path="/soft-skills-fundamentals"
              element={<SoftSkillsFundamentals />}
            />
            <Route
              path="/how-to-write-cv"
              element={<HowToWriteCvFundamentals />}
            />
            <Route
              path="/development-methodologies"
              element={<DevelpomentMethodologies />}
            />
            <Route path="/database-tasks" element={<DatabaseTasks />} />
            <Route
              path="/linux-interaction-tasks"
              element={<LinuxInteractionTasks />}
            />
            <Route path="/test-case-tasks" element={<TestCaseTasks />} />
            <Route
              path="/automation-infra-fundamentals"
              element={<AutomationInfraFundamentals />}
            />
            <Route
              path="/automation-infra-tasks"
              element={<AutomationInfraTasks />}
            />
            <Route
              path="/demo-pres"
              element={
                <RequireAuth redirectTo="/login">
                  <DemoPresentationToClient />
                </RequireAuth>
              }
            />
            <Route
              path="/editor/:id/:type"
              element={
                <Editor
                  match={{
                    params: {
                      id: "",
                      type: "",
                    },
                  }}
                />
              }
            />
            <Route
              path="/roadmap-and-progress"
              element={<RoadmapAndProgress />}
            />
            <Route
              path="/success"
              element={
                <RequireAuth redirectTo="/login">
                  <PaymentPageBasedOnTransaction />
                </RequireAuth>
              }
            />
            <Route
              path="/error"
              element={
                <RequireAuth redirectTo="/login">
                  <PaymentPageBasedOnTransaction />
                </RequireAuth>
              }
            />
            <Route
              path="/cancel"
              element={
                <RequireAuth redirectTo="/login">
                  <PaymentPageBasedOnTransaction />
                </RequireAuth>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/payment-form" element={<PaymentForm />} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;

import styled from "@emotion/styled";
import quantox from "../../../assets/images/companies/Quantox-Technology-logo.png";
import { Testimonial } from "../../header-pages/landing-page/testimonials/Testimonial";
import { PageIntro } from "../../shared/components/PageIntro";
import { VerticalLine } from "../../shared/components/VerticalLine";

export const Reviews = () => {
  return (
    <>
      <Container>
        <PageIntro
          description="Have something to say about QASkills? Great! We love to hear feedback from people. Feel free to share feedback about your experience with us below."
          subtitle="We're dedicated to your success."
          title="Reviews"
          showOutlinedButton={true}
        />

        <VerticalLine />

        <Cards>
          <StyledTestimonial
            name="Stefan"
            testimonial="QASkills appeared at a time when I was a bit tired of reading and watching different QA-related courses, which all looked similar, and none of them gave me a true vision of what it takes to learn, so I can dare to apply for the real QA position. I needed a different approach, and the QASkills concept seemed like the perfect match for me as I was searching for a single place where I could learn and practically check and track what I've learned right away. Going through the flow of QASkills lessons and tasks, I was getting the knowledge much easier, and I got from zero to ready at no time. I passed the interview and started working as a junior QA. I was surprised at how essential the lessons from the QASkills were for actual tasks at work. I'm writing this to say thank you, guys, and of course, I would highly recommend QASkills to all the future and current QA's."
            companyLogo={quantox}
            position="Junior QA Engineer"
          />
        </Cards>
      </Container>
    </>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  justify-content: center;
  padding-top: 5rem;
`;

const StyledTestimonial = styled(Testimonial)`
  width: 33%;
  flex-grow: 1;
  margin: 5rem;
  flex-basis: 33.3333%;
`;

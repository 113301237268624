export const setLoginState = () => {
  return {
    type: "SIGN_IN",
  };
};

export const setLogoutState = () => {
  return {
    type: "SIGN_OUT",
  };
};

export const setLightTheme = () => {
  return {
    type: "LIGHT_THEME",
  };
};

export const setDarkTheme = () => {
  return {
    type: "DARK_THEME",
  };
};

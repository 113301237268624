import { isLogin, logoutUser } from "../components/utils";

const loggedReducer = (state = isLogin(), action: { type: string }) => {
  switch (action.type) {
    case "SIGN_IN":
      return true;
    case "SIGN_OUT":
      logoutUser();
      return false;
    default:
      return state;
  }
};

export default loggedReducer;

import { Outlet } from "react-router-dom";
import Footer from "../shared/components/Footer";
import { Header } from "../shared/components/header/Header";
import { FlashSale } from "../shared/components/flash-sale/FlashSale";

export const PageLayout = () => {
  return (
    <>
      <FlashSale />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

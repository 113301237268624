import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

function AlertDismissable(props: any) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.show !== show) {
      setShow(props.show);
    }
  }, [props, show]);

  const handleDismiss = () => {
    setShow(false);
  };

  if (show) {
    return (
      <Alert onClose={handleDismiss}>
        <h5>Oh snap! You got an error!</h5>
        <p style={{ whiteSpace: "pre-wrap" }}>{props.message}</p>
      </Alert>
    );
  }

  return null;
}

AlertDismissable.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default AlertDismissable;

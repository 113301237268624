import { Button, Tooltip, Zoom } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface OutlinedButtonModel {
  text: string;
  variant: "text" | "outlined" | "contained" | undefined;
  disabled?: boolean;
  icon: any;
  color?: string;
  redirectLink?: string;
  type?: string;
  triggerAnimation?: boolean;
  executionMethod?: () => void;
  tooltipText?: string;
}

export const OutlinedButton = (props: OutlinedButtonModel) => {
  const {
    text,
    variant,
    disabled,
    icon,
    color,
    redirectLink,
    type,
    triggerAnimation,
    executionMethod,
    tooltipText,
  } = props;
  let navigate = useNavigate();

  const redirection = () => {
    if (redirectLink) {
      navigate(redirectLink);
    } else if (redirectLink === undefined) {
      return;
    } else {
      navigate("/payment");
    }
  };

  return (
    <Container>
      {triggerAnimation ? (
        <AnimationOnScroll animateOnce animateIn="animate__flipInX" offset={10}>
          <StyledButton
            customcolor={color}
            onClick={executionMethod ? executionMethod : redirection}
            variant={variant}
            disabled={disabled}
            type={type === "submit" ? "submit" : undefined}
          >
            {text}
          </StyledButton>
          <ButtonIcon>
            <Circle customcolor={color} disabled={disabled}>
              <img src={icon} alt="Button icon" />
            </Circle>
          </ButtonIcon>
        </AnimationOnScroll>
      ) : (
        <>
          <Tooltip
            title={tooltipText ? tooltipText : ""}
            TransitionComponent={Zoom}
            arrow={true}
          >
            <span>
              <StyledButton
                customcolor={color}
                onClick={executionMethod ? executionMethod : redirection}
                variant={variant}
                disabled={disabled}
                type={type === "submit" ? "submit" : undefined}
              >
                {text}
              </StyledButton>
            </span>
          </Tooltip>
          <ButtonIcon>
            <Circle customcolor={color} disabled={disabled}>
              <img src={icon} alt="Button icon" />
            </Circle>
          </ButtonIcon>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 320px;
  height: 70px;

  @media (max-width: 420px) {
    width: auto;
  }
`;

const StyledButton = styled(Button)<{ customcolor: string | undefined }>`
  width: 100%;
  height: 70px;
  padding-left: 5rem;
  border-radius: 20px;
  text-transform: none;
  background: transparent;
  z-index: 4;
  line-height: 1.5rem;
  color: ${(props: any) => props.customcolor ?? "#000000"};

  border-color: ${(props: any) => props.customcolor ?? "black"};
  border-width: 3px;
  border-style: solid;
  cursor: pointer;

  :hover {
    border-color: ${(props: any) => props.customcolor ?? "#000000"};
    background: transparent;
    border-width: 3px;
    box-shadow: 0 0 15px gray;
  }
  box-shadow: none;

  &:disabled {
    color: gray;
    border: 3px solid gray;
    cursor: not-allowed;
  }
`;

const ButtonIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0.5%;
  cursor: not-allowed;

  img {
    max-height: 35px;
  }
`;

const Circle = styled.div<{
  customcolor: string | undefined;
  disabled: boolean | undefined;
}>`
  background: ${(props: any) => (props.disabled ? "gray" : props.customcolor)};
  width: 70px;
  height: 70px;
  border-radius: 28px 70px 0 28px;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
`;

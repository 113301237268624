import { useState } from "react";
import { Image } from "react-bootstrap";
import styled from "@emotion/styled";
import { useTheme, makeStyles } from "@mui/styles";
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  useMediaQuery,
} from "@mui/material";

import clock from "../../../assets/images/table-icons/clock.svg";
import doubleCheckmark from "../../../assets/images/table-icons/double-checkmark.svg";

import { Trigger } from "../../controls/Trigger";
import { NavLink } from "react-router-dom";
import { LevelDifficulty } from "./level-difficulty/LevelDifficulty";
import unlock from "../../../assets/images/padlock/unlock.png";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

function MatTable(props: {
  rows: any;
  userType: string;
  location?: { taskPage: string; taskUrl: string };
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let rows = props.rows;
  const isSmallerDevice = useMediaQuery("(max-width:1023px)");

  const classes = useStyles();
  const theme = useTheme();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function levelFormatter(cell: string) {
    if (cell === "1") {
      return <LevelDifficulty level="Easy" />;
    } else if (cell === "2") {
      return <LevelDifficulty level="Medium" />;
    } else if (cell === "3") {
      return <LevelDifficulty level="Hard" />;
    } else if (cell === "4") {
      return <LevelDifficulty level="Very hard" />;
    }

    return <span>$ {cell} </span>;
  }

  function allowedForUserTypeFormatter(
    cell: {} | null | undefined,
    isInnerFunction: boolean
  ) {
    if (isSmallerDevice && isInnerFunction) return null;

    const isFreeUser =
      props.userType === "FREE" || props.userType === undefined;

    if (isFreeUser && cell === "FREE") {
      return <StyledImage src={unlock} />;
    } else if (isFreeUser && cell === "PAID") {
      return isSmallerDevice ? <Trigger showSimple={true} /> : <Trigger />;
    } else if (!isFreeUser && props.userType === "PAID") {
      return <StyledImage src={unlock} />;
    }

    return null;
  }

  function executionFormatter(cell: string) {
    const images = {
      STARTED: doubleCheckmark,
      "NOT STARTED": clock,
    };

    const selectedImage = images[cell];

    return (
      <div className="d-flex">
        {selectedImage && <Image src={selectedImage} />}
      </div>
    );
  }

  function titleFormatter(
    cell: any,
    row: { allowedForUserType: any; _id: string; type: string }
  ) {
    const questionAllowedForUser = row.allowedForUserType;
    const isFreeUser = props.userType === "FREE";
    const toPath = `/editor/${row._id}/${row.type}`;

    const renderLink = (content: React.ReactNode) => (
      <StyledNavLink {...{ theme }} to={{ pathname: toPath }}>
        {content}
      </StyledNavLink>
    );

    const renderDefaultLink = () => renderLink(cell);

    if (isFreeUser) {
      if (questionAllowedForUser === "FREE") {
        return renderLink(cell);
      } else if (questionAllowedForUser === "PAID") {
        return (
          <TaskDescription>
            <span>{cell}</span>
          </TaskDescription>
        );
      }
    } else if (props.userType === "PAID") {
      return renderLink(cell);
    }

    return renderDefaultLink();
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row: any) => (
            <StyledTableRow key={row.name}>
              <TableCell style={{ width: 80 }} align="right">
                <div className="d-flex">
                  {executionFormatter(row.execution)}
                  {isSmallerDevice
                    ? allowedForUserTypeFormatter(row.allowedForUserType, false)
                    : null}
                </div>
              </TableCell>
              <TableCell style={{ minWidth: 350 }} component="th" scope="row">
                {titleFormatter(row.title, row)}
              </TableCell>
              <UserTypeTableCell>
                {isSmallerDevice
                  ? null
                  : allowedForUserTypeFormatter(row.allowedForUserType, true)}
              </UserTypeTableCell>
              <TableCell style={{ width: 130 }} align="right">
                {levelFormatter(row.level)}
              </TableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <StyledTablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default MatTable;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.3rem;

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
  background: #ffffff !important;

  td {
    display: table-cell;
  }
`;

const StyledImage = styled(Image)`
  height: 40px;
  margin-left: 1.4rem;
`;

const StyledTablePagination = styled(TablePagination)`
  display: flex;
  width: 100%;
  justify-content: end;
  overflow: hidden !important;

  & .MuiTablePagination-select {
    margin-right: 10px;
    padding: auto;
  }

  & .MuiTablePagination-selectLabel {
    margin: auto;
  }

  & .MuiTablePagination-displayedRows {
    margin: auto;
  }
`;

const TaskDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const UserTypeTableCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
`;

import pageNotFound from "../../../../assets/images/custom-error-page/404.svg";
import { CustomErrorPage } from "../custom-error-page/CustomErrorPage";

export const NotFoundPage = () => {
  return (
    <CustomErrorPage
      title={"Page Not Found..."}
      description={"The page you were looking for does not exist."}
      image={pageNotFound}
      buttonText="Back home"
      redirectLink="/qaskills"
      trnStatus={""}
    />
  );
};

import { fetchData } from "./HttpHelper";
var config = require("./../../../../config");

const requestHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Basic dGVzdDp0ZXN0",
  };
};

const getByType = (type: string) => {
  return fetchData(
    config.getBeServerEndpoint() + `/staticcontent/type/` + type,
    "GET",
    requestHeaders(),
    null
  );
};

const getByTypeAndUserId = (type: string, userId: string) => {
  return fetchData(
    config.getBeServerEndpoint() +
      `/staticcontent/type/` +
      type +
      `/user/` +
      userId,
    "GET",
    requestHeaders(),
    null
  );
};

export { getByType, getByTypeAndUserId };

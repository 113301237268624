import footerBug from "../../../../assets/images/bug-icons/path-bug-footer.svg";
import { ContainedButton } from "../buttons/ContainedButton";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import levelUpIcon from "../../../../assets/images/automation-infra/level-up.svg";

interface EndPageNavigationModel {
  btnText: string;
}

export const EndPageNavigation = (props: EndPageNavigationModel) => {
  const { btnText } = props;

  return (
    <>
      <Icon>
        <img src={footerBug} alt="Footer bug icon" />
      </Icon>
      <BottomText>What are you waiting for? Go ahead and</BottomText>
      <StyledCustomButton>
        <ContainedButton
          text={btnText}
          variant="contained"
          disabled={false}
          icon={levelUpIcon}
          fromPage="landing page button from the bottom."
        />
      </StyledCustomButton>
    </>
  );
};

const Icon = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`;

const BottomText = styled.h4`
  color: #747474;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const StyledCustomButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

import styled from "@emotion/styled";
import { OutlinedInput, Tooltip, Typography, Zoom } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../utils";

import giftBoxIcon from "../../../../assets/images/gift/gift-box.svg";
import { getPlatformEndpoint } from "../../../../config";
import { OutlinedButton } from "../../../shared/components/buttons/OutlinedButton";
import LoadingPage from "../../../shared/components/LoadingPage";

type FormValues = {
  voucherCode: string;
};

interface CouponModel {
  isDisabled: boolean;
}

export const Coupon = (props: CouponModel) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isDisabled } = props;

  let navigate = useNavigate();
  const url = "/activate-voucher";
  const tooltipMessage =
    "You need to agree to our Terms and Conditions and Privacy Policy.";

  let currentUser = JSON.parse(getUser() as string);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      voucherCode: "",
    },
  });

  const handleFormSubmit = (form: FormValues) => {
    const message = "Voucher has been activated. BR, QASkills Team";

    setIsLoading(true);
    const promise = axios
      .post(
        getPlatformEndpoint() + url,
        {
          code: form.voucherCode,
          userId: currentUser._id,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(() => {
        setIsLoading(false);
        reset();
        navigate("/roadmap-and-progress");
      })
      .catch((error: any) => {
        setIsLoading(false);
        reset();
        if (error?.message) {
          if (error.message === "Request failed with status code 404") {
            throw new Error("Voucher code is not valid.");
          }
        }
      });

    toast.promise(promise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error.message,
    });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Card>
      <StyledImg src={giftBoxIcon} alt="automation" height="70" />
      <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
        <FieldTitle variant="caption">Voucher code:</FieldTitle>
        <FieldWrapper>
          <StyledField
            {...register("voucherCode", {
              required: true,
            })}
          />
          {errors.voucherCode && !errors.voucherCode?.message && (
            <ErrorMessage>Voucher code is required.</ErrorMessage>
          )}
          {errors.voucherCode?.message && (
            <ErrorMessage>{errors.voucherCode?.message}</ErrorMessage>
          )}
        </FieldWrapper>

        <Tooltip
          title={isDisabled ? tooltipMessage : ""}
          TransitionComponent={Zoom}
          arrow={true}
        >
          <span>
            <ButtonWrapper>
              <OutlinedButton
                text="Activate"
                disabled={isDisabled}
                icon={giftBoxIcon}
                variant="outlined"
                type="submit"
                redirectLink={undefined}
                color="#000000"
                triggerAnimation={false}
              />
            </ButtonWrapper>
          </span>
        </Tooltip>
        <Circle1></Circle1>
        <Circle2></Circle2>
      </StyledForm>
    </Card>
  );
};

const Card = styled.div`
  background-image: linear-gradient(
    to top right,
    ${(props: any) => props.theme.palette.primary.main},
    ${(props: any) => props.theme.palette.secondary.main}
  );
  height: 200px;
  width: calc(100% - 50px);
  position: relative;
  display: flex;
  margin: auto;
  padding: 1rem 2rem;
  top: 1rem;
  border-radius: 20px;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: calc(100% - 50px);
    padding: 0.5rem;
  }

  @media (max-width: 900px) {
    height: 230px !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-left: 3rem;
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 600px) {
    width: 100%;
    zoom: 0.8;
  }
  zoom: 0.9;
`;

const Circle1 = styled.div`
  background: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
`;

const Circle2 = styled.div`
  background: #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const FieldTitle = styled(Typography)`
  width: 55%;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
    text-align: center;
  }
`;

const StyledField = styled(OutlinedInput)`
  color: black;
  background: #ffffff;
  line-height: 1rem;
  width: 100%;

  & .MuiOutlinedInput-root {
    background: #ffffff;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 5px #dcdcdc;
  }

  & .MuiOutlinedInput-input {
    font-size: 1.2rem;
  }

  @media (max-width: 900px) {
    margin-bottom: 2rem;
  }
`;

const FieldWrapper = styled.div`
  width: calc(100% - 30px);
`;

const ErrorMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  @media (max-width: 900px) {
    display: inline-table;
    bottom: 77px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: left;
  }
  color: ${(props: any) => props.theme.palette.error.main};
  font-size: 16px;
`;

const StyledImg = styled.img`
  height: 80%;
  opacity: 0.2;
  position: absolute;
  left: 80%;
  transform: rotate(10deg);

  @media (max-width: 800px) {
    height: 30%;
    left: 80%;
    top: 60%;
  }
`;

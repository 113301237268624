import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { BreadcrumbNavigation } from "../../../shared/components/breadcrumb-navigation/Breadcrumb-Navigation";
import MatTable from "../../../shared/components/MatTable";
import { getByTypeAndUserId } from "../../../shared/services/api/LearningApi";
import { getUser } from "../../../utils";
import { Mixpanel } from "../../../../../Mixpanel";
import { TaskDto } from "../../../shared/dtos/task.dto";
import {
  HeadingTypeEnum,
  TitleAndTaskCounter,
} from "../../../shared/components/title-and-task-counter/TitleAndTaskCounter";

export const CodingSkills = () => {
  const [user, setUser] = useState({
    username: "",
    type: "",
    _id: "",
  });
  const [jsRows, setJsRows] = useState<TaskDto[]>([]);
  const [pyRows, setPyRows] = useState<TaskDto[]>([]);

  let userInfo = getUser();
  let currentUser = JSON.parse(userInfo as string);

  useEffect(() => {
    setUser({
      ...user,
      username: currentUser?.username,
      type: currentUser?.type,
      _id: currentUser?._id,
    });
  }, []);

  let userId = currentUser?._id;

  useEffect(() => {
    getByTypeAndUserId("javascript", userId).then((javascript: TaskDto[]) => {
      console.log("Get Javascript from API: " + JSON.stringify(javascript));
      setJsRows(javascript);
    });

    getByTypeAndUserId("python", userId).then((python: TaskDto[]) => {
      console.log("Get Python from API: " + JSON.stringify(python));
      setPyRows(python);
    });
  }, [userId]);

  return (
    <Container>
      <Title>Coding Skills</Title>
      <BreadcrumbNavigation title="Coding Skills" />

      <AccordionWrapper>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() => {
                Mixpanel.track("JavaScript content opened - ");
              }}
            >
              <TitleAndTaskCounter
                tasks1={jsRows}
                title="JavaScript"
                headingType={HeadingTypeEnum.H2}
              ></TitleAndTaskCounter>
            </Accordion.Header>
            <Accordion.Body>
              {jsRows && (
                <MatTable rows={jsRows} userType={user?.type}></MatTable>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              onClick={() => {
                Mixpanel.track("Python content opened - ");
              }}
            >
              <TitleAndTaskCounter
                tasks1={pyRows}
                title="Python"
                headingType={HeadingTypeEnum.H2}
              ></TitleAndTaskCounter>
            </Accordion.Header>
            <Accordion.Body>
              {pyRows && (
                <MatTable rows={pyRows} userType={user?.type}></MatTable>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </AccordionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 10rem;
  padding-right: 10rem;
  min-height: calc(100% - 15rem);
  height: auto;
  color: #000000;

  @media (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 970px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  padding-top: 3rem;
`;

const AccordionWrapper = styled.div`
  height: 100%;
`;

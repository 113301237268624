import { Container, Row } from "react-bootstrap";

import { Trigger } from "../../../controls/Trigger";
import { ContentDto } from "../../dtos/content.dto";
import { InnerContent } from "./InnerContent";

interface MarkdownGridModel {
  userType: string;
  staticcontent: ContentDto;
  userId: string;
  setTaskSubmissionResponse: (status: string) => void;
}

export const MarkdownGrid = (props: MarkdownGridModel) => {
  const { userType, staticcontent, userId, setTaskSubmissionResponse } = props;

  return (
    <Container fluid>
      <Row>
        {userType === "FREE" && staticcontent.allowedForUserType === "FREE" && (
          <InnerContent
            setTaskSubmissionResponse={setTaskSubmissionResponse}
            userId={userId}
            staticcontent={staticcontent}
          />
        )}

        {userType === "FREE" && staticcontent.allowedForUserType === "PAID" && (
          <Trigger />
        )}

        {userType === "PAID" && staticcontent.allowedForUserType === "FREE" && (
          <InnerContent
            setTaskSubmissionResponse={setTaskSubmissionResponse}
            userId={userId}
            staticcontent={staticcontent}
          />
        )}

        {userType === "PAID" && staticcontent.allowedForUserType === "PAID" && (
          <InnerContent
            setTaskSubmissionResponse={setTaskSubmissionResponse}
            userId={userId}
            staticcontent={staticcontent}
          />
        )}
      </Row>
    </Container>
  );
};

import { Breadcrumb } from "react-bootstrap";
import styled from "@emotion/styled";

interface BreadcrumbNavigationModel {
  title: string;
  taskPage?: string;
  taskUrl?: string;
}

export const BreadcrumbNavigation = (props: BreadcrumbNavigationModel) => {
  const { title, taskPage, taskUrl } = props;
  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item href="/qaskills">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/roadmap-and-progress">
        Roadmap & Progress
      </Breadcrumb.Item>
      {taskPage && taskUrl ? (
        <Breadcrumb.Item href={taskUrl}>{taskPage}</Breadcrumb.Item>
      ) : (
        ""
      )}
      <Breadcrumb.Item active>{title}</Breadcrumb.Item>
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  display: flex;
  justify-content: center;

  > ol {
    display: flex;
    justify-content: center;
  }
`;

import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { LoginPopup } from "../../login/LoginPopup";
import { Button } from "react-bootstrap";
import mostPopularIcon from "../../../assets/images/most-popular-icon.png";

interface PurchaseCardModel {
  activePackage: string | undefined;
  accessPeriod: string;
  amount: number | string;
  amountRsd: number | string;
  discount: number | string;
  currency: string;
  items: string[];
  color?: string;
  icon: any;
  packageType: string;
  setSelectedPackage: (packageType: string) => void;
}

export const PurchaseCard = (props: PurchaseCardModel) => {
  const {
    activePackage,
    accessPeriod,
    items,
    amount,
    amountRsd,
    discount,
    currency,
    icon,
    color,
    packageType,
    setSelectedPackage,
  } = props;

  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);

  const theme = useTheme();

  const shouldDisableButton = () => {
    if (
      activePackage === "YEARLY_PACKAGE_AND_FINAL_TEST" ||
      (activePackage === "YEARLY_PACKAGE" &&
        (packageType === "MONTHLY_PACKAGE" ||
          packageType === "YEARLY_PACKAGE")) ||
      (activePackage === "MONTHLY_PACKAGE" && packageType === "MONTHLY_PACKAGE")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <LoginPopup isShowLogin={isShowLogin} setIsShowLogin={setIsShowLogin} />

      {packageType === "YEARLY_PACKAGE" ? (
        <MostPopular>
          <img width="120" height="150" src={mostPopularIcon} alt="" />
        </MostPopular>
      ) : (
        ""
      )}

      <AccessPeriod color={color}>
        <Typography variant="body2">{accessPeriod}</Typography>
      </AccessPeriod>
      <AnimationOnScroll animateOnce animateIn="animate__heartBeat">
        <Price>
          <Typography variant="h4" className="d-flex">
            {discount ? amount : discount}
            <Currency variant="h4">{currency}</Currency>
          </Typography>
          <CrossedValue variant="overline">
            {discount ? <s>{" " + Number(discount)}</s> : ""}
            <Currency variant="overline">{currency}</Currency>
          </CrossedValue>
        </Price>
        {amountRsd ? (
          <Typography align="center" variant="body2">
            ({amountRsd} RSD)
          </Typography>
        ) : (
          ""
        )}
      </AnimationOnScroll>
      <Line />
      <AnimationOnScroll animateOnce animateIn="animate__slideInUp">
        <Items>
          {items.map((item: string, index: number) => (
            <div key={index}>
              <img src={icon} alt="Plus icon" />
              <StyledItems variant="caption">{item}</StyledItems>
            </div>
          ))}
        </Items>
        <BottomLine />
      </AnimationOnScroll>
      <AnimationOnScroll animateOnce animateIn="animate__heartBeat" offset={50}>
        <a href="#purchase-part">
          <PurchaseButton
            color={color}
            theme={theme}
            disabled={shouldDisableButton()}
            onClick={() => setSelectedPackage(packageType)}
          >
            <Typography variant="h6">Select</Typography>
          </PurchaseButton>
        </a>
      </AnimationOnScroll>
    </Container>
  );
};

export const Container = styled.div`
  background: #ffffff;
  width: 420px;

  @media (max-width: 1600px) {
    width: 450px;
  }

  position: relative;
  border-radius: 10px;
`;

export const AccessPeriod = styled.div`
  background: ${(props: any) =>
    props.color === "#6C2DC7"
      ? "#6C2DC7"
      : props.color === "primary"
      ? props.theme.palette.primary.main
      : props.theme.palette.secondary.main};
  color: #ffffff;
  width: 85%;
  height: 70px;
  text-transform: uppercase;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-weight: bold;
  margin-top: 1rem;

  > h4,
  p {
    font-weight: bold;
  }
`;

const Line = styled.hr`
  color: #747474;
  margin: 1.5rem 4rem 0;
`;

const BottomLine = styled.hr`
  color: #747474;
  margin: 5rem 4rem;
`;

const Currency = styled(Typography)``;

const Items = styled.div`
  color: #747474;
  padding: 2rem 2rem 0 2rem;
  line-height: 1.3rem;
  margin-bottom: 5rem;
  height: 220px;
  justify-content: center;
  display: grid;

  > div {
    display: flex;

    :not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  @media (max-width: 767px) {
    padding: 2rem 1rem 0 1rem;
  }
`;

const StyledItems = styled(Typography)`
  color: #747474;
  padding-left: 10px;
`;

const PurchaseButton = styled(Button)`
  position: absolute;
  bottom: -45px;
  width: 65%;
  height: 90px;
  border-radius: 10px;
  border: none !important;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  left: 50%;
  cursor: pointer;
  color: ${(props: any) =>
    props.color === "#6C2DC7"
      ? "#6C2DC7"
      : props.color === "primary"
      ? props.theme.palette.primary.main
      : props.theme.palette.secondary.main};
  background: #ffffff;
  border: 4px solid
    ${(props: any) =>
      props.color === "#6C2DC7"
        ? "#6C2DC7"
        : props.color === "primary"
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main} !important;
  :hover {
    box-shadow: 0 0 25px #ababab;
    background: ${(props: any) =>
      props.color === "#6C2DC7"
        ? "#6C2DC7"
        : props.color === "primary"
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main};
    color: #ffffff;
  }

  :disabled {
    background: gray !important;
  }
`;

const CrossedValue = styled(Typography)`
  position: relative;
  display: inline-block;
  ::before,
  ::after {
    content: "";
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
  }
  ::before {
    border-bottom: 1px solid #000000;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
  }
  ::after {
    border-bottom: 1px solid #000000;
    -webkit-transform: skewY(10deg);
    transform: skewY(10deg);
  }
`;

const MostPopular = styled.div`
  position: absolute;
  right: -40px;
  top: -20px;

  @media (max-width: 490px) {
    right: 0;
  }

  @media (max-width: 440px) {
    zoom: 0.8;
  }
`;

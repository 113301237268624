import { generateCommonStepOptions } from "../commonStepOptions";
import { ShepherdOptionsWithType } from "react-shepherd";

export const steps: ShepherdOptionsWithType[] = [
  {
    ...generateCommonStepOptions("#start", "bottom"),
    title: `<h5>Welcome to the QASkills Platform</h5>`,
    text: [
      `<b>
          Start your learning journey following our detailed and well-structured
          sections. The main idea is to prepare you for each part of the
          interview process. <br />
          Click the below button to start your tour.
        </b>`,
    ],
  },
  {
    ...generateCommonStepOptions("#profile-step", "bottom"),
    id: "#profile-step",
    title: `<h5>
      <b>In the Profile info section:</b>
                 </h5>`,
    text: [
      `<h6>
                    Explore the Profile Info section to view your profile image,
                     account type, active package, and an interesting quote.
                   </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#progress-step", "bottom"),
    title: `<h5>
      <h5>
              <b>In the Progress section:</b>
             </h5>`,
    text: [
      `<h6>
                     You can see the status of your submitted theoretical and practical
                     tasks in the Progress section.
                   </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#roadmap-step", "top"),
    title: `<h5>
      <b>Your roadmap:</b>
    </h5>`,
    text: [
      `<h6>
        Your roadmap is a step-by-step guide for beginners, ensuring the
        best learning experience.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#fundamentals-and-theory-step", "bottom"),
    title: `<h5>
      <b>In the Fundamentals and Theory section:</b>
    </h5>`,
    text: [
      `<h6>
        You will start by learning basic theoretical concepts about
        testing and quickly check your knowledge with a quiz.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#test-case-and-bug-step", "bottom"),
    title: `<h5>
      <b>In the Test Case and Bug Tasks section:</b>
    </h5>`,
    text: [
      `<h6>
        You will check and improve your knowledge about the Test Cases and
        Bugs, by writing some of them based on the provided templates.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#development-methodologies-step", "bottom"),
    title: `<h5>
      <b>In the Development Methodologies section:</b>
    </h5>`,
    text: [
      `<h6>
        You will learn the most important principles of agile methodology
        used by most companies.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#coding-skills-step", "bottom"),
    title: `<h5>
      <b>In the Coding Skills section:</b>
    </h5>`,
    text: [
      `<h6>
        You will start your programming journey on our platform, learn
        step-by-step basic concepts, and get hands-on experience writing
        your very first code.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#automation-tasks-step", "bottom"),
    title: `<h5>
      <b>In the Automation Tasks section:</b>
    </h5>`,
    text: [
      `<h6>
        You will start your automation testing journey and start using
        your programming experience to write your very first automation
        test.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#database-tasks-step", "bottom"),
    title: `<h5>
      <b>In the Database Tasks section:</b>
    </h5>`,
    text: [
      `<h6>
        You will learn the basic commands necessary for your future
        position for interaction with the database.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#linux-interaction-step", "bottom"),
    title: `<h5>
      <b>In the Linux interaction tasks section:</b>
    </h5>`,
    text: [
      `<h6>
        You will learn the basic commands for checking server data without
        using a graphical interface, but instead type commands via the
        terminal.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions(
      "#automationinfra-fundamentals-step",
      "bottom"
    ),
    title: `<h5>
      <b>In the AutomationInfra Fundamentals section:</b>
    </h5>`,
    text: [
      `<h6>
        You will learn about the most important concepts and tools used
        for setting up the necessary infrastructure for the execution of
        automation tests via continuous integration used by all companies.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#automationinfra-tasks-step", "bottom"),
    title: `<h5>
      <b>In the AutomationInfra tasks section:</b></h5>`,
    text: [
      `<h6>
        You will learn, based on real-world examples, how software testers
        should discuss with developers about current infrastructure, and
        get important information to understand and/or implement the best
        automation testing infrastructure.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#soft-skills-step", "bottom"),
    title: `<h5>
      <b>In the Soft Skills section:</b></h5>`,
    text: [
      `<h6>
        You will learn best practices for communication in your future
        team.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#cv-step", "bottom"),
    title: `<h5>
      <b>In the CV section:</b></h5>`,
    text: [
      `<h6>
        You will learn the most important parts of writing your CV so that
        you can improve your current one or create one based on a
        template.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#certificate-step", "bottom"),
    title: `<h5>
      <b>In the Certificate section:</b></h5>`,
    text: [
      `<h6>
        After submitting all tasks, you will receive a Certificate of
        Completion and a button to directly add this certificate to your
        LinkedIn profile.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#final-test-step", "bottom"),
    title: `<h5>
      <b>In the Final Test section:</b></h5>`,
    text: [
      `<h6>
        You will attend a real video interview, making you familiar with
        the interview process and checking whether you are ready for the
        official one.
      </h6>`,
    ],
  },
  {
    ...generateCommonStepOptions("#end", "center"),
    title: `<h5>
      <b>Conclusion Time:</b></h5>`,
    text: [
      `<h6>
        You will have more confidence to apply to as many junior positions
        as possible in Software Testing. You will be prepared for D-Day,
        so explain to them what you have been doing in previous steps.
        <br />
        <br />
        Thank you for your attention. Have a pleasant learning day. <br />
        Now start with learning, and as Linus Torvalds says: <br />
        ‘Talk is cheap. Show me the code.’
      </h6>`,
    ],
  },
];

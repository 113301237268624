import { useLocation } from "react-router-dom";

export const PaymentForm = () => {
  const { state } = useLocation();

  const paymentFormUrl = state as string;
  window.location.replace(paymentFormUrl);

  return <></>;
};

import styled from "@emotion/styled";
import bugsTeam from "../../assets/images/bug-icons/bugs-team.svg";
import personPlaceholder from "../../assets/images/person-placeholder.svg";
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from "@mui/material";
import { RoadmapCard } from "./roadmap-card/RoadmapCard";
import { VerticalLine } from "../shared/components/VerticalLine";
import { CertificateCard } from "./certificate-card/CertificateCard";
import { DeleteAccount } from "./delete-account/DeleteAccount";
import { SimpleRoadmapLayout } from "./simple-roadmap-layout/SimpleRoadmapLayout";
import { useEffect, useState } from "react";
import { getUser, setNewUser } from "../utils";
import { AccountType } from "../utils/accountType";
import { NavLink, useNavigate } from "react-router-dom";
import { Progress } from "./Progress";
import {
  getStatisticsStaticContentSubmittedByUserId,
  getStatisticsSubmittedByUserId,
} from "../shared/services/api/TaskSubmissionApi";
import axios from "axios";
import { getBeServerEndpoint, getPlatformEndpoint } from "../../config";
import { toast } from "react-hot-toast";
import { formatPackageType, TransactionsDialog } from "./TransactionsDialog";
import LoadingPage from "../shared/components/LoadingPage";
import { AnimationOnScroll } from "react-animation-on-scroll";
import InfoIcon from "@mui/icons-material/Info";
import { FinalTestAndInterviewCard } from "./FinalTestAndInterviewCard";
import rocketImg from "../../assets/images/rocket.svg";
import { useShepherdTour } from "react-shepherd";
import { tourOptions } from "../utils/commonStepOptions";
import { steps } from "../utils/tour-utils/roadmap-tour-steps";

export const PackageType = {
  freeAccess: "FREE",
  monthlyPackage: "MONTHLY_PACKAGE",
  yearlyPackage: "YEARLY_PACKAGE",
  yearlyPackageAndFinal: "YEARLY_PACKAGE_AND_FINAL_TEST",
};

export interface TransactionsDto {
  amount: number;
  packageType: string;
  transactionId: string;
  transactionDate: string;
  status: string;
  activePackage: string;
}

interface QuoteDto {
  quote: string;
  author: string;
}

export interface UserInfoDto {
  activePackage?: string;
  googleId: string;
  name: string;
  paidAccountEndDate: string;
  type: string;
  username: string;
  _id: string;
}

export const RoadmapAndProgress = () => {
  const [user, setUser] = useState({
    name: "",
    type: "",
    paidAccountEndDate: "",
    userImage: "",
    id: "",
    activePackage: "",
  });

  const [taskSubmmisionStatistic, setTaskSubmmisionStatistic] = useState({
    tasksNumber: 0,
    submittedTasks: 0,
    percentageDone: 0,
  });

  const [
    taskSubmmisionStatisticStaticContent,
    setTaskSubmmisionStatisticStaticContent,
  ] = useState({
    tasksNumber: 0,
    submittedTasks: 0,
    percentageDone: 0,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loadedOnce, setLoadedOnce] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<TransactionsDto[]>([]);
  const [quote, setQuote] = useState<QuoteDto>({
    quote: "",
    author: "",
  });

  const currentUser = getUser() as string;
  let userDetails = JSON.parse(currentUser);

  let userId = "";
  let userName = "";

  if (userDetails) {
    userId = userDetails?._id;
    userName = userDetails?.name.split(" ")[0];
  }

  // const [{ run, steps }, setState] = useSetState<State>({
  //   run: false,
  //   steps: [
  //     {
  //       content: (
  //         <>
  //           <b>
  //             <h5>{userName}, welcome to the QASkills Platform</h5>
  //             Start your learning journey following our detailed and
  //             well-structured sections. The main idea is to prepare you for each
  //             part of the interview process. <br />
  //             Click the below button to start your tour.
  //           </b>
  //         </>
  //       ),
  //       placement: "center",
  //       target: "body",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Profile info section:</b>
  //           </h5>
  //           <h6>
  //             Explore the Profile Info section to view your profile image,
  //             account type, active package, and an interesting quote.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#profile-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Progress section:</b>
  //           </h5>
  //           <h6>
  //             You can see the status of your submitted theoretical and practical
  //             tasks in the Progress section.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#progress-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>Your roadmap:</b>
  //           </h5>
  //           <h6>
  //             Your roadmap is a step-by-step guide for beginners, ensuring the
  //             best learning experience.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#roadmap-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Fundamentals and Theory section:</b>
  //           </h5>
  //           <h6>
  //             You will start by learning basic theoretical concepts about
  //             testing and quickly check your knowledge with a quiz.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#fundamentals-and-theory-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Test Case and Bug Tasks section:</b>
  //           </h5>
  //           <h6>
  //             You will check and improve your knowledge about the Test Cases and
  //             Bugs, by writing some of them based on the provided templates.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#test-case-and-bug-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Development Methodologies section:</b>
  //           </h5>
  //           <h6>
  //             You will learn the most important principles of agile methodology
  //             used by most companies.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#development-methodologies-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Coding Skills section:</b>
  //           </h5>
  //           <h6>
  //             You will start your programming journey on our platform, learn
  //             step-by-step basic concepts, and get hands-on experience writing
  //             your very first code.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#coding-skills-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Automation Tasks section:</b>
  //           </h5>
  //           <h6>
  //             You will start your automation testing journey and start using
  //             your programming experience to write your very first automation
  //             test.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#automation-tasks-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Database Tasks section:</b>
  //           </h5>
  //           <h6>
  //             you will learn the basic commands necessary for your future
  //             position for interaction with the database.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#database-tasks-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Linux interaction tasks section:</b>
  //           </h5>
  //           <h6>
  //             You will learn the basic commands for checking server data without
  //             using a graphical interface, but instead type commands via the
  //             terminal.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#linux-interaction-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the AutomationInfra Fundamentals section:</b>
  //           </h5>
  //           <h6>
  //             You will learn about the most important concepts and tools used
  //             for setting up the necessary infrastructure for the execution of
  //             automation tests via continuous integration used by all companies.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#automationinfra-fundamentals-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the AutomationInfra tasks section:</b>
  //           </h5>
  //           <h6>
  //             You will learn, based on real-world examples, how software testers
  //             should discuss with developers about current infrastructure, and
  //             get important information to understand and/or implement the best
  //             automation testing infrastructure.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#automationinfra-tasks-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Soft Skills section:</b>
  //           </h5>
  //           <h6>
  //             You will learn best practices for communication in your future
  //             team.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#soft-skills-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the CV section:</b>
  //           </h5>
  //           <h6>
  //             You will learn the most important parts of writing your CV so that
  //             you can improve your current one or create one based on a
  //             template.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#cv-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Certificate section:</b>
  //           </h5>
  //           <h6>
  //             After submitting all tasks, you will receive a Certificate of
  //             Completion and a button to directly add this certificate to your
  //             LinkedIn profile.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#certificate-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>In the Final Test section:</b>
  //           </h5>
  //           <h6>
  //             You will attend a real video interview, making you familiar with
  //             the interview process and checking whether you are ready for the
  //             official one.
  //           </h6>
  //         </>
  //       ),
  //       floaterProps: {
  //         disableAnimation: true,
  //       },
  //       spotlightPadding: 10,
  //       target: "#final-test-step",
  //     },
  //     {
  //       content: (
  //         <>
  //           <h5>
  //             <b>Conclusion Time:</b>
  //           </h5>
  //           <h6>
  //             You will have more confidence to apply to as many junior positions
  //             as possible in Software Testing. You will be prepared for D-Day,
  //             so explain to them what you have been doing in previous steps.
  //             <br />
  //             <br />
  //             Thank you for your attention. Have a pleasant learning day. <br />
  //             Now start with learning, and as Linus Torvalds says: <br />
  //             ‘Talk is cheap. Show me the code.’
  //           </h6>
  //         </>
  //       ),
  //       placement: "center",
  //       target: "body",
  //     },
  //   ],
  // });

  const transactionMessage =
    "The status will be updated in a few minutes after you make a purchase. Please refresh the website or check the status of your packages.";

  useEffect(() => {
    if (userId) {
      getStatisticsSubmittedByUserId(userId).then(
        (taskSubmmisionStatistic: any) => {
          console.log(
            "TaskSubmissionApi.getSubmittedByUserId: " +
              JSON.stringify(taskSubmmisionStatistic)
          );

          setTaskSubmmisionStatistic({
            ...taskSubmmisionStatistic,
            taskSubmmisionStatistic,
          });
        }
      );

      getStatisticsStaticContentSubmittedByUserId(userId).then(
        (taskSubmmisionStatisticStaticContent: any) => {
          console.log(
            "TaskSubmissionApi.getStatisticsStaticContentSubmittedByUserId: " +
              JSON.stringify(taskSubmmisionStatisticStaticContent)
          );

          setTaskSubmmisionStatisticStaticContent({
            ...taskSubmmisionStatisticStaticContent,
            taskSubmmisionStatisticStaticContent,
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (userId) {
      getUserInfo(userId);
    }
  }, [userId, user?.activePackage, user?.type]);

  useEffect(() => {
    getProgrammingQuote();
  }, []);

  const getListOfAllTransactions = (userId: string) => {
    setIsLoading(true);
    const transactionsPromise = axios
      .get<TransactionsDto[]>(
        getPlatformEndpoint() + `/user/${userId}/transactions`,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setLoadedOnce(true);
        setTransactions(response.data);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message = "Transactions fetched successfully.";
    toast.promise(transactionsPromise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  const getProgrammingQuote = () => {
    setIsLoading(true);
    const quotePromise = axios
      .get<QuoteDto>(getBeServerEndpoint() + `/quotes`, {
        headers: {
          Authorization: "Basic dGVzdDp0ZXN0",
          "content-type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setQuote(response.data);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message = "Quote is fetched successfully.";
    toast.promise(quotePromise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  const getUserInfo = (userId: string) => {
    setIsLoading(true);
    const userPromise = axios
      .get<UserInfoDto>(getBeServerEndpoint() + `/users/${userId}`, {
        headers: {
          Authorization: "Basic dGVzdDp0ZXN0",
          "content-type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        const newUserDetails = response.data;
        setUser({
          ...userDetails,
          username: newUserDetails.name,
          type: newUserDetails.type,
          paidAccountEndDate: newUserDetails.paidAccountEndDate,
          activePackage: newUserDetails?.activePackage,
          userImage: userDetails.imageUrl,
          id: newUserDetails?._id,
        });
        setNewUser({
          ...userDetails,
          username: newUserDetails.name,
          type: newUserDetails.type,
          paidAccountEndDate: newUserDetails.paidAccountEndDate,
          activePackage: newUserDetails?.activePackage,
          userImage: userDetails.imageUrl,
          id: newUserDetails?._id,
        });
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log(error);
        if (error?.message) {
          throw new Error(error);
        }
      });

    const message = "User info success";
    toast.promise(userPromise, {
      loading: "Loading, please wait...",
      success: message,
      error: (error) => error?.message,
    });
  };

  let notCompletedCourse =
    taskSubmmisionStatistic.percentageDone !== 100 &&
    taskSubmmisionStatisticStaticContent.percentageDone !== 100;

  let navigate = useNavigate();

  const isSmallerDevice = useMediaQuery("(min-width:1250px)");

  const getExpirationDate = () => {
    // let date = new Date(user?.paidAccountEndDate);
    return user?.paidAccountEndDate;
  };

  const getActivePackage = () => {
    return user?.activePackage && user?.activePackage !== "" ? (
      checkForAdditionalMessage(user.activePackage)
    ) : (
      <p>
        &nbsp;No package has yet been purchased, check our packages{" "}
        <b>
          <PackagesLink to="/products-price">here</PackagesLink>
        </b>
        .
        <Tooltip
          title={transactionMessage}
          TransitionComponent={Zoom}
          arrow={true}
        >
          <StyledIconButton>
            <InfoIcon />
          </StyledIconButton>
        </Tooltip>
      </p>
    );
  };

  const checkForAdditionalMessage = (activePackage: string) => {
    if (activePackage === PackageType.monthlyPackage) {
      return (
        <p>
          &nbsp;{formatPackageType(activePackage)}
          &nbsp;, you can check out our yearly package{" "}
          <b>
            <PackagesLink to="/products-price">here</PackagesLink>
          </b>
          .
          <Tooltip
            title={transactionMessage}
            TransitionComponent={Zoom}
            arrow={true}
          >
            <StyledIconButton>
              <InfoIcon />
            </StyledIconButton>
          </Tooltip>
        </p>
      );
    } else if (activePackage === PackageType.yearlyPackage) {
      return (
        <p>
          &nbsp;{formatPackageType(activePackage)}
          &nbsp;, you can check out our yearly package with final test{" "}
          <b>
            <PackagesLink to="/products-price">here</PackagesLink>
          </b>
          .
          <Tooltip
            title={transactionMessage}
            TransitionComponent={Zoom}
            arrow={true}
          >
            <StyledIconButton>
              <InfoIcon />
            </StyledIconButton>
          </Tooltip>
        </p>
      );
    } else {
      return formatPackageType(activePackage);
    }
  };

  const handleDialogState = () => {
    if (!transactions.length && !loadedOnce) {
      getListOfAllTransactions(user?.id);
    }
    setOpen(true);
  };

  const roadmapTour = useShepherdTour({ tourOptions, steps: steps });

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <ImgContainer
        src={bugsTeam}
        alt="Bugs team icon"
        width="1428"
        height="740"
      />
      <div className="d-flex flex-column">
        <StyledGuide>
          <Tooltip title="Coming soon" TransitionComponent={Zoom} arrow={true}>
            <span>
              <GuideButton onClick={() => roadmapTour.start()} disabled={true}>
                QASkills Experience Tour
                <StyledRocket src={rocketImg} height="40" />
              </GuideButton>
            </span>
          </Tooltip>
        </StyledGuide>
        <div>
          <QuoteContainer>
            <Typography variant="caption">
              "{quote.quote}" {quote.author !== "" ? " - " + quote.author : ""}
            </Typography>
          </QuoteContainer>
        </div>
      </div>

      <PersonPlaceholderContainer>
        <img
          src={user?.userImage ? user?.userImage : personPlaceholder}
          alt="Person placeholder"
          width="96"
          height="146"
        />
      </PersonPlaceholderContainer>
      <NameAndSurname variant="body1">{user?.name}</NameAndSurname>
      <UserInfo variant="caption">
        Account type: {user?.type}{" "}
        <Tooltip
          title={transactionMessage}
          TransitionComponent={Zoom}
          arrow={true}
        >
          <StyledIconButton>
            <InfoIcon />
          </StyledIconButton>
        </Tooltip>
      </UserInfo>
      {user?.type === AccountType.paid ? (
        <div>
          <UserInfo variant="caption">
            Access until: {getExpirationDate()}
          </UserInfo>
          <UserInfo variant="caption">
            Active package: {getActivePackage()}
          </UserInfo>
          <Transactions onClick={handleDialogState}>
            <Typography variant="caption">Your transactions</Typography>
          </Transactions>
        </div>
      ) : (
        ""
      )}
      <ProgressWrapper>
        <Progress />
      </ProgressWrapper>
      <VerticalLine />
      <RoadmapTitle id="roadmap-step">Your roadmap</RoadmapTitle>
      <AnimationOnScroll animateOnce animateIn="animate__bounceIn">
        <StyledHeading4 className="text-center">
          Our step-by-step guide for learning and improving your skills and
          earning your certificate!
        </StyledHeading4>
      </AnimationOnScroll>
      <RoadmapCardContainer>
        {isSmallerDevice ? (
          <Column>
            <Row>
              <Card
                id="fundamentals-and-theory-step"
                onClick={() => navigate("/qaskills-fundamentals")}
              >
                <RoadmapCard
                  title="QASkills: Fundamentals and theory"
                  completedTasks={24}
                  totalTasks={24}
                  description={
                    "Regardless of the seniority, everyone needs to know the fundamental processes and rules when testing software. It is always best to start learning the basic concepts in testing."
                  }
                  lesson=""
                />
              </Card>
              <StyledHr />
              <Card
                id="test-case-and-bug-step"
                onClick={() => navigate("/test-case-tasks")}
              >
                <RoadmapCard
                  title="QASkills: Test Case and Bug Tasks"
                  completedTasks={0}
                  totalTasks={24}
                  description={
                    "Writing test cases is normally the responsibility of someone on the software development team, testing team, or quality assurance team. It’s typically preferred that a professional who was not involved with writing the code should write the test cases since they have a fresh perspective."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
            </Row>
            <Row>
              <StyledHorizontalLine rowNumber={2} />
            </Row>
            <Row>
              <Card
                id="coding-skills-step"
                onClick={() => navigate("/coding-skills")}
              >
                <RoadmapCard
                  title="QASkills: Coding Skills"
                  completedTasks={10}
                  totalTasks={24}
                  description={
                    "Before you start writing automatic tests, it is necessary to learn the basics of programming. It is best to start with simple examples, such as the examples in this section."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
              <StyledHr />
              <Card
                id="development-methodologies-step"
                onClick={() => navigate("/development-methodologies")}
              >
                <RoadmapCard
                  title="QASkills: Development methodologies"
                  completedTasks={24}
                  totalTasks={24}
                  description={
                    "All teams work on the principle of some agile methodology, and one of the most widespread is called Scrum. Therefore, a QA engineer must know the fundamental processes of the mentioned method."
                  }
                  lesson=""
                />
              </Card>
            </Row>
            <Row>
              <StyledHorizontalLine rowNumber={4} />
            </Row>
            <Row>
              <Card
                id="automation-tasks-step"
                onClick={() => navigate("/automation-tasks")}
              >
                <RoadmapCard
                  title="QASkills: Automation tasks"
                  completedTasks={2}
                  totalTasks={24}
                  description={
                    "After processing the QASkills Fundamentals and Coding Skills sections, we are finally ready to start writing automated tests. This section will cover the writing of automatic Unit tests, Rest API tests, and EndToEnd tests."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
              <StyledHr />
              <Card
                id="database-tasks-step"
                onClick={() => navigate("/database-tasks")}
              >
                <RoadmapCard
                  title="QASkills: Database tasks"
                  completedTasks={0}
                  totalTasks={24}
                  description={
                    "Many applications store their data in an SQL database. Writing SQL sentences is an essential skill for a QA engineer, and some of the basic examples are covered in this section."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
            </Row>
            <Row>
              <StyledHorizontalLine rowNumber={2} />
            </Row>
            <Row>
              <Card
                id="automationinfra-fundamentals-step"
                onClick={() => navigate("/automation-infra-fundamentals")}
              >
                <RoadmapCard
                  title="AutomationInfra: Fundamentals and theory"
                  completedTasks={10}
                  totalTasks={24}
                  description={
                    "Knowing the basic principles, applications, or processes with excellent and proper infrastructure for automatically testing software is necessary. Some of the most commonly used methods or applications are mentioned in the first section."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
              <StyledHr />
              <Card
                id="linux-interaction-step"
                onClick={() => navigate("/linux-interaction-tasks")}
              >
                <RoadmapCard
                  title="QASkills: Linux Interactions Task"
                  completedTasks={0}
                  totalTasks={24}
                  description={
                    "Most servers are using Linux systems and for every QA Engineer is super important to know most used linux commands."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
            </Row>
            <Row>
              <StyledHorizontalLine rowNumber={4} />
            </Row>
            <Row>
              <Card
                id="automationinfra-tasks-step"
                onClick={() => navigate("/automation-infra-tasks")}
              >
                <RoadmapCard
                  title="AutomationInfra: Tasks"
                  completedTasks={2}
                  totalTasks={24}
                  description={
                    "In practical examples of a conversation within a team, we can see the key and most common questions that a QA engineer needs to ask. You can check our samples to get a good look at the whole application, and we suggest setting up an automated testing infrastructure for these applications."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
              <StyledHr />
              <Card
                id="soft-skills-step"
                onClick={() => navigate("/soft-skills-fundamentals")}
              >
                <RoadmapCard
                  title="Other: Soft Skills Fundamentals"
                  completedTasks={0}
                  totalTasks={24}
                  description={
                    "Working in any team requires specific skills which are not technical. It is no different for any team working on software products, and all communication skills are necessary for one QA engineer to work with all team members or users of the application itself."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
            </Row>
            <Row>
              <StyledHorizontalLine rowNumber={2} />
            </Row>
            <Row>
              <Card id="certificate-step">
                <CertificateCard disabled={notCompletedCourse} />
              </Card>
              <StyledHr />
              <Card id="cv-step" onClick={() => navigate("/how-to-write-cv")}>
                <RoadmapCard
                  title="Other: How to write a CV"
                  completedTasks={0}
                  totalTasks={24}
                  description={
                    "For the company to know what skills we have, it is necessary to send a CV. Writing a CV itself is a skill that can be learned or improved, and some of the basic rules are covered in this section."
                  }
                  lesson="Name of the lesson user is currently working on is some practical tasks"
                />
              </Card>
            </Row>
            <Row>
              <FinalCard id="final-test-step">
                <FinalTestAndInterviewCard
                  disabled={
                    notCompletedCourse &&
                    user?.activePackage !== PackageType.yearlyPackageAndFinal
                  }
                  userId={user?.id}
                  setIsLoading={setIsLoading}
                />
              </FinalCard>
            </Row>
          </Column>
        ) : (
          <SimpleRoadmapLayout
            disabled={notCompletedCourse}
            userId={user?.id}
            setIsLoading={setIsLoading}
          />
        )}
      </RoadmapCardContainer>
      {/* <VerticalLine /> */}
      {/* <DeleteAccount /> */}
      {open ? (
        <TransactionsDialog
          title="Your transactions"
          subtitle="Here you can find all your transactions."
          setOpenPopup={setOpen}
          isOpen={open}
          transactions={transactions}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #f6f9fc;
  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 820px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const Card = styled.div`
  width: 44%;
  height: 100%;
  cursor: pointer;
  z-index: 2;

  &:hover {
    box-shadow: 0 0 20px rgb(0, 240, 208);
    transform: scale(1.02);
    height: fit-content;
  }
`;

const FinalCard = styled.div`
  width: 44%;
  cursor: pointer;
  z-index: 2;
  margin: auto;
  margin-top: 5rem;

  &:hover {
    box-shadow: 0 0 20px #6c2dc7;
    transform: scale(1.02);
    overflow: auto;
  }
`;

const PersonPlaceholderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  img {
    border-radius: 50%;
    width: 150px;
  }
`;

const NameAndSurname = styled(Typography)`
  font-weight: bolder;
  text-align: center;
`;

const UserInfo = styled(Typography)`
  text-align: center;
  color: gray;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

const ProgressWrapper = styled.div`
  margin-bottom: 14%;
`;

const ImgContainer = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
`;

const RoadmapTitle = styled.h2`
  text-align: center;
  padding-bottom: 3rem;
`;

const RoadmapCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
`;

const StyledHorizontalLine = styled.div<{ rowNumber: number }>`
  border-right: 2px solid #747474;
  height: 150px;
  position: relative;
  // Calculated middle of the second or first card card->44% line->12% card->44%
  left: ${(props) => (props.rowNumber === 2 ? "78%" : "22%")};
  width: 1px;
`;

const StyledHr = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='3' stroke-dasharray='0%2c 0' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 1px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 12%;
`;

const StyledHeading4 = styled.h4`
  color: #747474;
  padding-bottom: 5rem;
`;

const Transactions = styled.a`
  position: relative;
  width: fit-content;
  margin: auto;
  display: block;
  text-decoration: underline !important;
  cursor: pointer !important;
`;

const QuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-style: italic;

  > span {
    font-weight: bold;
  }
`;

const PackagesLink = styled(NavLink)`
  cursor: pointer;
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  svg {
    font-size: medium !important;
  }
`;

const GuideButton = styled(Button)`
  background-image: linear-gradient(
    to top right,
    ${(props: any) => props.theme.palette.primary.main},
    ${(props: any) => props.theme.palette.secondary.main}
  );
  text-transform: none;
  color: #ffffff;
  height: auto;
  line-height: normal;
  font-size: 17px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  cursor: pointer;
  float: right;
  z-index: 990;
  margin-top: -0.5rem;
  @media (max-width: 820px) {
    right: 0rem;
  }

  &:disabled {
    color: white;
    background: lightgray;
    pointer-events: auto;
  }
`;

const StyledRocket = styled.img`
  display: inline-block;
  animation: rocketAnimation 1.3s infinite alternate; /* Adjust the animation duration as needed */

  @keyframes rocketAnimation {
    from {
      transform: translateY(0); /* Start position */
    }
    to {
      transform: translateY(3px); /* End position */
    }
  }
`;

const StyledGuide = styled.div`
  width: 96%;
  display: flex;
  justify-content: end;

  @media (max-width: 820px) {
    width: 100%;
  }
`;
